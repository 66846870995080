<template>
    <div class="tw-w-full tw-h-full tw-bg-brand-area-color" v-if="getDirectIpLogin">
        <!-- Search Filters -->
        <search-bar v-model.trim="searchFilter" />

        <!-- queries List -->
        <neo-table :list="list" :headers="headers" :searchFilter="searchFilter" :searchFilterKey="searchFilterKey" :isLoading="loader" @checked="check" draggable @update:list="dragHandler($event)" @download="download" />
    </div>
    <error-message v-else-if="!getDirectIpLogin" />
</template>

<script>
import SearchBar from "@/components/searchbar";
import Table from "@/components/table-expandable";
import {mapGetters, mapActions, mapMutations} from "vuex";
import {downloadFile} from "@/utils/functions";
import {EventBus} from "@/main.js";
const ErrorMessage = () => import("@/components/server-error-message");

export default {
    name: "report-templates",
    components: {
        "neo-table": Table,
        SearchBar,
        ErrorMessage,
    },
    data() {
        return {
            list: [],
            searchFilterKey: "template_name",
            headers: [
                {
                    value: "checked",
                    type: "toggle",
                },
                {
                    text: "Template Name",
                    value: "template_name",
                    class: "tw-w-1/3",
                    maxLength: 150,
                },
                {
                    text: "Description",
                    value: "description",
                    class: "tw-w-2/5 tw-flex-grow",
                    maxLength: 150,
                },
                {
                    text: "Attachments",
                    value: "file",
                    type: "file",
                },
            ],
            searchFilter: "",
            loader: false,
        };
    },
    created() {
        this.RESET_PRODUCT_REPORT_TEMPLATES();
    },
    beforeDestroy() {
        this.RESET_PRODUCT_REPORT_TEMPLATES();
    },
    async mounted() {
        await this.initPage();
    },
    computed: {
        ...mapGetters(["getProduct", "getCompanyProducts", "getDirectIpLogin"]),
        product() {
            return this.getProduct.key ? this.getProduct : this.getCompanyProducts.find((el) => el.name === this.$route.query.product);
        },
        API() {
            return {
                get: this.getProductReportTemplates,
                post: this.postProductReportTemplates,
                download: this.downloadProductReportTemplates,
                drag: this.priorityProductReportTemplates,
            };
        },
    },
    methods: {
        ...mapMutations(["RESET_PRODUCT_REPORT_TEMPLATES"]),
        ...mapActions(["getProductReportTemplates", "postProductReportTemplates", "downloadProductReportTemplates", "priorityProductReportTemplates"]),
        async initPage() {
            this.loader = true;
            try {
                const response = await this.API.get(this.product.key);
                this.list = response.map((el, index) => ({
                    ...el,
                    id: el.report_templates_id,
                    file: el.s3_file_name,
                    order: index,
                }));
            } catch (error) {
                this.$toast.error(error.message || "Something went wrong");
            }
            this.loader = false;
        },
        async check({item, value}) {
            const id = item.id;
            try {
                EventBus.$emit("topprogress", "start");
                const elToChange = this.list.find((el, index) => el.id === id);
                const response = await this.API.post({
                    product_id: this.product.key,
                    status_active: value,
                    priority: elToChange.order,
                    report_templates_id: id,
                });
                elToChange.checked = value;
                if (!value) elToChange.product_template_id = null;
                else elToChange.product_template_id = response.data._id;
            } catch (error) {
                this.$toast.error(error.message || "Something went wrong");
            }
            EventBus.$emit("topprogress", "done");
        },
        async dragHandler(payload) {
            try {
                EventBus.$emit("topprogress", "start");
                this.list = payload.list;
                await this.API.drag({
                    KeyPriority: payload.list.map((el) => ({
                        id: el.product_template_id || el._id,
                        priority: el.order,
                    })),
                });
            } catch (error) {
                this.$toast.error(error.message || "Something went wrong");
            }
            EventBus.$emit("topprogress", "done");
        },
        async download(file) {
            EventBus.$emit("topprogress", "start");
            try {
                const fileUrl = await this.API.download(file);
                downloadFile(fileUrl);
            } catch (error) {
                this.$toast.error(error.message || "Something went wrong");
            }
            EventBus.$emit("topprogress", "done");
        },
    },
};
</script>

<style scoped lang="scss"></style>
