<template>
    <div class="tw-p-8 tw-bg-brand-area-color" v-if="getDirectIpLogin">
        <!-- Search Filters -->
        <div class="tw-w-full tw-block tw-text-left tw-flex">
            <div class="tw-mb-8 tw-block tw-pr-2 tw-border-0 tw-border-b-2 tw-border-solid tw-border-gray-200 tw-flex tw-flex-grow">
                <neo-input class="tw-w-full tw-border-0 tw-border-solid tw-border-b-2 tw-border-gray-200" :shadow="false" placeholder="Search by template name" v-model.trim="searchFilter" bg="var(--brand-area-color)" margin="0" icon="search"> </neo-input>
            </div>
        </div>

        <!-- queries List -->
        <neo-table :list="list" :headers="headers" :searchFilter="searchFilter" :searchFilterKey="searchFilterKey" :isLoading="loader" editable @update:list="dragHandler($event)" @update:row="updateRow" @remove:row="removeRow" @download="download" />

        <!-- Add New Report Template Modal -->
        <neo-modal ref="updateModal" class="updateModal" name="updateModal" :close="closemodal" :title="editId ? 'Edit ' + getItemById(editId).template_name : 'Add a new template'" @submit="submitModal" v-bind="updateModal">
            <template #content>
                <div class="updateModal__content tw-py-4">
                    <div class="updateModal__content__row tw-flex tw-justify-between">
                        <div class="section tw-w-1/3">
                            <div class="section__header">Name of the template</div>
                            <neo-input class="field" :class="{'field--invalid': isInvalid('name')}" placeholder="Enter name" bg="white" margin="0" :style="{width: '100%'}" v-model="updateData.name" />
                        </div>
                        <div class="section tw-w-1/2">
                            <div class="section__header">Upload attachments</div>
                            <!-- TODO upload input -->
                            <neo-button-attachments @change="changeFile" @remove="removeFile" :fileList="updateData.attachments" :invalid="isInvalid('attachments')" />
                        </div>
                    </div>
                    <div class="updateModal__content__row tw-flex">
                        <div class="section tw-w-full">
                            <div class="section__header">Description</div>
                            <textarea rows="4" class="field tw-shadow-sm focus:tw-outline-none tw-p-3 tw-border-gray-300 tw-rounded-md tw-text-sm" :class="{'field--invalid': isInvalid('description')}" placeholder="Enter Description" v-model="updateData.description" style="width: -webkit-fill-available"></textarea>
                        </div>
                    </div>
                </div>
            </template>
        </neo-modal>
        <modal-confirm ref="modal-confirm" />
    </div>
    <error-message v-else-if="!getDirectIpLogin" />
</template>

<script>
import Input from "@/components/input";
import Table from "@/components/table-expandable";
import Button from "@/components/button";
import Modal from "@/components/modal-content";
import ModalConfirm from "@/components/modal-confirm";
import ButtonAttachments from "@/components/button-attachments";
import {mapActions, mapMutations, mapGetters} from "vuex";
import {required, minLength} from "vuelidate/lib/validators";
import {downloadFile} from "@/utils/functions";
import titleMixin from "@/mixins/titleMixin";
import {EventBus} from "@/main.js";
const ErrorMessage = () => import("@/components/server-error-message");

export default {
    title() {
        return `Report Templates`;
    },
    mixins: [titleMixin],
    name: "report-templates",
    components: {
        "neo-input": Input,
        "neo-table": Table,
        "neo-button": Button,
        "neo-modal": Modal,
        "modal-confirm": ModalConfirm,
        "neo-button-attachments": ButtonAttachments,
        ErrorMessage,
    },
    data() {
        return {
            list: [],
            searchFilterKey: "template_name",
            headers: [
                {
                    text: "Template Name",
                    value: "template_name",
                    class: "tw-w-1/3",
                    maxLength: 150,
                },
                {
                    text: "Description",
                    value: "description",
                    class: "tw-w-2/5 tw-flex-grow",
                    maxLength: 150,
                },
                {
                    text: "Attachments",
                    value: "file",
                    type: "file",
                },
            ],
            searchFilter: "",
            loader: false,
            closemodal: false,
            editId: null,
            updateData: {
                name: "",
                attachments: [],
                description: "",
            },
            updateModal: {
                loading: false,
            },
        };
    },
    validations() {
        const validation = {
            updateData: {
                name: {required},
                description: {required},
                attachments: {minLength: minLength(1)},
            },
        };
        if (!this.editId) validation.updateData.attachments.required = required;
        return validation;
    },
    created() {
        this.RESET_ADMIN_REPORT_TEMPLATES();
    },
    beforeDestroy() {
        this.RESET_ADMIN_REPORT_TEMPLATES();
    },
    async mounted() {
        await this.initPage();
    },
    computed: {
        ...mapGetters(["getDirectIpLogin"]),
        API() {
            return {
                get: this.getAdminReportTemplates,
                post: this.postAdminReportTemplates,
                put: this.putAdminReportTemplates,
                delete: this.deleteAdminReportTemplates,
                download: this.downloadProductReportTemplates,
            };
        },
    },
    methods: {
        ...mapMutations(["RESET_ADMIN_REPORT_TEMPLATES"]),
        ...mapActions(["getAdminReportTemplates", "postAdminReportTemplates", "putAdminReportTemplates", "deleteAdminReportTemplates", "downloadProductReportTemplates"]),
        wait(ms) {
            // for testing
            return new Promise((resolve) => setTimeout(resolve, ms));
        },
        async initPage() {
            this.loader = true;
            try {
                const response = await this.API.get();
                this.list = response.map((el, index) => ({
                    ...el,
                    id: el.report_templates_id,
                    file: el.s3_file_name,
                    order: index,
                }));
            } catch (error) {
                this.$toast.error(error.message || "Something went wrong");
            } finally {
                this.loader = false;
            }
        },
        dragHandler(payload) {
            // TODO action for changing ordering
            this.list = payload.list;
        },
        updateRow(payload = {}) {
            this.editId = payload.item?.id;
            this.resetUpdateData();
            this.$refs.updateModal.showModal();
        },
        resetUpdateData(drop) {
            this.$v.$reset();
            if (drop || !this.editId) {
                this.updateData = {
                    name: "",
                    attachments: [],
                    description: "",
                };
            } else {
                const currItem = this.getItemById(this.editId);
                this.updateData = {
                    name: currItem.template_name,
                    attachments: [],
                    description: currItem.description,
                };
            }
        },
        getItemById(id) {
            return this.list.find((el) => el.id === id);
        },
        changeFile(payload) {
            this.updateData.attachments = payload;
        },
        removeFile(index) {
            this.updateData.attachments = Array.from(this.updateData.attachments);
            this.updateData.attachments.splice(index, 1);
        },
        async submitModal() {
            this.$v.updateData.$touch();
            if (this.$v.updateData.$error) return;

            this.updateModal.loading = true;
            try {
                const templateData = new FormData();
                templateData.append("template_name", this.updateData.name);
                templateData.append("description", this.updateData.description);
                if (this.updateData.attachments[0]) templateData.append("file", this.updateData.attachments[0]);
                if (!this.editId) {
                    await this.API.post(templateData);
                    this.$toast.success("Template created successfully");
                } else {
                    templateData.append("report_templates_id", this.editId);
                    await this.API.put(templateData);
                    this.$toast.success("Template updated successfully");
                }
                this.$refs.updateModal.hideModal();
                await this.initPage();
            } catch (error) {
                this.$toast.error(error.message);
            }
            this.updateModal.loading = false;
        },
        onClickOutside() {
            this.$refs.updateModal.showModal();
        },
        async removeRow(payload) {
            try {
                const confirm = await this.$refs["modal-confirm"].show({
                    title: "Are you sure you want to remove this template?",
                    autoclose: false,
                });
                if (confirm) {
                    await this.API.delete({report_templates_id: payload.item.id});
                    this.$refs["modal-confirm"].close();
                    this.$toast.success("Template removed successfully");
                    await this.initPage();
                }
            } catch (error) {
                this.$toast.error(error.message || "Something went wrong");
                this.$refs["modal-confirm"].close();
            }
        },
        isInvalid(fieldName) {
            return this.$v.updateData[fieldName].$invalid && this.$v.updateData.$error;
        },
        async download(file) {
            EventBus.$emit("topprogress", "start");
            try {
                const fileUrl = await this.API.download(file);
                downloadFile(fileUrl);
            } catch (error) {
                this.$toast.error(error.message || "Something went wrong");
            }
            EventBus.$emit("topprogress", "done");
        },
    },
};
</script>

<style scoped lang="scss">
.updateModal {
    &__content {
        padding-top: 10px;
        padding-bottom: 35px;
        &__row {
            &:not(:last-child) {
                margin-bottom: 30px;
            }
            .section {
                &:not(:last-child) {
                    @apply tw-mr-4;
                }
                &__header {
                    margin-bottom: 9px;
                    color: #939393;
                }
            }
        }
    }
}
</style>
<style lang="scss">
@import "../../mixins.scss";
.updateModal {
    &__content {
        &__row {
            .section {
                .field {
                    @include field;
                }
            }
        }
    }
}
</style>
