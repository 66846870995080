import Button from "@/components/button";
import Input from "@/components/input";
import Loader from "@/components/loader";
import Table from "@/components/table";
import VueMultiselect from "@/components/vue-multiselect";
import "@/assets/css/multiselect.scss";

import titleMixin from "@/mixins/titleMixin";
import {mapGetters, mapActions} from "vuex";
import axios from "@/axios";

const ErrorMessage = () => import("@/components/server-error-message");

export default {
    name: "neo-products-list",
    components: {
        "neo-button": Button,
        "neo-input": Input,
        "neo-loader": Loader,
        "neo-table": Table,
        VueMultiselect,
        ErrorMessage,
    },
    props: [],
    mixins: [titleMixin],
    title() {
        return `Products`;
    },
    data() {
        return {
            products: [],
            selectedProduct: null,
            headers: [
                {
                    text: "Product Name",
                    value: "name",
                    class: "tw-w-1/4",
                },
                // {
                //     text: "Company",
                //     value: "company",
                // },
                {
                    text: "Product Description",
                    value: "description",
                    class: "tw-w-1/4",
                },
                {
                    text: "TAT",
                    value: "tat",
                    class: "tw-w-1/4",
                },
                {
                    text: "Product Key",
                    value: "key",
                    class: "tw-w-1/4",
                },
            ],
            searchFilter: "",
            searchFilterKey: {
                text: "Product Name",
                value: "name",
            },
            loader: false,
        };
    },
    computed: {
        ...mapGetters(["getCompanyProducts", "getLicenses", "getDirectIpLogin", "checkPermissionsFetched"]),
        isAuth() {
            return true;
            // return this.getLicenses?.includes("case_create__case_data");
        },

        productsList() {
            if (this.searchFilterKey.value === "tat")
                return this.products.filter((product) =>
                    product[this.searchFilterKey.value]
                        .toString()
                        .toLowerCase()
                        .includes(this.searchFilter)
                );
            else return this.products.filter((product) => product[this.searchFilterKey.value].toLowerCase().includes(this.searchFilter.toLowerCase()));
        },
    },
    async mounted() {
        this.loader = true;
        await this.fetchCompanyProducts();
        this.products = [...this.products, ...this.getCompanyProducts];
        this.loader = false;
    },
    methods: {
        ...mapActions(["fetchCompanyProducts"]),
        configureCase(product) {
            this.selectedProduct = product;
            this.$store.dispatch("setProduct", this.selectedProduct);
            this.$router.push({path: "/product/configure", query: {product: this.selectedProduct.name}});
        },
    },
};
