import NeoInput from "@/components/input";
import NeoButton from "@/components/button";
import NeoBadge from "@/components/badge";
import NeoPagination from "@/components/pagination";
import NeoToggleSwitch from "@/components/toggle-switch";
import {required} from "vuelidate/lib/validators";
import NeoCheckbox from "@/components/checkbox";
import titleMixin from "@/mixins/titleMixin";
import axios from "@/axios";
import VueMultiselect from "@/components/vue-multiselect";
// import "vue-multiselect/dist/vue-multiselect.min.css";
import "@/assets/css/multiselect.scss";
import vueFilePond from "vue-filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js";
import FilePondPluginImagePreview from "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm.js";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview);
import rawAxios from "axios";
import {mapGetters} from "vuex";
import {uniqBy} from "lodash";
import {EventBus} from "@/main.js";
import {getConfig} from "@/utils/functions";
const ErrorMessage = () => import("@/components/server-error-message");
import iamApi from "@/axios/user";

export default {
    title() {
        return this.$route.name === "Configure Product" ? "Product Admin" : `Research Methodology`;
    },
    name: "company-methodology",
    components: {
        NeoInput,
        NeoButton,
        VueMultiselect,
        NeoBadge,
        NeoPagination,
        NeoToggleSwitch,
        NeoCheckbox,
        FilePond,
        ErrorMessage,
    },
    props: [],
    mixins: [titleMixin],
    data() {
        return {
            // search: "",
            entityValues: [],
            entityOptions: [],
            productTypeValues: [],
            productTypeOptions: [],
            purposeValues: [],
            purposeOptions: [],
            moduleValues: [],
            moduleOptions: [],
            typeValues: [],
            typeOptions: [],
            statusOptions: [
                {
                    name: "Active",
                    code: true,
                },
                {
                    name: "Inactive",
                    code: false,
                },
            ],
            mandatoryOptions: [
                {
                    name: "Yes",
                    code: true,
                },
                {
                    name: "No",
                    code: false,
                },
            ],
            mandatoryValues: "",
            statusValues: "",
            checkedOptions: [
                {
                    name: "Yes",
                    code: true,
                },
                {
                    name: "No",
                    code: false,
                },
            ],
            checkedValues: "",
            inputOptions: [],
            inputValues: [],
            outputOptions: [],
            outputValues: [],
            regionOptions: [],
            regionValues: [],
            countryOptions: [],
            countryValues: [],
            locationOptions: [],
            locationValues: [],
            stepOptions: [],
            companyOptions: [],
            columns: {
                adminHeadings: [
                    {
                        label: "Entity Type",
                        field: "entity_type",
                        sorted: false,
                        clicked: false,
                        multiple: true,
                        closeOnSelect: true,
                        clearOnSelect: true,
                        groupSelect: true,
                        checkboxes: true,
                        taggable: false,
                        limit: 1,
                        options: [],
                        values: [],
                        width: "120px",
                        filterOptions: {
                            enabled: true,
                            placeholder: "Search by region",
                            trigger: "enter",
                        },
                        sortable: false,
                    },
                    {
                        label: "Module",
                        field: "module",
                        sorted: false,
                        clicked: false,
                        multiple: true,
                        closeOnSelect: true,
                        clearOnSelect: true,
                        groupSelect: true,
                        checkboxes: true,
                        taggable: false,
                        options: [],
                        values: [],
                        limit: 1,
                        width: "120px",
                        filterOptions: {
                            enabled: true,
                            placeholder: "Search by region",
                            trigger: "enter",
                        },
                        sortable: false,
                    },
                    {
                        label: "Purpose",
                        field: "purpose",
                        sorted: false,
                        clicked: false,
                        multiple: true,
                        closeOnSelect: true,
                        clearOnSelect: true,
                        groupSelect: true,
                        checkboxes: true,
                        taggable: false,
                        options: [],
                        values: [],
                        limit: 1,
                        width: "120px",
                        filterOptions: {
                            enabled: true,
                            placeholder: "Search by region",
                            trigger: "enter",
                        },
                        sortable: false,
                    },
                    {
                        label: "Step",
                        field: "step",
                        sorted: false,
                        clicked: false,
                        multiple: true,
                        width: "120px",
                        closeOnSelect: true,
                        clearOnSelect: true,
                        groupSelect: true,
                        checkboxes: true,
                        taggable: false,
                        options: [],
                        values: [],
                        limit: 1,
                        sortable: false,
                    },
                    {
                        label: "Guidance",
                        field: "guidance",
                        closeOnSelect: true,
                        clearOnSelect: true,
                        groupSelect: true,
                        checkboxes: true,
                        taggable: false,
                        width: "120px",
                        options: [],
                        values: [],
                        limit: 1,
                        sortable: false,
                    },
                    {
                        label: "Mandatory",
                        field: "mandatory",
                        closeOnSelect: true,
                        clearOnSelect: true,
                        groupSelect: true,
                        checkboxes: true,
                        taggable: false,
                        multiple: false,
                        width: "120px",
                        options: [],
                        values: null,
                        limit: 1,
                        filterOptions: {
                            enabled: true,
                            placeholder: "Search by region",
                            trigger: "enter",
                        },
                        sortable: false,
                    },
                    {
                        label: "Type",
                        field: "report_type",
                        sorted: false,
                        clicked: false,
                        multiple: true,
                        closeOnSelect: true,
                        clearOnSelect: true,
                        groupSelect: true,
                        checkboxes: true,
                        taggable: false,
                        options: [],
                        values: [],
                        limit: 1,
                        width: "120px",
                        filterOptions: {
                            enabled: true,
                            placeholder: "Search by type",
                            trigger: "enter",
                        },
                        sortable: false,
                    },
                    {
                        label: "Status",
                        field: "active",
                        closeOnSelect: true,
                        clearOnSelect: true,
                        groupSelect: true,
                        checkboxes: true,
                        taggable: false,
                        multiple: false,
                        width: "120px",
                        options: [],
                        values: null,
                        limit: 1,
                        filterOptions: {
                            enabled: true,
                            placeholder: "Search by region",
                            trigger: "enter",
                        },
                        sortable: false,
                    },
                    {
                        label: "Products",
                        field: "products",
                        closeOnSelect: true,
                        clearOnSelect: true,
                        groupSelect: true,
                        checkboxes: true,
                        taggable: false,
                        multiple: true,
                        width: "200px",
                        options: [],
                        values: [],
                        limit: 1,
                        filterOptions: {
                            enabled: true,
                            placeholder: "Search by region",
                            trigger: "enter",
                        },
                        sortable: false,
                    },
                    {
                        label: "Input",
                        field: "input",
                        closeOnSelect: true,
                        clearOnSelect: true,
                        groupSelect: true,
                        checkboxes: true,
                        taggable: false,
                        width: "120px",
                        options: [],
                        values: [],
                        multiple: true,
                        limit: 1,
                        filterOptions: {
                            enabled: true,
                            placeholder: "Search by region",
                            trigger: "enter",
                        },
                        sortable: false,
                    },
                    {
                        label: "Output",
                        field: "output",
                        closeOnSelect: true,
                        clearOnSelect: true,
                        groupSelect: true,
                        checkboxes: true,
                        taggable: false,
                        width: "120px",
                        options: [],
                        values: [],
                        multiple: true,
                        limit: 1,
                        filterOptions: {
                            enabled: true,
                            placeholder: "Search by region",
                            trigger: "enter",
                        },
                        sortable: false,
                    },
                    {
                        label: "Region",
                        field: "region",
                        closeOnSelect: true,
                        clearOnSelect: true,
                        groupSelect: true,
                        checkboxes: true,
                        taggable: false,
                        width: "120px",
                        multiple: true,
                        options: [],
                        values: [],
                        limit: 1,
                        filterOptions: {
                            enabled: true,
                            placeholder: "Search by region",
                            trigger: "enter",
                        },
                        sortable: false,
                    },
                    {
                        label: "Country",
                        field: "country",
                        closeOnSelect: true,
                        clearOnSelect: true,
                        groupSelect: true,
                        checkboxes: true,
                        taggable: false,
                        width: "120px",
                        multiple: true,
                        options: [],
                        values: [],
                        limit: 1,
                        filterOptions: {
                            enabled: true,
                            placeholder: "Search by region",
                            trigger: "enter",
                        },
                        sortable: false,
                    },
                    {
                        label: "Location",
                        field: "location",
                        closeOnSelect: true,
                        clearOnSelect: true,
                        groupSelect: true,
                        checkboxes: true,
                        taggable: false,
                        width: "120px",
                        multiple: true,
                        options: [],
                        values: [],
                        limit: 1,
                        filterOptions: {
                            enabled: true,
                            placeholder: "Search by region",
                            trigger: "enter",
                        },
                        sortable: false,
                    },
                ],
                superadminHeadings: [
                    {
                        label: "Entity Type",
                        field: "entity_type",
                        sorted: false,
                        clicked: false,
                        multiple: true,
                        closeOnSelect: true,
                        clearOnSelect: true,
                        groupSelect: true,
                        checkboxes: true,
                        taggable: false,
                        limit: 1,
                        options: [],
                        values: [],
                        width: "120px",
                        filterOptions: {
                            enabled: true,
                            placeholder: "Search by region",
                            trigger: "enter",
                        },
                        sortable: false,
                    },
                    {
                        label: "Module",
                        field: "module",
                        sorted: false,
                        clicked: false,
                        multiple: true,
                        closeOnSelect: true,
                        clearOnSelect: true,
                        groupSelect: true,
                        checkboxes: true,
                        taggable: false,
                        options: [],
                        values: [],
                        limit: 1,
                        width: "120px",
                        filterOptions: {
                            enabled: true,
                            placeholder: "Search by region",
                            trigger: "enter",
                        },
                        sortable: false,
                    },
                    {
                        label: "Purpose",
                        field: "purpose",
                        sorted: false,
                        clicked: false,
                        multiple: true,
                        closeOnSelect: true,
                        clearOnSelect: true,
                        groupSelect: true,
                        checkboxes: true,
                        taggable: false,
                        options: [],
                        values: [],
                        limit: 1,
                        width: "120px",
                        filterOptions: {
                            enabled: true,
                            placeholder: "Search by region",
                            trigger: "enter",
                        },
                        sortable: false,
                    },
                    {
                        label: "Step",
                        field: "step",
                        sorted: false,
                        clicked: false,
                        multiple: true,
                        width: "120px",
                        closeOnSelect: true,
                        clearOnSelect: true,
                        groupSelect: true,
                        checkboxes: true,
                        taggable: false,
                        options: [],
                        values: [],
                        limit: 1,
                        sortable: false,
                    },
                    {
                        label: "Guidance",
                        field: "guidance",
                        closeOnSelect: true,
                        clearOnSelect: true,
                        groupSelect: true,
                        checkboxes: true,
                        taggable: false,
                        width: "120px",
                        options: [],
                        values: [],
                        limit: 1,
                        sortable: false,
                    },
                    {
                        label: "Mandatory",
                        field: "mandatory",
                        closeOnSelect: true,
                        clearOnSelect: true,
                        groupSelect: true,
                        checkboxes: true,
                        taggable: false,
                        multiple: false,
                        width: "120px",
                        options: [],
                        values: null,
                        limit: 1,
                        filterOptions: {
                            enabled: true,
                            placeholder: "Search by region",
                            trigger: "enter",
                        },
                        sortable: false,
                    },
                    {
                        label: "Type",
                        field: "report_type",
                        sorted: false,
                        clicked: false,
                        multiple: true,
                        closeOnSelect: true,
                        clearOnSelect: true,
                        groupSelect: true,
                        checkboxes: true,
                        taggable: false,
                        options: [],
                        values: [],
                        limit: 1,
                        width: "120px",
                        filterOptions: {
                            enabled: true,
                            placeholder: "Search by type",
                            trigger: "enter",
                        },
                        sortable: false,
                    },
                    {
                        label: "Status",
                        field: "active",
                        closeOnSelect: true,
                        clearOnSelect: true,
                        groupSelect: true,
                        checkboxes: true,
                        taggable: false,
                        multiple: false,
                        width: "120px",
                        options: [],
                        values: null,
                        limit: 1,
                        filterOptions: {
                            enabled: true,
                            placeholder: "Search by region",
                            trigger: "enter",
                        },
                        sortable: false,
                    },
                    {
                        label: "Products",
                        field: "products",
                        closeOnSelect: true,
                        clearOnSelect: true,
                        groupSelect: true,
                        checkboxes: true,
                        taggable: false,
                        multiple: true,
                        width: "200px",
                        options: [],
                        values: [],
                        limit: 1,
                        filterOptions: {
                            enabled: true,
                            placeholder: "Search by region",
                            trigger: "enter",
                        },
                        sortable: false,
                    },
                    {
                        label: "Input",
                        field: "input",
                        closeOnSelect: true,
                        clearOnSelect: true,
                        groupSelect: true,
                        checkboxes: true,
                        taggable: false,
                        width: "120px",
                        options: [],
                        values: [],
                        multiple: true,
                        limit: 1,
                        filterOptions: {
                            enabled: true,
                            placeholder: "Search by region",
                            trigger: "enter",
                        },
                        sortable: false,
                    },
                    {
                        label: "Output",
                        field: "output",
                        closeOnSelect: true,
                        clearOnSelect: true,
                        groupSelect: true,
                        checkboxes: true,
                        taggable: false,
                        width: "120px",
                        options: [],
                        values: [],
                        multiple: true,
                        limit: 1,
                        filterOptions: {
                            enabled: true,
                            placeholder: "Search by region",
                            trigger: "enter",
                        },
                        sortable: false,
                    },
                    {
                        label: "Region",
                        field: "region",
                        closeOnSelect: true,
                        clearOnSelect: true,
                        groupSelect: true,
                        checkboxes: true,
                        taggable: false,
                        width: "120px",
                        multiple: true,
                        options: [],
                        values: [],
                        limit: 1,
                        filterOptions: {
                            enabled: true,
                            placeholder: "Search by region",
                            trigger: "enter",
                        },
                        sortable: false,
                    },
                    {
                        label: "Country",
                        field: "country",
                        closeOnSelect: true,
                        clearOnSelect: true,
                        groupSelect: true,
                        checkboxes: true,
                        taggable: false,
                        width: "120px",
                        multiple: true,
                        options: [],
                        values: [],
                        limit: 1,
                        filterOptions: {
                            enabled: true,
                            placeholder: "Search by region",
                            trigger: "enter",
                        },
                        sortable: false,
                    },
                    {
                        label: "Location",
                        field: "location",
                        closeOnSelect: true,
                        clearOnSelect: true,
                        groupSelect: true,
                        checkboxes: true,
                        taggable: false,
                        width: "120px",
                        multiple: true,
                        options: [],
                        values: [],
                        limit: 1,
                        filterOptions: {
                            enabled: true,
                            placeholder: "Search by region",
                            trigger: "enter",
                        },
                        sortable: false,
                    },
                    {label: "Edit", field: "edit", sortable: false},
                ],
                analystHeadings: [
                    {
                        label: "Entity Type",
                        field: "entity_type",
                        sorted: false,
                        clicked: false,
                        multiple: true,
                        closeOnSelect: true,
                        clearOnSelect: true,
                        groupSelect: true,
                        checkboxes: true,
                        taggable: false,
                        limit: 1,
                        options: [],
                        values: [],
                        width: "120px",
                        filterOptions: {
                            enabled: true,
                            placeholder: "Search by region",
                            trigger: "enter",
                        },
                        sortable: false,
                    },
                    {
                        label: "Module",
                        field: "module",
                        sorted: false,
                        clicked: false,
                        multiple: true,
                        closeOnSelect: true,
                        clearOnSelect: true,
                        groupSelect: true,
                        checkboxes: true,
                        taggable: false,
                        options: [],
                        values: [],
                        limit: 1,
                        width: "120px",
                        filterOptions: {
                            enabled: true,
                            placeholder: "Search by region",
                            trigger: "enter",
                        },
                        sortable: false,
                    },
                    {
                        label: "Type",
                        field: "report_type",
                        sorted: false,
                        clicked: false,
                        multiple: true,
                        closeOnSelect: true,
                        clearOnSelect: true,
                        groupSelect: true,
                        checkboxes: true,
                        taggable: false,
                        options: [],
                        values: [],
                        limit: 1,
                        width: "120px",
                        filterOptions: {
                            enabled: true,
                            placeholder: "Search by type",
                            trigger: "enter",
                        },
                        sortable: false,
                    },
                    {
                        label: "Purpose",
                        field: "purpose",
                        sorted: false,
                        clicked: false,
                        multiple: true,
                        closeOnSelect: true,
                        clearOnSelect: true,
                        groupSelect: true,
                        checkboxes: true,
                        taggable: false,
                        options: [],
                        values: [],
                        limit: 1,
                        width: "120px",
                        filterOptions: {
                            enabled: true,
                            placeholder: "Search by region",
                            trigger: "enter",
                        },
                        sortable: false,
                    },
                    {
                        label: "Step",
                        field: "step",
                        sorted: false,
                        clicked: false,
                        multiple: true,
                        width: "120px",
                        closeOnSelect: true,
                        clearOnSelect: true,
                        groupSelect: true,
                        checkboxes: true,
                        taggable: false,
                        options: [],
                        values: [],
                        limit: 1,
                        sortable: false,
                    },
                    {
                        label: "Guidance",
                        field: "guidance",
                        closeOnSelect: true,
                        clearOnSelect: true,
                        groupSelect: true,
                        checkboxes: true,
                        taggable: false,
                        width: "120px",
                        options: [],
                        values: [],
                        limit: 1,
                        sortable: false,
                    },
                    {
                        label: "Mandatory",
                        field: "mandatory",
                        closeOnSelect: true,
                        clearOnSelect: true,
                        groupSelect: true,
                        checkboxes: true,
                        taggable: false,
                        multiple: false,
                        width: "120px",
                        options: [],
                        values: null,
                        limit: 1,
                        filterOptions: {
                            enabled: true,
                            placeholder: "Search by region",
                            trigger: "enter",
                        },
                        sortable: false,
                    },
                    {
                        label: "Status",
                        field: "active",
                        closeOnSelect: true,
                        clearOnSelect: true,
                        groupSelect: true,
                        checkboxes: true,
                        taggable: false,
                        multiple: false,
                        width: "120px",
                        options: [],
                        values: null,
                        limit: 1,
                        filterOptions: {
                            enabled: true,
                            placeholder: "Search by region",
                            trigger: "enter",
                        },
                        sortable: false,
                    },
                    {
                        label: "Products",
                        field: "products",
                        closeOnSelect: true,
                        clearOnSelect: true,
                        groupSelect: true,
                        checkboxes: true,
                        taggable: false,
                        multiple: true,
                        width: "200px",
                        options: [],
                        values: [],
                        limit: 1,
                        filterOptions: {
                            enabled: true,
                            placeholder: "Search by region",
                            trigger: "enter",
                        },
                        sortable: false,
                    },
                    {
                        label: "Input",
                        field: "input",
                        closeOnSelect: true,
                        clearOnSelect: true,
                        groupSelect: true,
                        checkboxes: true,
                        taggable: false,
                        width: "120px",
                        options: [],
                        values: [],
                        multiple: true,
                        limit: 1,
                        filterOptions: {
                            enabled: true,
                            placeholder: "Search by region",
                            trigger: "enter",
                        },
                        sortable: false,
                    },
                    {
                        label: "Output",
                        field: "output",
                        closeOnSelect: true,
                        clearOnSelect: true,
                        groupSelect: true,
                        checkboxes: true,
                        taggable: false,
                        width: "120px",
                        options: [],
                        values: [],
                        multiple: true,
                        limit: 1,
                        filterOptions: {
                            enabled: true,
                            placeholder: "Search by region",
                            trigger: "enter",
                        },
                        sortable: false,
                    },
                    {
                        label: "Region",
                        field: "region",
                        closeOnSelect: true,
                        clearOnSelect: true,
                        groupSelect: true,
                        checkboxes: true,
                        taggable: false,
                        width: "120px",
                        multiple: true,
                        options: [],
                        values: [],
                        limit: 1,
                        filterOptions: {
                            enabled: true,
                            placeholder: "Search by region",
                            trigger: "enter",
                        },
                        sortable: false,
                    },
                    {
                        label: "Country",
                        field: "country",
                        closeOnSelect: true,
                        clearOnSelect: true,
                        groupSelect: true,
                        checkboxes: true,
                        taggable: false,
                        width: "120px",
                        multiple: true,
                        options: [],
                        values: [],
                        limit: 1,
                        filterOptions: {
                            enabled: true,
                            placeholder: "Search by region",
                            trigger: "enter",
                        },
                        sortable: false,
                    },
                    {
                        label: "Location",
                        field: "location",
                        closeOnSelect: true,
                        clearOnSelect: true,
                        groupSelect: true,
                        checkboxes: true,
                        taggable: false,
                        width: "120px",
                        multiple: true,
                        options: [],
                        values: [],
                        limit: 1,
                        filterOptions: {
                            enabled: true,
                            placeholder: "Search by region",
                            trigger: "enter",
                        },
                        sortable: false,
                    },
                ],
            },
            methodologies: [],
            currentPage: 1,
            pageLimit: 10,
            editId: "",
            typeInput: "",
            formTypeInput: [],
            stepInput: [],
            moduleInput: [],
            notes: "",
            guidanceInput: {
                description: "",
                image: "",
            },
            formProductsInput: [],
            formEntityInput: [],
            purposeInput: [],
            formNotesInput: "",
            mandatoryInput: false,
            inputForm: [],
            outputForm: [],
            regionInput: [],
            countryInput: [],
            locationInput: [],
            formCompanyInput: [],
            myServer: {
                process: this.uploadScreenShot,
                load: this.loadScreenshot,
                revert: this.revereImage,
            },
            myFiles: "",
            openImagePreview: false,
            resetData: [],
            oldValueNotes: "",
            add_edit: "",
            adminAccounts: [],
            isLoading: false,

            filtersANdFuncMapValues: {
                entity_type: "entityValues",
                products: "productTypeValues",
                purpose: "purposeValues",
                report_type: "typeValues",
                step: "stepValues",
                input: "inputValues",
                output: "outputValues",
                region: "regionValues",
                country: "countryValues",
                location: "locationValues",
                module: "moduleValues",
                mandatory: "mandatoryValues",
                active: "statusValues",
                checked: "checkedValues",
            },

            filtersAndFuncMapOptions: {
                entity_type: "entityOptions",
                products: "productTypeOptions",
                purpose: "purposeOptions",
                report_type: "typeOptions",
                step: "stepOptions",
                input: "inputOptions",
                output: "outputOptions",
                region: "regionOptions",
                country: "countryOptions",
                location: "locationOptions",
                module: "moduleOptions",
                mandatory: "mandatoryOptions",
                active: "statusOptions",
                checked: "checkedOptions",
            },
        };
    },
    computed: {
        ...mapGetters(["getAccountDetails", "getDirectIpLogin", "getProductFeatures", "getLicenses", "checkPermissionsFetched", "getUserAuthDetails"]),
        search() {
            return this.$store.getters.search["Research Methodology"];
        },
        filteredResults() {
            this.currentPage = this.search ? 1 : this.currentPage;
            return this.methodologies.filter((data) => data.step?.toLowerCase().includes(this.search.toLowerCase()) || data.module?.toLowerCase().includes(this.search.toLowerCase()) || data.purpose?.toLowerCase().includes(this.search.toLowerCase()) || data.entity_type?.toLowerCase().includes(this.search.toLowerCase()) || data.guidance?.description?.toLowerCase().includes(this.search.toLowerCase()));
        },
        getTotalPages() {
            return Math.ceil(this.filteredResults.length / 10);
        },
        // methodology__methodology
        isAuth() {
            return this.getLicenses.includes("methodology__methodology");
        },
        isNeotasUser() {
            return this.getUserAuthDetails.email.includes("@neotas.com");
        },
        isNeotasAdmin() {
            return this.getUserAuthDetails?.userRole?.includes("neotas-superadmin");
        },
        getActiveHeading() {
            let currentRoute = this.$route.name;
            return this.isNeotasAdmin && currentRoute === "Research Methodology" ? "superadminHeadings" : !this.isNeotasAdmin && currentRoute === "Research Methodology" ? "adminHeadings" : "analystHeadings";
        },
        getColumns: {
            get() {
                return {...this.columns, [this.getActiveHeading]: this.columns[this.getActiveHeading].map((el) => ({...el, options: this.getOptionsList(this.filtersAndFuncMapOptions[el.field])}))};
            },
            set(val) {
                this.columns = val;
            },
        },
        imagesBaseURL() {
            return process.env.VUE_APP_IMAGES_URL;
        },
    },
    async mounted() {
        this.getAdminAccounts();
        // const loading = this.$loading.show();
        getConfig(["methodology:add-edit"]).then((config) => {
            if (config["methodology:add-edit"].status) {
                this.add_edit = config["methodology:add-edit"].value;
                console.log(this.add_edit);
            }
        });

        EventBus.$emit("topprogress", "start");
        let url = "/research-methodology/list";
        let payload = {};
        if (this.$route.params.id)
            payload = {
                case_id: this.$route.params.id,
            };
        if (this.getAccountDetails?.id) {
            payload.company = [this.getAccountDetails.id];
        }
        this.isLoading = true;
        const {data} = await axios.post(url, payload);
        this.methodologies = data?.data.map((el) => ({...el, showMore: false, showMoreInput: false, showMoreOutput: false, showMoreLocation: false, showMoreRegion: false, showMoreCountry: false}));
        this.entityOptions = [
            {name: "Company", code: 1},
            {name: "Individual", code: 2},
        ];
        //  this.getUniqueList("entity_type");
        this.productTypeOptions = [
            ...new Set(
                [].concat
                    .apply(
                        [],
                        this.methodologies.filter((n) => n["products"]).map((n) => n.products)
                    )
                    .map((n) => n.product_name.trim())
            ),
        ]
            .map((n2, i) => ({name: n2, code: i}))
            .sort((a, b) => a.name?.localeCompare(b.name));
        this.purposeOptions = this.getUniqueList("purpose");
        this.typeOptions = this.getUniqueList("report_type");
        this.stepOptions = this.getUniqueList("step");
        this.inputOptions = this.getUniqueList("input");
        this.outputOptions = this.getUniqueList("output");
        this.regionOptions = this.getUniqueList("region");
        this.countryOptions = this.getUniqueList("country");
        this.locationOptions = this.getUniqueList("location");
        this.moduleOptions = this.getUniqueList("module");
        this.isLoading = false;
        // this.formCompanyInput =
        if (this.adminAccounts) {
            this.companyOptions = this.adminAccounts.map((el) => ({name: el.name, code: el.id}));
        }
        this.reassignFilter();
        // loading.hide();
        EventBus.$emit("topprogress", "done");
    },
    methods: {
        getOptionsList(list) {
            return [
                {
                    group: list === "productTypeOptions" ? "product type" : list?.split("Options")[0],
                    categories: list === "productTypeOptions" && Object.keys(this.getProductFeatures).length > 0 ? uniqBy([...this[list], ...Object.keys(this.getProductFeatures).map((product, index) => ({name: product, code: this[list].length + index}))], "name") : this[list],
                },
            ];
        },

        limitText(length) {
            return ` +${length}`;
        },

        getUniqueList(dataset) {
            return [
                ...new Set(
                    [].concat.apply(
                        [],
                        this.methodologies.filter((n) => n[dataset]).map((n1) => n1[dataset])
                    )
                ),
            ]
                .map((n2, i) => ({name: n2, code: i}))
                .sort((a, b) => a.name?.localeCompare(b.name));
        },
        getTag(tag) {
            return {
                name: tag,
                code: tag.substring(0, 2) + Math.floor(Math.random() * 10000000),
            };
        },
        async handleChange() {
            this.currentPage = 1;
            let url = `research-methodology/list`;
            let payload = {};
            if (this.$route.params.id)
                payload = {
                    case_id: this.$route.params.id,
                };

            this.getColumns[this.getActiveHeading].forEach((el) => {
                if (el.values) {
                    if (el.field === "mandatory" || el.field === "checked" || el.field === "active") {
                        payload[el.field] = el.values.code || false;
                    } else {
                        if (el.values.length > 0) {
                            payload[el.field] = el.values?.map((n) => n.name);
                        }
                    }
                }
            });

            if (this.getAccountDetails?.id) {
                payload.company = [this.getAccountDetails.id];
            }

            const obj = {};

            this.getColumns[this.getActiveHeading].forEach((el) => {
                obj[this.filtersANdFuncMapValues[el.field]] = el.values;
            });
            sessionStorage.setItem("methodology-values", JSON.stringify(obj));
            // const loading = this.$loading.show();
            EventBus.$emit("topprogress", "start");
            this.isLoading = true;
            const {data} = await axios.post(url, payload);
            this.isLoading = false;
            // loading.hide();
            EventBus.$emit("topprogress", "done");
            this.methodologies = data?.data;
        },
        async getAdminAccounts() {
            try {
                let response = await iamApi.get("/api/v1/accounts/admin/all");
                this.adminAccounts = response.data;
            } catch (error) {}
        },
        handleClosed() {
            this.editId = "";
            this.formTypeInput = [];
            this.stepInput = [];
            this.moduleInput = [];
            this.guidanceInput = {
                description: "",
                image: "",
            };
            this.formProductsInput = [];
            this.formNotesInput = "";
            this.formEntityInput = [];
            this.purposeInput = [];
            this.mandatoryInput = false;
            this.inputForm = [];
            this.outputForm = [];
            this.regionInput = [];
            this.countryInput = [];
            this.locationInput = [];
            this.myFiles = "";
            this.$v.$reset();
        },
        handleSort(key, index) {
            let elem = this.$route.name == "Research Methodology" ? this.adminHeadings[index].sorted : this.analystHeadings[index].sorted;
            this.methodologies = this.methodologies.sort((a, b) => (!elem ? a[key]?.localeCompare(b[key]) : b[key]?.localeCompare(a[key])));
            if (this.$route.name == "Research Methodology") {
                this.adminHeadings[index].sorted = !this.adminHeadings[index].sorted;
                this.adminHeadings[index].clicked = true;
            } else {
                this.analystHeadings[index].sorted = !this.analystHeadings[index].sorted;
                this.analystHeadings[index].clicked = true;
            }
        },
        handleResetSort(key, index) {
            this.methodologies = [...this.resetData];
            if (this.$route.name == "Research Methodology") {
                this.adminHeadings[index].clicked = false;
            } else {
                this.analystHeadings[index].clicked = false;
            }
        },
        addTypeTag(newTag) {
            const tag = this.getTag(newTag);
            this.typeOptions.push(tag);
            this.formTypeInput.push(tag);
        },
        addProductTypeTag(newTag) {
            const tag = this.getTag(newTag);
            this.productTypeOptions.push(tag);
            this.formProductsInput.push(tag);
        },
        addCompanyTypeTag(newTag) {
            const tag = this.getTag(newTag);
            this.companyOptions.push(tag);
            this.formCompanyInput.push(tag);
        },
        addEntityTypeTag(newTag) {
            const tag = this.getTag(newTag);
            this.entityOptions.push(tag);
            this.formEntityInput.push(tag);
        },
        addInputTypeTag(newTag) {
            const tag = this.getTag(newTag);
            this.inputOptions.push(tag);
            this.inputForm.push(tag);
        },
        addOutputTypeTag(newTag) {
            const tag = this.getTag(newTag);
            this.outputOptions.push(tag);
            this.outputForm.push(tag);
        },
        addStepTypeTag(newTag) {
            const tag = this.getTag(newTag);
            this.stepOptions.push(tag);
            this.stepInput.push(tag);
        },
        addPurposeTag(newTag) {
            const tag = this.getTag(newTag);
            this.purposeOptions.push(tag);
            this.purposeInput.push(tag);
        },
        addRegionTag(newTag) {
            const tag = this.getTag(newTag);
            this.regionOptions.push(tag);
            this.regionInput.push(tag);
        },
        addCountryTag(newTag) {
            const tag = this.getTag(newTag);
            this.countryOptions.push(tag);
            this.countryInput.push(tag);
        },
        addLocationTag(newTag) {
            const tag = this.getTag(newTag);
            this.locationOptions.push(tag);
            this.locationInput.push(tag);
        },
        addModuleTag(newTag) {
            const tag = this.getTag(newTag);
            this.moduleOptions.push(tag);
            this.moduleInput.push(tag);
        },
        async handleAddMethodology() {
            this.$v.$touch();
            if (!this.$v.$error) {
                let obj = {
                    report_type: this.formTypeInput[0].name,
                    step: this.stepInput[0].name,
                    module: this.moduleInput[0].name,
                    guidance: {
                        description: this.guidanceInput.description ? this.guidanceInput.description : "",
                        image: this.guidanceInput.image ? this.guidanceInput.image : "",
                    },
                    products: this.formProductsInput?.map((n) => ({
                        product_name: n.name,
                    })),
                    checked: false,
                    notes: "",
                    entity_type: this.formEntityInput[0]?.name,
                    purpose: this.purposeInput[0].name,
                    active: true,
                    mandatory: this.mandatoryInput ? true : false,
                    input: this.inputForm.map((n) => n.name),
                    output: this.outputForm.map((n) => n.name),
                    region: this.regionInput.map((n) => n.name),
                    country: this.countryInput.map((n) => n.name),
                    location: this.locationInput.map((n) => n.name),
                    company: this.formCompanyInput.map((n) => ({key: n.code, enabled: true})),
                };
                // const loading = this.$loading.show();
                EventBus.$emit("topprogress", "start");
                if (!this.editId) {
                    const {data} = await axios.post(`/research-methodology`, obj).catch((err) => this.$toast.error("Adding methodology failed"));
                    if (data.id) {
                        obj.id = data.id;
                        this.methodologies.push(obj);
                        this.$toast.success("Methodology added successfully");
                    }
                } else {
                    obj.id = this.editId;
                    let index = this.methodologies.findIndex((n) => n.id == this.editId);
                    const response = await axios.put(`/research-methodology`, obj).catch((err) => this.$toast.error("Updating methodology failed"));
                    if (response.status == 200) this.methodologies.splice(index, 1, obj);
                    this.$toast.success("Methodology updated successfully");
                }
                // loading.hide();
                EventBus.$emit("topprogress", "done");
                this.$modal.hide("company-methodology");
            }
        },
        handleEdit(id) {
            this.editId = id;
            let ele = this.methodologies.find((n) => n.id == id);
            this.formTypeInput = [{name: ele.report_type, code: ele.id}];
            this.stepInput = [{name: ele.step, code: ele.id}];
            this.moduleInput = ele.module ? [{name: ele.module, code: ele.id}] : [];
            this.guidanceInput.description = ele.guidance?.description;
            this.guidanceInput.image = ele.guidance?.image;
            this.formProductsInput = ele.products?.map((n, i) => ({
                name: n.product_name,
                code: i,
            }));
            this.formNotesInput = ele?.notes;
            this.formEntityInput = ele.entity_type ? [{name: ele.entity_type, code: ele.id}] : [];
            this.purposeInput = [{name: ele.purpose, code: ele.id}];
            this.mandatoryInput = ele?.mandatory;
            this.inputForm = ele.input?.map((n, i) => ({
                name: n,
                code: i,
            }));
            this.outputForm = ele.output?.map((n, i) => ({
                name: n,
                code: i,
            }));
            this.regionInput = ele.region?.map((n, i) => ({
                name: n,
                code: i,
            }));
            this.countryInput = ele.country?.map((n, i) => ({
                name: n,
                code: i,
            }));
            this.locationInput = ele.location?.map((n, i) => ({
                name: n,
                code: i,
            }));
            let savedCountries = [];
            this.companyOptions.forEach((e1) =>
                ele.company.forEach((e2) => {
                    if (e1.code == e2.key) {
                        savedCountries.push(e1);
                    }
                })
            );
            this.formCompanyInput = savedCountries?.map((n) => ({name: n.name, code: n.code}));
            this.myFiles = ele.guidance?.image ? [{source: ele.guidance.image, options: {type: "local"}}] : "";
            this.$modal.show("company-methodology");
        },
        async handleChecked(id) {
            let index = this.methodologies.findIndex((n) => n.id == id);
            this.methodologies[index].checked = !this.methodologies[index].checked;
            const obj = {
                research_methodology_id: id,
                checked: this.methodologies[index].checked,
            };
            const response = await axios.put(`/research-methodology/${this.$route.params.id}`, obj).catch((err) => this.$toast.error("Status update failed"));
            // if (data.status) {
            this.$forceUpdate();
            // }
        },
        async handleStatus(id) {
            let index = this.methodologies.findIndex((n) => n.id == id);
            this.methodologies[index].active = !this.methodologies[index].active;
            await axios.put(`/research-methodology`, this.methodologies[index]).catch((err) => this.$toast.error("Updating methodology failed"));
        },
        async handleMandatory(id) {
            let index = this.methodologies.findIndex((n) => n.id == id);
            this.methodologies[index].mandatory = !this.methodologies[index].mandatory;
            await axios.put(`/research-methodology`, this.methodologies[index]).catch((err) => this.$toast.error("Updating methodology failed"));
        },
        onImgSelected({target}) {
            let selectedImg = target.files[0];
            let reader = new FileReader();
            if (selectedImg) {
                reader.onload = (event) => {
                    this.guidanceInput.image = event.target.result;
                };
                reader.readAsDataURL(selectedImg);
            }
        },
        handleGuidanceClosed() {
            this.guidanceInput = {
                description: "",
                image: "",
            };
        },
        handleGuidance(id) {
            const data = this.methodologies.find((n) => n.id == id);
            this.guidanceInput.description = data.guidance?.description;
            this.guidanceInput.image = data.guidance?.image;
            this.$modal.show("guidance-modal");
        },
        async handleSaveNotes(id) {
            const index = this.methodologies.findIndex((n) => n.id == id);
            const obj = {
                research_methodology_id: id,
                checked: this.methodologies[index].checked,
                notes: this.methodologies[index].notes,
            };
            if (this.oldValueNotes != this.methodologies[index].notes.trim()) {
                await axios
                    .put(`/research-methodology/${this.$route.params.id}`, obj)
                    .then(() => this.$toast.success("Note saved successfully!"))
                    .catch((err) => this.$toast.error("Status update failed"));
                this.oldValueNotes = "";
            }
        },
        removeImage() {
            this.guidanceInput.image = "";
        },
        async uploadScreenShot(fieldName, file, metadata, load, error, progress, abort) {
            const formData = new FormData();
            formData.append("file", file, file.name);
            try {
                let response = await axios({
                    url: "/uploads/reports/images",
                    method: "post",
                    data: formData,
                    onUploadProgress: (e) => {
                        // updating progress indicator
                        progress(e.lengthComputable, e.loaded, e.total);
                    },
                });
                load(response?.data?.file);
                this.guidanceInput.image = response?.data?.file;
            } catch (error) {
                console.error("Request canceled", error);
            }
        },
        async handleFilePondInit() {
            // this.$refs.pond.addFile(await this.loadScreenshot())
        },
        revereImage() {
            this.openImagePreview = false;
        },
        async loadScreenshot(source, load, error, progress, abort, headers) {
            try {
                let response = await rawAxios.get(`${imagesBaseURL}/${source}`, {
                    responseType: "blob",
                });
                load(response?.data);
            } catch (error) {
                load();
            }
        },
        reassignFilter() {
            const obj = JSON.parse(sessionStorage.getItem("methodology-values"));
            console.log(obj, "object");
            if (obj) {
                this.entityValues = obj.entityValues;
                this.productTypeValues = obj.productTypeValues;
                this.purposeValues = obj.purposeValues;
                this.moduleValues = obj.moduleValues;
                this.typeValues = obj.typeValues;
                this.mandatoryValues = obj.mandatoryValues;
                this.statusValues = obj.statusValues;
                this.checkedValues = obj.checkedValues;
                this.inputValues = obj.inputValues;
                this.outputValues = obj.outputValues;
                this.regionValues = obj.regionValues;
                this.countryValues = obj.countryValues;
                this.locationValues = obj.locationValues;
                this.handleChange();
            }
        },
        showAllProducts(rowId) {
            this.methodologies = this.methodologies.map((el) => {
                if (el.id === rowId) {
                    return {...el, showMore: !el.showMore};
                } else {
                    return {...el};
                }
            });
        },
        showAllInput(rowId) {
            this.methodologies = this.methodologies.map((el) => {
                if (el.id === rowId) {
                    return {...el, showMoreInput: !el.showMoreInput};
                } else {
                    return {...el};
                }
            });
        },
        showAllOutput(rowId) {
            this.methodologies = this.methodologies.map((el) => {
                if (el.id === rowId) {
                    return {...el, showMoreOutput: !el.showMoreOutput};
                } else {
                    return {...el};
                }
            });
        },

        showAllCountry(rowId) {
            this.methodologies = this.methodologies.map((el) => {
                if (el.id === rowId) {
                    return {...el, showMoreCountry: !el.showMoreCountry};
                } else {
                    return {...el};
                }
            });
        },
        showAllLocation(rowId) {
            this.methodologies = this.methodologies.map((el) => {
                if (el.id === rowId) {
                    return {...el, showMoreLocation: !el.showMoreLocation};
                } else {
                    return {...el};
                }
            });
        },
        showAllRegion(rowId) {
            this.methodologies = this.methodologies.map((el) => {
                if (el.id === rowId) {
                    return {...el, showMoreRegion: !el.showMoreRegion};
                } else {
                    return {...el};
                }
            });
        },
        async resetFilter() {
            this.getColumns = {...this.getColumns, [this.getActiveHeading]: this.getColumns[this.getActiveHeading].map((el) => ({...el, values: el.multiple ? [] : null}))};
            await this.handleChange();
        },
        onPageChange(params) {
            console.log(params, "page");

            this.currentPage = params.currentPage;
        },

        onPerPageChange(params) {
            console.log(params, "perpage");
            this.currentPage = 1;
            this.pageLimit = params.currentPerPage;
        },
    },
    validations: {
        stepInput: {
            required,
        },
        moduleInput: {
            required,
        },
        purposeInput: {
            required,
        },
        formTypeInput: {
            required,
        },
        formEntityInput: {required},
        formProductsInput: {
            required,
        },
        formCompanyInput: {
            required,
        },
    },
    watch: {
        currentPage() {
            if (this.$route.name == "Research Methodology") {
                window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                });
            } else {
                document.getElementById("view").scrollTo({
                    top: 0,
                    behavior: "smooth",
                });
            }
        },
    },
};
