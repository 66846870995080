import axios from "@/axios";
import titleMixin from "@/mixins/titleMixin";
import "vue-multiselect/dist/vue-multiselect.min.css";
import "@/assets/css/multiselect.scss";
import {DateTime} from "luxon";
import "vue-good-table/dist/vue-good-table.css";
import {VueGoodTable} from "vue-good-table";
import Loader from "@/components/loader";
import {mapGetters} from "vuex";
import nLoader from "@/components/n-loader";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";

// Dynamic imports for components that are not used on first load
const Modal = () => import("@/components/modal");
const Button = () => import("@/components/button");
const Confirmation = () => import("@/components/confirmation");
const Input = () => import("@/components/input");
const VueMultiselect = () => import("@/components/vue-multiselect");
const ErrorMessage = () => import("@/components/server-error-message");

export default {
    title() {
        return `Case List`;
    },
    name: "case-list",
    components: {
        VueGoodTable,
        "neo-loader": Loader,
        Confirmation,
        "neo-modal": Modal,
        "neo-input": Input,
        "neo-button": Button,
        "neo-datepicker": DatePicker,
        nLoader,
        VueMultiselect,
        ErrorMessage,
    },
    props: [],
    mixins: [titleMixin],
    data() {
        return {
            key: 1,
            casePrefix: null,
            caseData: [],
            loader: false,
            buttonDisable: false,
            entityData: "",
            rows: [],
            isLoading: true,
            showCreateCaseModel: false,
            dateRange: [],
            columns: [
                {
                    label: "Case ID",
                    field: "case_id",
                    html: true,
                    width: "120px",
                    filterOptions: {
                        enabled: true,
                        placeholder: "Search by Case ID",
                        trigger: "enter",
                    },
                },
                {
                    label: "Status",
                    field: "case_state",
                    width: "120px",
                    filterOptions: {
                        customFilter: true,
                    },
                },
                {
                    label: "Case Type",
                    field: "case_type",
                    width: "180px",
                    filterOptions: {
                        enabled: true,
                        placeholder: "Search by Case Type",
                        trigger: "enter",
                    },
                },
                {
                    label: "Client Name",
                    field: "client_name",
                    width: "250px",
                    filterOptions: {
                        enabled: true,
                        placeholder: "Search by Client Name",
                        trigger: "enter",
                    },
                },
                {
                    label: "Created On",
                    field: "created_at",
                    type: "date",
                    width: "250px",
                    dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSSSS", // expects 2018-03-16
                    dateOutputFormat: "EEE, MMMM dd yyyy",
                },

                {
                    label: "Due Date",
                    field: "case_due_date",
                    tdClass: "tw-text-right",
                    width: "170px",
                    sortable: false,
                },
                {
                    label: "Completed On",
                    field: "resolution_date",
                    tdClass: "tw-text-right",
                    width: "250px",
                },
                {
                    label: "Rag Status",
                    field: "rag_status",
                    width: "120px",
                    filterOptions: {
                        enabled: true,
                        placeholder: "Search by case rag status",
                        trigger: "enter",
                    },
                },
                {
                    label: "Risk Category",
                    field: "risk_categories",
                    sortable: false,
                    width: "280px",
                    filterOptions: {
                        enabled: false,
                        placeholder: "Search by risk category",
                        trigger: "enter",
                    },
                },
                {
                    label: "Entity Name",
                    field: "entity_name",
                    width: "180px",
                    filterOptions: {
                        enabled: true,
                        placeholder: "Search by entity",
                        trigger: "enter",
                    },
                },

                {
                    label: "Case Name",
                    field: "case_name",
                    width: "250px",
                    filterOptions: {
                        enabled: true,
                        placeholder: "Search by Case Name",
                        trigger: "enter",
                    },
                },
                {
                    label: "Unacknowledged Events",
                    field: "monitoring_changes_count",
                    width: "150px",
                    tooltip: "Events represent no. of changes tracked in monitoring",
                    // filterOptions: {
                    //     enabled: true,
                    //     placeholder: "Search by Case Name",
                    //     trigger: "enter",
                    // },
                },
                {
                    label: "Upcoming review date",
                    field: "upcoming_review_date",
                    tdClass: "tw-text-right",
                    width: "170px",

                    // filterOptions: {
                    //     enabled: true,
                    //     placeholder: "Search by Case Name",
                    //     trigger: "enter",
                    // },
                },

                // {
                //     label: "Action",
                //     field: "action",
                //     width: "120px",
                //     html: true,
                //     sortable: false,
                // },
            ],
            paginationOptions: {
                enabled: true,
                dropdownAllowAll: false,
            },
            totalRecords: 0,
            serverParams: {
                current_page: 1,
                order_by: "case_id",
                order_type: "desc",
                limit: 10,
                filters: [],
            },
            status: ["Inprogress", "Monitoring"],
            search_status: [],
            selected_rag_status: [],
            selected_risk_Category: [],
            selected_client_name: [],
            selected_entity: [],
            selected_case_type: [],
            caseState: [],
            caseType: [],
            entity: [],
            ragStatus: [],
            clientNames: [],
            caseId: "",
            caseName: "",
            resolutionDateRange: [],
        };
    },
    computed: {
        ...mapGetters(["getPermissions", "getDirectIpLogin", "getLicenses", "checkPermissionsFetched", "getIsCaseListReadOnly", "getReadOnlyMode", "getIsUserAdmin"]),
        isAuth() {
            return this.getLicenses?.includes("case_list__case_data");
        },
        isAuthCreate() {
            return this.getLicenses?.includes("case_create__case_data");
        },
        isReadOnlyMode() {
            return this.getReadOnlyMode ?? false;
        },
    },
    async mounted() {
        this.isLoading = true;
        try {
            await this.$store.dispatch("getCaseListReadOnly");
            await this.caseInfoFilters();
            await this.fetchCaseList();
        } catch (error) {
            console.log(error, "ERROR");
        }
        this.isLoading = false;
        console.log(this.$refs);

        // const {data} = await xios.get(`/entity-identifier/entities`)
    },
    methods: {
        async fetchCaseList() {
            this.isLoading = true;
            try {
                const {data} = await axios.post(`/case-info/case-list`, this.serverParams);
                if (data?.cases) {
                    // this.caseData = data?.cases;
                    this.rows = [];
                    data.cases.forEach((e) => {
                        let row = {};
                        row["case_id"] = this.getLink(e);
                        row["entity_name"] = e.entity_name || "-";
                        row["case_type"] = e.data["Due Diligence Level"].value || "-";
                        row["client_name"] = e.client_name || "-";
                        row["case_state"] = e.case_state || "In Progress";
                        row["created_at"] = e.created_at || "-";
                        row["case_name"] = e.description || "-";
                        row["monitoring_changes_count"] = e.monitoring_changes_count || "-";
                        row["risk_categories"] = e.risk_categories.length > 0 ? e.risk_categories.join(", ") : "-";
                        row["rag_status"] = e?.rag_status || "-";
                        // row["upcoming_review_date"] = e.upcoming_review_date || "-";
                        row["upcoming_review_date"] = e.upcoming_review_date !== "" && e.case_state === "Monitoring" ? DateTime.fromJSDate(new Date(e.upcoming_review_date)).toFormat(`EEE, MMMM dd yyyy`) : "-";
                        if (e?.resolution_date) row["resolution_date"] = DateTime.fromJSDate(new Date(e.resolution_date)).toFormat(`EEE, MMMM dd yyyy`);
                        else row["resolution_date"] = "-";
                        if (e.case_due_date) {
                            row["case_due_date"] = e.case_due_date !== "" ? DateTime.fromJSDate(new Date(e.case_due_date)).toFormat(`EEE, MMMM dd yyyy`) : "-";
                        } else {
                            row["case_due_date"] = "-";
                        }

                        //     row["action"] = `<a href="/research/${e._id}" target="_blank" rel="noreferrer" class="tw-bg-brand hover:tw-bg-brand-hover  tw-px-2 tw-py-1 tw-rounded-full tw-text-white tw-text-sm tw-no-underline">
                        // View Case</a>`;
                        this.rows.push(row);
                    });
                }
                if (data?.pagination?.total_rows) {
                    this.totalRecords = data.pagination.total_rows;
                } else {
                    this.totalRecords = 0;
                }
            } catch (error) {
                console.error(error);
            }
            this.isLoading = false;
        },

        getLink(case_name) {
            const isManagedType = !case_name.self_service;
            const isAutomated = case_name.automated;
            const link_id = case_name._id;
            let readOnly = this.getIsCaseListReadOnly;
            let html_tag = "";
            if (isManagedType) {
                if (isAutomated) {
                    html_tag = `<span class="tw-font-bold">
                        <a href="/${readOnly ? "ro" : "automated/research"}/${link_id}/entities" target="_blank" rel="noreferrer" class="tw-text-blue-600 tw-no-underline"> ${link_id} </a>
                    </span>`;
                } else {
                    html_tag = `<span class="tw-font-bold">
                        <a href="/${readOnly ? "ro" : "research"}/${link_id}/entities" target="_blank" rel="noreferrer" class="tw-text-blue-600 tw-no-underline"> ${link_id} </a>
                    </span>`;
                }
            } else {
                if (isAutomated) {
                    html_tag = `<span class="tw-font-bold">
                        <a href="/${readOnly ? "ro" : "automated/research"}/${link_id}" target="_blank" rel="noreferrer" class="tw-text-blue-600 tw-no-underline"> ${link_id} </a>
                    </span>`;
                } else {
                    html_tag = `<span class="tw-font-bold">
                        <a href="/${readOnly ? "ro" : "research"}/${link_id}" target="_blank" rel="noreferrer" class="tw-text-blue-600 tw-no-underline"> ${link_id} </a>
                    </span>`;
                }
            }
            return html_tag;
        },

        clearDate() {
            this.dateRange = [];
        },

        clearDateRange() {
            this.resolutionDateRange = [];
        },

        setDateFormat(val) {
            return DateTime.fromISO(val).toFormat("EEE, MMMM dd yyyy, hh:mm a");
        },
        selectProduct() {
            this.$router.push("/case/product");
        },
        async createCase() {
            this.$modal.show("create_saas_case");
            this.loader = true;
            this.buttonDisable = true;
            const {data} = await axios.post(`/case-info/case-create`);

            if (data.case_id) {
                this.$toast.success(`Case ${data.case_id} created.`);
                this.loader = false;
                this.buttonDisable = false;
                // this.$router.push({path: `/research/${data.case_id}`}).catch(() => null)
                window.open(`/research/${data.case_id}`, "_self");
                sessionStorage.clear();
                localStorage.clear();
            } else {
                this.$toast.error("Something went wrong while creating new case.");
                this.loader = false;
                this.buttonDisable = false;
            }
        },
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },
        onPageChange(params) {
            this.updateParams({current_page: params.currentPage});
            this.fetchCaseList();
        },

        onPerPageChange(params) {
            this.updateParams({limit: params.currentPerPage, current_page: 1});
            this.fetchCaseList();
        },

        onSortChange(params) {
            console.log(params);
            let sort_params = {};
            if (params[0].type == "none") {
                this.$refs["my-table"].sorts[0].type = "asc";
                sort_params = {order_by: params[0].field, order_type: "asc", current_page: 1};
            } else {
                sort_params = {order_by: params[0].field, order_type: params[0].type, current_page: 1};
            }
            this.updateParams(sort_params);
            this.fetchCaseList();
        },

        // onColumnFilter(params) {
        //     let filters = Object.entries(params.columnFilters)
        //         .filter(([key, value]) => value)
        //         .map(([key, value]) => ({ filter_key: key, filter_value: value }));
        //     this.updateParams({ filters, current_page: 1 });
        //     this.fetchCaseList();
        // },
        disabledRange(date) {
            return date > new Date();
        },
        handleColumnFilter() {
            let filters = [];
            let dateRange = [];
            if (this.dateRange?.length > 0 && this.dateRange[0] && this.dateRange[1]) {
                let startDate = new Date(this.dateRange[0]);
                let endDate = new Date(this.dateRange[1]);
                startDate.setHours(0);
                startDate.setMinutes(0);
                startDate.setSeconds(0);
                startDate.setMilliseconds(0);

                //// SET END DATE TO HAVE CURRENT TIME
                endDate.setHours(23);
                endDate.setMinutes(59);
                endDate.setSeconds(59);
                endDate.setMilliseconds(999);
                this.dateRange = [Date.parse(startDate), Date.parse(endDate)];
                dateRange = [new Date(startDate), new Date(endDate)];
            }
            let resolutionDate = [];
            if (this.resolutionDateRange?.length > 0 && this.resolutionDateRange[0] && this.resolutionDateRange[1]) {
                let resStartDate = new Date(this.resolutionDateRange[0]);
                let resEndDate = new Date(this.resolutionDateRange[1]);
                resStartDate.setHours(0);
                resStartDate.setMinutes(0);
                resStartDate.setSeconds(0);
                resStartDate.setMilliseconds(0);

                //// SET END DATE TO HAVE CURRENT TIME
                resEndDate.setHours(23);
                resEndDate.setMinutes(59);
                resEndDate.setSeconds(59);
                resEndDate.setMilliseconds(999);
                this.resolutionDate = [Date.parse(resStartDate), Date.parse(resEndDate)];
                resolutionDate = [new Date(resStartDate), new Date(resEndDate)];
            }
            if (this.search_status.length > 0) {
                filters.push({filter_key: "case_state", filter_value: this.search_status});
            }
            if (this.selected_rag_status.length > 0) {
                filters.push({filter_key: "rag_status", filter_value: this.selected_rag_status});
            }
            if (this.selected_client_name.length > 0) {
                filters.push({filter_key: "client_name", filter_value: this.selected_client_name});
            }
            if (this.selected_case_type.length > 0) {
                filters.push({filter_key: "case_type", filter_value: this.selected_case_type});
            }
            if (this.selected_entity.length > 0) {
                filters.push({filter_key: "entity_name", filter_value: this.selected_entity});
            }
            if (this.caseId != "") {
                filters.push({filter_key: "case_id", filter_value: this.caseId});
            }
            if (this.caseName != "") {
                filters.push({filter_key: "case_name", filter_value: this.caseName});
            }
            if (dateRange?.length > 0) {
                filters.push({filter_key: "created_at", filter_value: dateRange});
            }
            if (resolutionDate?.length > 0) {
                filters.push({filter_key: "resolution_date", filter_value: resolutionDate});
            }
            this.$refs["my-table"].reset();
            this.updateParams({filters, current_page: 1});
            this.fetchCaseList();
        },
        cleartext(inputtype) {
            this.$refs[inputtype].clearAll();
        },
        resetFilter() {
            this.$refs["my-table"].reset();
            this.$refs["my-table"].currentPerPage = 10;
            this.key++;
            this.updateParams({
                current_page: 1,
                order_by: "case_id",
                order_type: "desc",
                limit: 10,
                filters: [],
            });
            this.caseId = "";
            this.search_status = [];
            this.selected_rag_status = [];
            this.selected_case_type = [];
            this.selected_client_name = [];
            this.selected_entity = [];
            this.caseName = "";
            this.resolutionDateRange = [];
            this.dateRange = [];
            this.fetchCaseList();
        },
        async caseInfoFilters() {
            const response = await axios.get("case-info/filters-values");
            this.caseState = response.data.case_state;
            this.caseType = response.data.case_type;
            this.entity = response.data.entity;
            this.ragStatus = response.data.rag_status;
            this.clientNames = response.data.client_name;
        },
        activateReadMore(row, column) {
            if (column == "case_state") {
                this.$set(row, "readMoreActivated", !row.readMoreActivated);
            } else if (column == "risk_categories") {
                this.$set(row, "readMoreActivatedRiskCategory", !row.readMoreActivatedRiskCategory);
            } else if (column == "entity_name") {
                this.$set(row, "readMoreActivatedEntityName", !row.readMoreActivatedEntityName);
            }
        },
        filteredList() {
            let list = cloneDeep(this.rows);
            return uniqBy(list);
        },
    },
    async created() {
        // await this.fetchPermissions();
    },
};
