import axios from "@/axios";
import titleMixin from "@/mixins/titleMixin";
import VueMultiselect from "@/components/vue-multiselect";
// import "vue-multiselect/dist/vue-multiselect.min.css";
import "@/assets/css/multiselect.scss";
import {mapActions, mapGetters} from "vuex";
import {required, url} from "vuelidate/lib/validators";
import vueFilePond from "vue-filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js";
import FilePondPluginImagePreview from "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm.js";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview);
import rawAxios from "axios";
import nLoader from "@/components/n-loader";
import {EventBus} from "@/main.js";
import iamApi from "@/axios/user";
const ErrorMessage = () => import("@/components/server-error-message");
const Input = () => import("@/components/input");
const Dropdown = () => import("@/components/dropdown");
const Button = () => import("@/components/button");
const Badge = () => import("@/components/badge");
const ToggleSwitch = () => import("@/components/toggle-switch");

export default {
    title() {
        return this.$route.name === "Configure Product" ? "Product Admin" : `Sources`;
    },
    name: "sources",
    components: {
        "neo-input": Input,
        "neo-dropdown": Dropdown,
        "neo-button": Button,
        "neo-badge": Badge,
        "neo-toggle-switch": ToggleSwitch,
        VueMultiselect,
        nLoader,
        ErrorMessage,
    },
    mixins: [titleMixin],
    data() {
        return {
            columns: [
                {
                    label: "URL",
                    field: "url",
                    width: "120px",
                    analystAccess: true,
                    sortable: false,
                },
                {
                    label: "Region",
                    field: "region",
                    width: "120px",
                    analystAccess: true,
                    multiple: true,
                    closeOnSelect: false,
                    clearOnSelect: true,
                    groupSelect: true,
                    checkboxes: true,
                    taggable: false,
                    limit: 1,
                    filterOptions: {
                        enabled: true,
                        placeholder: "Search by region",
                        trigger: "enter",
                    },
                    values: [],
                    sortable: false,
                },
                {
                    label: "Country",
                    field: "country",
                    width: "120px",
                    analystAccess: true,
                    multiple: true,
                    closeOnSelect: false,
                    clearOnSelect: true,
                    groupSelect: true,
                    checkboxes: true,
                    taggable: false,
                    limit: 1,
                    filterOptions: {
                        enabled: true,
                        placeholder: "Search by country",
                        trigger: "enter",
                    },
                    values: [],
                    sortable: false,
                },
                {
                    label: "Location",
                    field: "location",
                    width: "120px",
                    analystAccess: true,
                    multiple: true,
                    closeOnSelect: false,
                    clearOnSelect: true,
                    groupSelect: true,
                    checkboxes: true,
                    taggable: false,
                    limit: 1,
                    filterOptions: {
                        enabled: true,
                        placeholder: "Search by location",
                        trigger: "enter",
                    },
                    values: [],
                    sortable: false,
                },
                {
                    label: "Type",
                    field: "source_type",
                    width: "120px",
                    analystAccess: true,
                    multiple: true,
                    closeOnSelect: false,
                    clearOnSelect: true,
                    groupSelect: true,
                    checkboxes: true,
                    taggable: false,
                    limit: 1,
                    filterOptions: {
                        enabled: true,
                        placeholder: "Search by type",
                        trigger: "enter",
                    },
                    values: [],
                    sortable: false,
                },
                {
                    label: "Others",
                    field: "others",
                    width: "120px",
                    analystAccess: true,
                    multiple: true,
                    closeOnSelect: false,
                    clearOnSelect: true,
                    groupSelect: true,
                    checkboxes: true,
                    taggable: false,
                    limit: 1,
                    filterOptions: {
                        enabled: true,
                        placeholder: "Search by others",
                        trigger: "enter",
                    },
                    values: [],
                    sortable: false,
                },
                {
                    label: "Guidance",
                    field: "guidance",
                    width: "120px",
                    analystAccess: true,
                    sortable: false,
                },
                {
                    label: "Mandatory",
                    field: "mandatory",
                    width: "120px",
                    analystAccess: true,
                    checkboxes: true,
                    taggable: false,
                    limit: 1,
                    filterOptions: {
                        enabled: true,
                        placeholder: "Search by mandatory",
                        trigger: "enter",
                    },
                    values: "",
                    sortable: false,
                },
                {
                    label: "Status",
                    field: "active",
                    width: "120px",
                    analystAccess: false,
                    checkboxes: true,
                    taggable: false,
                    limit: 1,
                    filterOptions: {
                        enabled: true,
                        placeholder: "Search by status",
                        trigger: "enter",
                    },
                    values: "",
                    sortable: false,
                },
                {
                    label: "Checked",
                    field: "checked",
                    width: "120px",
                    analystAccess: true,
                    checkboxes: true,
                    taggable: false,
                    limit: 1,
                    filterOptions: {
                        enabled: true,
                        placeholder: "Search by checked",
                        trigger: "enter",
                    },
                    values: "",
                    sortable: false,
                },
                {
                    label: "Notes",
                    field: "notes",
                    width: "120px",
                    analystAccess: true,
                    sortable: false,
                },
                {
                    label: "Edit",
                    field: "edit",
                    width: "120px",
                    analystAccess: false,
                    sortable: false,
                },
            ],
            osintData: [],
            currentPage: 1,
            pageLimit: 10,
            regionOptions: [],
            countryOptions: [],
            locationOptions: [],
            source_typeOptions: [],
            othersOptions: [],
            searchMethodsOptions: [],
            searchMethodsValues: [],
            mandatoryOptions: [
                {
                    name: "Yes",
                    code: true,
                },
                {
                    name: "No",
                    code: false,
                },
            ],
            activeOptions: [
                {
                    name: "Active",
                    code: true,
                },
                {
                    name: "Inactive",
                    code: false,
                },
            ],
            checkedOptions: [
                {
                    name: "Yes",
                    code: true,
                },
                {
                    name: "No",
                    code: false,
                },
            ],
            urlInput: "",
            toolInput: "",
            toggle: false,
            formRegionValues: [],
            formCountryValues: [],
            formTypeValues: [],
            formOtherValues: [],
            formSearchMethodsValues: [],
            formLocationValues: [],
            error: false,
            editId: "",
            addTagView: false,
            tagType: "",
            newTagValue: [],
            newTagOptions: [],
            guidanceInput: {
                description: "",
                image: "",
            },
            myServer: {
                process: this.uploadScreenShot,
                load: this.loadScreenshot,
                revert: this.revereImage,
            },
            myFiles: "",
            openImagePreview: false,
            mandatoryInput: false,
            inactiveReasonInput: "",
            inactiveReasonId: "",
            oldValueNotes: "",
            isLoading: false,
            companyOptions: [],
            formCompanyValues: [],
            adminAccounts: [],
        };
    },
    computed: {
        ...mapGetters(["getReadOnlyMode", "getDirectIpLogin", "getOsintData", "getPermissions", "checkPermissionsFetched", "getLicenses", "getUserAuthDetails", "getProduct"]),
        filteredResults() {
            this.currentPage = this.search ? 1 : this.currentPage;
            if (!this.search && this.search !== "") return this.osintData;
            return this.osintData.filter((item) => item.url.toLowerCase().includes(this.search?.toLowerCase()) || item.guidance?.description?.toLowerCase().includes(this.search?.toLowerCase()) || item.tool?.toLowerCase().includes(this.search?.toLowerCase()) || this.containsCheck(item?.region ?? []) || this.containsCheck(item?.country ?? []) || this.containsCheck(item?.location ?? []) || this.containsCheck(item?.source_type ?? []) || this.containsCheck(item?.others ?? []));
        },
        search() {
            return this.$store.getters.search[this.$route.name];
        },
        getTotalPages() {
            return Math.ceil(this.filteredResults.length / 10);
        },
        permissionsFetched() {
            return this.checkPermissionsFetched;
        },
        isAuth() {
            if (this.$route.name === "Sources") {
                return this.getLicenses.includes("sources__sources");
            } else {
                return this.getPermissions.includes("sources__sources");
            }
        },
        isNeotasUser() {
            return this.getUserAuthDetails.email.includes("@neotas.com");
        },
        isNeotasAdmin() {
            return this.getUserAuthDetails?.userRole?.includes("neotas-superadmin");
        },
        rows() {
            return this.filteredResults.slice((this.currentPage - 1) * this.pageLimit, this.currentPage * this.pageLimit);
        },
        headers() {
            if (this.isNeotasAdmin) return this.$route.name === "Sources" ? this.columns.filter((col) => !["checked", "notes"].includes(col.field)) : this.columns.filter((col) => col.analystAccess);
            else return this.$route.name === "Sources" ? this.columns.filter((col) => !["checked", "notes", "edit"].includes(col.field)) : this.columns.filter((col) => col.analystAccess);
        },
        imagesBaseURL() {
            return process.env.VUE_APP_IMAGES_URL;
        },
    },
    async mounted() {
        this.getAdminAccounts();
        // await this.fetchCompanyProducts(); // Function to fetch company related products
        EventBus.$emit("topprogress", "start");
        let payload = {};
        if (this.$route.params.id)
            payload = {
                case_id: this.$route.params.id,
            };
        else if (this.getProduct?.key)
            payload = {
                product_id: this.getProduct?.key,
            };

        const url = `/osint-sources/list`;

        this.isLoading = true;

        await this.fetchOsintSources({
            url: url,
            data: payload,
        });

        this.osintData = this.getOsintData;

        this.isLoading = false;

        this.regionOptions = this.getUniqueList("region");
        this.countryOptions = this.getUniqueList("country");
        this.source_typeOptions = this.getUniqueList("source_type");
        this.othersOptions = this.getUniqueList("others");
        this.locationOptions = this.getUniqueList("location");
        if (this.adminAccounts) {
            this.companyOptions = this.adminAccounts.map((el) => ({name: el.name, code: el.id}));
        }
        this.reassignFilter();
        EventBus.$emit("topprogress", "done");
    },
    methods: {
        ...mapActions(["fetchOsintSources", "fetchPermissions", "fetchCompanyProducts"]),
        getTag(tag) {
            return {
                name: tag,
                code: tag.substring(0, 2) + Math.floor(Math.random() * 10000000),
            };
        },

        getOptionsList(list) {
            return [
                {
                    group: list === "newTagOptions" ? "new tag" : list.split("Options")[0],
                    categories: this[list],
                },
            ];
        },

        limitText(length) {
            return ` +${length}`;
        },

        onPageChange(params) {
            this.currentPage = params.currentPage;
        },

        onPerPageChange(params) {
            this.currentPage = 1;
            this.pageLimit = params.currentPerPage;
        },

        resetFilter() {
            this.headers.forEach((header) => {
                if (header.filterOptions) header["values"] = [];
            });
            this.handleChange();
        },

        getUniqueList(dataset) {
            return [
                ...new Set(
                    [].concat.apply(
                        [],
                        this.osintData.filter((n) => n[dataset]).map((n1) => n1[dataset])
                    )
                ),
            ]
                .map((n2, i) => ({name: n2, code: i}))
                .sort((a, b) => a.name.localeCompare(b.name));
        },
        async handleAddSource() {
            this.$v.$touch();
            if (!this.$v.$error) {
                if (this.formCountryValues.length > 0 || this.formRegionValues.length > 0 || this.formOtherValues.length > 0 || this.formTypeValues.length > 0 || this.formSearchMethodsValues.length > 0 || this.formLocationValues.length > 0) {
                    let obj = {
                        tool: this.toolInput,
                        url: this.urlInput,
                        region: this.formRegionValues?.map((n) => n.name),
                        active: true,
                        source_type: this.formTypeValues?.map((n) => n.name),
                        others: this.formOtherValues?.map((n) => n.name),
                        country: this.formCountryValues?.map((n) => n.name),
                        // search_method: this.formSearchMethodsValues?.map((n) => n.name),
                        location: this.formLocationValues?.map((n) => n.name),
                        guidance: {
                            description: this.guidanceInput.description,
                            image: this.guidanceInput.image,
                        },
                        mandatory: this.mandatoryInput,
                        notes: "",
                        company: this.formCompanyValues.map((n) => ({key: n.code, enabled: true})),
                    };
                    const loading = this.$loading.show();
                    if (!this.editId) {
                        const {data} = await axios.post(`/osint-sources`, obj).catch((err) => this.$toast.error("Adding source failed"));
                        // if (data) {
                        obj.id = data.id;
                        this.osintData.push(obj);
                        this.$toast.success("Source added successfully");
                        // }
                    } else {
                        obj.id = this.editId;
                        let index = this.osintData.findIndex((n) => n.id == this.editId);
                        const {data} = await axios.put(`/osint-sources`, obj).catch((err) => this.$toast.error("Updating source failed"));
                        this.osintData.splice(index, 1, obj);
                        this.$toast.success("Source updated successfully");
                    }
                    loading.hide();
                    this.$modal.hide("add-source-modal");
                } else {
                    this.error = true;
                    this.$toast.error("Source not added");
                }
            }
        },
        handleClosed() {
            this.urlInput = "";
            this.toolInput = "";
            this.formRegionValues = [];
            this.formTypeValues = [];
            this.formOtherValues = [];
            this.formCountryValues = [];
            this.formSearchMethodsValues = [];
            this.formLocationValues = [];
            this.error = false;
            this.editId = "";
            this.addTagView = false;
            this.tagType = "";
            this.guidanceInput = {
                description: "",
                image: "",
            };
            this.myFiles = "";
            this.mandatoryInput = false;
            this.$v.$reset();
        },
        async handleStatus(id) {
            let index = this.osintData.findIndex((n) => n.id == id);
            this.osintData[index].active = !this.osintData[index].active;
            if (!this.osintData[index].active) {
                this.inactiveReasonId = id;
                this.$modal.show("inactive-modal");
            }
            this.osintData[index].inactive_reason = "";
            this.osintData[index].id = this.osintData[index].id;
            const {data} = await axios.put(`/osint-sources`, this.osintData[index]).catch((err) => this.$toast.error("Status update failed"));
            // if (data.status) {
            this.$forceUpdate();
            // }
        },
        async handleMandatory(id) {
            let index = this.osintData.findIndex((n) => n.id == id);
            this.osintData[index].mandatory = !this.osintData[index].mandatory;
            await axios.put(`/osint-sources`, this.osintData[index]).catch((err) => this.$toast.error("Updating Sources failed"));
        },
        handleEdit(id) {
            this.editId = id;
            let index = this.osintData.findIndex((n) => n.id == id);
            const ele = this.osintData[index];
            this.urlInput = ele.url;
            this.toolInput = ele.tool;
            this.formRegionValues = ele.region?.map((n, i) => ({name: n, code: i}));
            this.formTypeValues = ele.source_type?.map((n, i) => ({
                name: n,
                code: i,
            }));
            this.formOtherValues = ele.others?.map((n, i) => ({name: n, code: i}));
            this.formCountryValues = ele.country?.map((n, i) => ({
                name: n,
                code: i,
            }));
            this.formLocationValues = ele.location?.map((n, i) => ({
                name: n,
                code: i,
            }));
            let savedCountries = [];
            this.companyOptions.forEach((e1) =>
                ele.company.forEach((e2) => {
                    if (e1.code == e2.key) {
                        savedCountries.push(e1);
                    }
                })
            );
            this.formCompanyValues = savedCountries?.map((n) => ({name: n.name, code: n.code}));
            this.myFiles = ele.guidance?.image ? [{source: ele.guidance.image, options: {type: "local"}}] : "";
            this.guidanceInput.description = ele.guidance?.description;
            this.guidanceInput.image = ele.guidance?.image;
            this.mandatoryInput = ele?.mandatory;
            this.$modal.show("add-source-modal");
        },
        async handleChange() {
            this.currentPage = 1;
            let url = `/osint-sources/list`;
            let payload = {};
            if (this.$route.params.id)
                payload = {
                    case_id: this.$route.params.id,
                };

            const obj = {};
            this.headers.forEach((header) => {
                if (header.filterOptions && header["values"]) {
                    if (Array.isArray(header["values"]) && header["values"]?.length) {
                        payload[header.field] = header["values"].map((n) => n.name.replace(/ *\([^)]*\) */g, ""));
                        obj[`${header.field}Options`] = payload[header.field];
                    } else if (!Array.isArray(header["values"]) && header["values"]) {
                        payload[header.field] = header["values"].code;
                        obj[`${header.field}Options`] = payload[header.field];
                    }
                }
            });
            if (this.$route.params.id) {
                obj.case_id = this.$route.params.id;
            }
            sessionStorage.setItem("sources-values", JSON.stringify(obj));
            const loading = this.$loading.show();
            await this.fetchOsintSources({
                url: url,
                data: payload,
            });
            loading.hide();
            this.osintData = this.getOsintData;
        },
        async getAdminAccounts() {
            try {
                let response = await iamApi.get("/api/v1/accounts/admin/all");
                this.adminAccounts = response.data;
            } catch (error) {}
        },
        changeModelView(type) {
            this.tagType = type;
            this.newTagValue = [];
            let options = [];
            switch (type) {
                case "Region":
                    options = this.regionOptions;
                    break;
                case "Country":
                    options = this.countryOptions;
                    break;
                case "Location":
                    options = this.locationOptions;
                    break;
                case "Type":
                    options = this.source_typeOptions;
                    break;
                case "Other":
                    options = this.othersOptions;
                    break;
                case "Methods":
                    options = this.searchMethodsOptions;
                    break;
                case "Company":
                    options = this.companyOptions;
                    break;
                default:
                    break;
            }
            this.newTagOptions = options?.map((n) => {
                let json = {...n};
                json["$isDisabled"] = true;
                return json;
            });
            this.addTagView = true;
        },
        addNewTag(newTag) {
            const tag = this.getTag(newTag);
            this.newTagValue.push(tag);
        },
        handleAddNewTag() {
            switch (this.tagType) {
                case "Region":
                    this.regionOptions = [...this.regionOptions, ...this.newTagValue];
                    this.formRegionValues = [...this.formRegionValues, ...this.newTagValue];
                    break;
                case "Country":
                    this.countryOptions = [...this.countryOptions, ...this.newTagValue];
                    this.formCountryValues = [...this.formCountryValues, ...this.newTagValue];
                    break;
                case "Location":
                    this.locationOptions = [...this.locationOptions, ...this.newTagValue];
                    this.formLocationValues = [...this.formLocationValues, ...this.newTagValue];
                    break;
                case "Type":
                    this.source_typeOptions = [...this.source_typeOptions, ...this.newTagValue];
                    this.formTypeValues = [...this.formTypeValues, ...this.newTagValue];
                    break;
                case "Other":
                    this.othersOptions = [...this.othersOptions, ...this.newTagValue];
                    this.formOtherValues = [...this.formOtherValues, ...this.newTagValue];
                    break;
                case "Methods":
                    this.searchMethodsOptions = [...this.searchMethodsOptions, ...this.newTagValue];
                    this.formSearchMethodsValues = [...this.formSearchMethodsValues, ...this.newTagValue];
                    break;
                case "Company":
                    this.companyOptions = [...this.companyOptions, ...this.newTagValue];
                    this.formCompanyValues = [...this.formCompanyValues, ...this.newTagValue];
                    break;
                default:
                    break;
            }
            this.addTagView = false;
            this.tagType = "";
        },
        removeImage() {
            this.guidanceInput.image = "";
        },
        async uploadScreenShot(fieldName, file, metadata, load, error, progress, abort) {
            const formData = new FormData();
            formData.append("file", file, file.name);
            try {
                let response = await axios({
                    url: "/uploads/reports/images",
                    method: "post",
                    data: formData,
                    onUploadProgress: (e) => {
                        // updating progress indicator
                        progress(e.lengthComputable, e.loaded, e.total);
                    },
                });
                load(response?.data?.file);
                this.guidanceInput.image = response?.data?.file;
            } catch (error) {
                console.error("Request canceled", error);
            }
        },
        async handleFilePondInit() {
            // this.$refs.pond.addFile(await this.loadScreenshot())
        },
        revereImage() {
            this.openImagePreview = false;
        },
        async loadScreenshot(source, load, error, progress, abort, headers) {
            try {
                let response = await rawAxios.get(`${imagesBaseURL}/${source}`, {
                    responseType: "blob",
                });
                load(response?.data);
            } catch (error) {
                load();
            }
        },
        handleGuidanceClosed() {
            this.guidanceInput = {
                description: "",
                image: "",
            };
        },
        handleGuidance(id) {
            const data = this.osintData.find((n) => n.id == id);
            this.guidanceInput.description = data.guidance?.description;
            this.guidanceInput.image = data.guidance?.image;
            this.$modal.show("guidance-modal");
        },
        async handleSaveNotes(id, notes) {
            const index = this.osintData.findIndex((n) => n.id == id);
            this.osintData[index].notes = notes;
            const obj = {
                source_id: id,
                checked: this.osintData[index].checked,
                notes: this.osintData[index].notes,
            };
            if (this.oldValueNotes != this.osintData[index].notes.trim()) {
                await axios
                    .put(`/osint-sources/${this.$route.params.id}`, obj)
                    .then(() => this.$toast.success("Note saved successfully!"))
                    .catch((err) => this.$toast.error("Note update failed"));
                this.oldValueNotes = "";
            }
        },
        async handleChecked(id) {
            let index = this.osintData.findIndex((n) => n.id == id);
            this.osintData[index].checked = !this.osintData[index].checked;
            const obj = {
                source_id: id,
                checked: this.osintData[index].checked,
            };
            const response = await axios.put(`/osint-sources/${this.$route.params.id}`, obj).catch((err) => this.$toast.error("Status update failed"));
            // if (data.status) {
            this.$forceUpdate();
            // }
        },
        containsCheck(item) {
            const array = item.filter((n) => n.toLowerCase().includes(this.search?.toLowerCase()));
            return array.length > 0 ? true : false;
        },
        onCopy(e) {
            this.$toast.success(`URL Copied ${e.text}`);
        },
        handleInactiveClosed() {
            this.inactiveReasonInput = "";
            this.inactiveReasonId = "";
        },
        async handleReason() {
            const index = this.osintData.findIndex((n) => n.id == this.inactiveReasonId);
            this.osintData[index].inactive_reason = this.inactiveReasonInput;
            await axios
                .put(`/osint-sources`, this.osintData[index])
                .catch((err) => this.$toast.error("Reason for inactive saved failed"))
                .then(() => this.$toast.success("Reason for inactive Saved!"));
            this.$modal.hide("inactive-modal");
        },
        handleOpenReason(reason, id) {
            this.inactiveReasonId = id;
            this.inactiveReasonInput = reason;
            this.$modal.show("inactive-modal");
        },
        reassignFilter() {
            const obj = JSON.parse(sessionStorage.getItem("sources-values"));
            if (obj && (obj.case_id === this.$route.params.id || this.$route.name == "Sources")) {
                this.headers.forEach((header) => {
                    if (header.filterOptions) header["values"] = obj[`${header.field}Values`];
                });
                this.handleChange();
            }
        },
    },
    validations: {
        urlInput: {
            required,
            url,
        },
    },
    watch: {
        currentPage() {
            if (this.$route.name == "Sources") {
                window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                });
            } else {
                document.getElementById("view").scrollTo({
                    top: 0,
                    behavior: "smooth",
                });
            }
        },
    },
};
