import Button from "@/components/button";
import titleMixin from "@/mixins/titleMixin";
import KeywordsTable from "@/views/manage-keywords/keywords-table";
import KeywordsForm from "@/views/manage-keywords/keywords-form";
import {mapGetters} from "vuex";
import {cloneDeep} from "lodash";
import {showToast} from "@/utils/functions";

const X_STATES = {
    initial: "initial",
    error: "error",
    ready: "ready",
};

export default {
    name: "manage-keywords",
    components: {
        "neo-button": Button,
        "keywords-table": KeywordsTable,
        "keywords-form": KeywordsForm,
    },
    mixins: [titleMixin],
    title() {
        return `Keywords Admin`;
    },
    data() {
        return {
            selectedKeywordId: "",
            keywordEntityCopy: null,
            xState: X_STATES.initial,
        };
    },
    async mounted() {
        await this.loadInitialData();
    },
    computed: {
        isEditMode() {
            return this.selectedKeywordId !== "";
        },
        ...mapGetters([
            {
                entities: "getKeywordEntities",
                errorMessage: "getErrorMessage",
            },
            "getDirectIpLogin",
        ]),
    },
    watch: {
        errorMessage(newValue, oldValue) {
            showToast(newValue);
        },
    },
    methods: {
        isLoading() {
            return this.xState === X_STATES.loading;
        },
        isInitial() {
            return this.xState === X_STATES.initial;
        },
        isError() {
            return this.xState === X_STATES.error;
        },
        changeXState(newXState) {
            const isExistedXState = Object.values(X_STATES).some((xStateValue) => xStateValue === newXState);
            if (!isExistedXState) {
                throw new Error(`The state ${isExistedXState} isn't supported`);
            }

            this.xState = newXState;
        },
        async refresh() {
            this.changeXState(X_STATES.initial);
            await this.loadInitialData();
        },
        openEditModal(entityId) {
            this.selectedKeywordId = entityId;
            this.$modal.show("keywords_manage_modal");
        },
        openCopyModal(entityId) {
            this.selectedKeywordId = "";
            const foundEntity = this.entities.find((entity) => entity._id === entityId);
            this.keywordEntityCopy = cloneDeep(foundEntity);
            this.keywordEntityCopy.keyword_set_name += " (Copy)";
            this.$modal.show("keywords_manage_modal");
        },
        openCreateModal() {
            this.$modal.show("keywords_manage_modal");
        },
        clearSelectedEntity() {
            this.selectedKeywordId = "";
            this.keywordEntityCopy = null;
        },
        async loadInitialData() {
            try {
                await Promise.all([this.$store.dispatch("loadKeywordLanguages")]);
                this.changeXState(X_STATES.ready);
            } catch (e) {
                console.error(e);
                this.changeXState(X_STATES.error);
            }
        },
        beforeModalClose() {
            this.clearSelectedEntity();
        },
    },
};
