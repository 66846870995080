<template>
    <div v-if="currentUser" class="card tw-flex tw-flex-col tw-max-w-full tw-h-full tw-w-full tw-p-4">
        <div class="tw-bg-white tw-shadow sm:tw-rounded-lg tw-overflow-hidden">
            <div class="tw-px-4 tw-py-5 sm:tw-px-6">
                <h3 class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900">User Information</h3>
                <p class="tw-mt-1 tw-max-w-2xl tw-text-sm tw-text-gray-500">User details and licenses.</p>
            </div>
            <div class="tw-border-0 tw-border-t tw-border-gray-200 tw-border-solid">
                <dl>
                    <div class="tw-bg-gray-50 tw-px-4 tw-py-5 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6">
                        <dt class="tw-text-sm tw-font-medium tw-text-gray-500">Full name</dt>
                        <dd class="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0 tw-capitalize">{{ getUserFullName(currentUser) }}</dd>
                    </div>
                    <div class="tw-bg-white tw-px-4 tw-py-5 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6">
                        <dt class="tw-text-sm tw-font-medium tw-text-gray-500">Email address</dt>
                        <dd class="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">{{ currentUser && currentUser.email }}</dd>
                    </div>
                    <div class="tw-bg-gray-50 tw-px-4 tw-py-5 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6">
                        <dt class="tw-text-sm tw-font-medium tw-text-gray-500">Groups</dt>
                        <dd class="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0" v-if="currentUser.status === 'CONFIRMED'">
                            <div class="tw-flex tw-gap-x-2 tw-font-xs" v-if="currentUser && currentUser.groups.length >= 1">
                                <Tag v-bind:key="g.group_id" class="tw-font-xs tw-capitalize" v-for="g in currentUser.groups" :tag="g.name"></Tag>
                            </div>
                            <div class="" v-else>
                                N/A
                            </div>
                        </dd>
                    </div>
                    <div class="tw-bg-white tw-px-4 tw-py-5 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6">
                        <dt class="tw-text-sm tw-font-medium tw-text-gray-500">Admin Privileges</dt>
                        <dd class="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">
                            <toggle-switch :status="isAdmin" :key="currentUser.email" @click="handleAdminChange(currentUser)" class="tw-cursor-pointer" title="Make Admin" />
                        </dd>
                    </div>
                    <div class="tw-bg-white tw-px-4 tw-py-5 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6">
                        <dt class="tw-text-sm tw-font-medium tw-text-gray-500">Access all cases</dt>
                        <dd class="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">
                            <toggle-switch :status="canAccessAllCases" :key="currentUser.email" @click="handleCaseAccess(currentUser)" class="tw-cursor-pointer" title="Access all cases" />
                        </dd>
                    </div>
                    <div class="tw-bg-white tw-px-4 tw-py-5 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6">
                        <dt class="tw-text-sm tw-font-medium tw-text-gray-500">Browser Extension Login</dt>
                        <dd class="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">
                            <toggle-switch :status="isBrowserExtensionLogin" :key="currentUser.email" @click="handleBrowserExtension(currentUser)" class="tw-cursor-pointer" title="Browser Extension Login" />
                        </dd>
                    </div>
                    <div class="tw-bg-white tw-px-4 tw-py-5 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6">
                        <dt class="tw-text-sm tw-font-medium tw-text-gray-500">Direct Ip Login</dt>
                        <dd class="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">
                            <toggle-switch :status="isDirectIpLogin" :key="currentUser.email" @click="handleDirectIpLogin(currentUser)" class="tw-cursor-pointer" title="Direct Ip Login" />
                        </dd>
                    </div>
                    <div class="tw-bg-gray-50 tw-px-4 tw-py-5 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6">
                        <dt class="tw-text-sm tw-font-medium tw-text-gray-500">Licenses</dt>
                        <dd class="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0 tw-max-h-60 tw-overflow-y-auto scroll-bar">
                            <template v-if="isLincensesLoading">
                                <n-loader :loading="isLincensesLoading" class="tw-h-6 tw-w-6 tw-text-brand" />
                            </template>
                            <template v-else>
                                <div class="tw-max-w-full" v-for="license in getLicenseList" :key="license.value">
                                    <!-- <div> -->
                                    <input class="tw-hidden" type="checkbox" :id="`task_2${license.value}`" :checked="license.selected" :disabled="license.isDisabled" @change="handleChange($event, license)" />
                                    <label class="tw-flex tw-items-center tw-h-10 tw-px-2 tw-rounded" :class="license.isDisabled ? 'tw-cursor-not-allowed' : 'tw-cursor-pointer hover:tw-bg-gray-100'" :for="`task_2${license.value}`">
                                        <span class="tw-flex tw-items-center tw-justify-center tw-w-5 tw-h-5 tw-text-transparent tw-border-2 tw-border-solid tw-border-gray-300 tw-rounded-full">
                                            <svg class="tw-w-4 tw-h-4 tw-fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                                <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                                            </svg>
                                        </span>
                                        <!-- {{license}} -->
                                        <span class="tw-ml-4 tw-text-sm">{{ `${license.license_pack_name} (${license.remaining})` }}</span>
                                    </label>
                                    <!-- </div> -->
                                </div>
                            </template>
                        </dd>
                    </div>
                </dl>
            </div>
            <div class="tw-flex tw-justify-end tw-px-4 tw-py-5 tw-border-t tw-border-gray-200 tw-border-solid tw-border-0" v-if="currentUser && !currentUser.groups.some((el) => el.name == 'admin')">
                <neo-button label="Delete User" class="tw-bg-red-500 tw-border-0 hover:tw-bg-red-700 tw-text-white tw-font-medium tw-p-2 tw-ml-2" borderRadius=".25rem" bg="var(--color-danger)" hoverBg="var(--color-danger)" @click="$modal.show('confirm_delete')" icon="trash" />
            </div>
        </div>
        <neo-confirmation v-if="showCasePermissionDeleteModal" title="Delete permission" description="Do you want to delete this permission?" @cancel="showCasePermissionDeleteModal = false" @delete="removeCasePermissionById()" />

        <neo-confirmation v-if="showUserRemoveModal" title="Delete user" description="Do you want to delete this user?" @cancel="showUserRemoveModal = false" @delete="removeCasePermissionById()" />
        <neo-confirmation v-if="showUserPermissionModal" title="Remove user permission" description="Do you want to remove this user permission?" @cancel="showUserRemoveModal = false" @delete="removeUserPermission()" />
    </div>
</template>

<script>
import Tag from "@/components/tag";
import Button from "@/components/button";
import nLoader from "@/components/n-loader";
import {VueGoodTable} from "vue-good-table";
import Confirmation from "@/components/confirmation";
import VueMultiselect from "@/components/vue-multiselect";
import axios from "../../axios/user";
import {default as saasAxios} from "../../axios";
import {isEqual} from "lodash";
import toggleSwitch from "@/components/toggle-switch";

export default {
    name: "UserDetailsCard",
    props: ["groups", "cases", "currentUser", "addUserPermission"],

    components: {Tag, "vue-good-table": VueGoodTable, "neo-button": Button, nLoader, "neo-tag": Tag, "neo-confirmation": Confirmation, VueMultiselect, toggleSwitch},
    async mounted() {
        this.isLincensesLoading = true;
        await this.getResourcePermissions();
        await this.getUserPermissions();
        await this.fetchLincenses();
        await this.fetchUserLincenses();
        this.isLincensesLoading = false;
        if (this.currentUser.is_admin) {
            this.isAdmin = true;
        }
        if (this.currentUser.access_all_cases) {
            this.canAccessAllCases = true;
        }
        if (this.currentUser.direct_ip_login) {
            this.isDirectIpLogin = true;
        }
        if (this.currentUser.browser_extension_login) {
            this.isBrowserExtensionLogin = true;
        }
    },
    data() {
        return {
            isAdmin: false,
            canAccessAllCases: false,
            isBrowserExtensionLogin: false,
            isDirectIpLogin: false,
            casePermissionIDTobeDeleted: null,
            showCasePermissionDeleteModal: false,
            confirmPermissionDeletion: false,
            resourcePermissions: [],
            showUserRemoveModal: false,
            userPermissions: [],
            showUserPermissionModal: false,
            userPermissionToDelete: null,
            licenseList: [],
            isLincensesLoading: false,
            licenses: [],
        };
    },
    computed: {
        getLicenseList() {
            let allLicenses = this.licenseList;
            let licensesIds = this.licenses.map((ele) => ele.id);
            allLicenses = allLicenses.map((e) => {
                let json = {license_pack_name: e.license_pack_name, value: e.id, remaining: e.remaining};
                const checkId = licensesIds.includes(json.value);
                if (checkId) {
                    json.selected = checkId;
                    json.order = 100;
                } else {
                    json.selected = checkId;
                    json.order = 1000;
                }
                if (json.remaining === 0 && !checkId) {
                    json.isDisabled = true;
                }
                return json;
            });
            allLicenses = allLicenses.sort((a, b) => a.order - b.order);
            return allLicenses;
        },
    },
    methods: {
        async handleAdminChange(user) {
            const group_id = this.groups.find((el) => el.name === "admin");
            if (this.isAdmin === false) {
                try {
                    const resp = await axios.post(`api/v1/accounts/${this.$store.getters.getAccountId}/groups/${group_id.id}/users`, [
                        {
                            first_name: this.currentUser.first_name,
                            last_name: this.currentUser.last_name,
                            id: this.currentUser.id,
                            email: this.currentUser.email,
                            is_active: this.currentUser.is_active,
                        },
                    ]);
                    this.$toast.success(`${this.currentUser.first_name} is now Admin!`);
                    this.isAdmin = true;
                } catch (error) {
                    this.$toast.error(error?.response?.data?.detail);
                }
            } else {
                try {
                    let url = `api/v1/accounts/${this.$store.getters.getAccountId}/groups/${group_id.id}/users`;
                    const resp = await axios.delete(url, {
                        params: {
                            users: this.currentUser.id,
                        },
                    });
                    this.$toast.warning(`${this.currentUser.first_name} is not an admin now!`);
                    this.isAdmin = false;
                } catch (error) {
                    this.$toast.error(error?.response?.data?.detail);
                }
            }
        },
        async handleCaseAccess(user) {
            const group_id = this.groups.find((el) => el.name === "access_all_cases");
            if (this.canAccessAllCases === false) {
                try {
                    const resp = await axios.post(`api/v1/accounts/${this.$store.getters.getAccountId}/groups/${group_id.id}/users`, [
                        {
                            first_name: this.currentUser.first_name,
                            last_name: this.currentUser.last_name,
                            id: this.currentUser.id,
                            email: this.currentUser.email,
                            is_active: this.currentUser.is_active,
                        },
                    ]);
                    this.$toast.success(`${this.currentUser.first_name} can now access all cases!`);
                    this.canAccessAllCases = true;
                } catch (error) {
                    this.$toast.error(error?.response?.data?.detail);
                }
            } else {
                try {
                    let url = `api/v1/accounts/${this.$store.getters.getAccountId}/groups/${group_id.id}/users`;
                    const resp = await axios.delete(url, {
                        params: {
                            users: this.currentUser.id,
                        },
                    });
                    this.$toast.warning(`${this.currentUser.first_name} can't access all cases now!`);
                    this.canAccessAllCases = false;
                } catch (error) {
                    this.$toast.error(error?.response?.data?.detail);
                }
            }
        },
        async handleBrowserExtension(user) {
            const group_id = this.groups.find((el) => el.name === "browser_extension_login");
            if (this.isBrowserExtensionLogin === false) {
                try {
                    const resp = await axios.post(`api/v1/accounts/${this.$store.getters.getAccountId}/groups/${group_id.id}/users`, [
                        {
                            first_name: this.currentUser.first_name,
                            last_name: this.currentUser.last_name,
                            id: this.currentUser.id,
                            email: this.currentUser.email,
                            is_active: this.currentUser.is_active,
                        },
                    ]);
                    this.$toast.success(`${this.currentUser.first_name} can  login only through browser extension!`);
                    this.isBrowserExtensionLogin = true;
                } catch (error) {
                    this.$toast.error(error?.response?.data?.detail);
                }
            } else {
                try {
                    let url = `api/v1/accounts/${this.$store.getters.getAccountId}/groups/${group_id.id}/users`;
                    const resp = await axios.delete(url, {
                        params: {
                            users: this.currentUser.id,
                        },
                    });
                    this.$toast.warning(`${this.currentUser.first_name} cannot login through browser extension!`);
                    this.isBrowserExtensionLogin = false;
                } catch (error) {
                    this.$toast.error(error?.response?.data?.detail);
                }
            }
        },
        async handleDirectIpLogin(user) {
            const group_id = this.groups.find((el) => el.name === "direct_ip_login");
            if (this.isDirectIpLogin === false) {
                try {
                    const resp = await axios.post(`api/v1/accounts/${this.$store.getters.getAccountId}/groups/${group_id.id}/users`, [
                        {
                            first_name: this.currentUser.first_name,
                            last_name: this.currentUser.last_name,
                            id: this.currentUser.id,
                            email: this.currentUser.email,
                            is_active: this.currentUser.is_active,
                        },
                    ]);
                    this.$toast.success(`${this.currentUser.first_name} can login only through direct ip login!`);
                    this.isDirectIpLogin = true;
                } catch (error) {
                    this.$toast.error(error?.response?.data?.detail);
                }
            } else {
                try {
                    let url = `api/v1/accounts/${this.$store.getters.getAccountId}/groups/${group_id.id}/users`;
                    const resp = await axios.delete(url, {
                        params: {
                            users: this.currentUser.id,
                        },
                    });
                    this.$toast.warning(`${this.currentUser.first_name} cannot login through direct ip login!`);
                    this.isDirectIpLogin = false;
                } catch (error) {
                    this.$toast.error(error?.response?.data?.detail);
                }
            }
        },
        startCasePermissionDeletePrompt(permissionId) {
            this.showCasePermissionDeleteModal = true;
            this.casePermissionIDTobeDeleted = permissionId;
        },
        startUserPermissionDeletePrompt(permission) {
            this.showUserPermissionModal = true;
            this.userPermissionToDelete = permission;
        },

        async removeCasePermissionById() {
            // this.$modal.show("confirm_permission_delete")
            try {
                var permissionId = this.casePermissionIDTobeDeleted;
                await saasAxios.delete(`/permissions?permission_type=case&permission_id=${permissionId}`);
                this.$toast.success("Succefully removed permission");
            } catch (error) {
                this.$toast.error("Failed to remove permission");
            }
        },

        async removeUserPermission() {
            let url = `api/v1/accounts/${this.$store.getters.getAccountId}/user-permissions`;
            try {
                let obj = {
                    user_id: this.currentUser.id,
                    account_id: this.$store.getters.getAccountId,
                    resource: this.userPermissionToDelete.resource,
                    action: this.userPermissionToDelete.action,
                    id: this.userPermissionToDelete.id,
                };
                var response = await axios.delete(url, {data: obj});
                this.$toast.success("Succefully removed permission");
                this.showUserPermissionModal = false;
                // this.userPermissions = response.data;
                const index = this.userPermissions.findIndex((pr) => pr.id === this.userPermissionToDelete.id);
                this.userPermissions.splice(index, 1);
            } catch (error) {
                console.log(error, "error");
            }
        },

        formatCasePermissionName(caseName) {
            return caseName.split("#")[1].replace("--", " ");
        },

        async getResourcePermissions() {
            var url = `/permissions/user-case-resource-permission-mapping/${this.currentUser.id}`;
            try {
                var response = await saasAxios.get(url);
                this.resourcePermissions = response.data;
            } catch (error) {
                console.log(error, "error");
            }
        },
        async getUserPermissions() {
            let url = `api/v1/accounts/${this.$store.getters.getAccountId}/user-permissions/${this.currentUser.id}`;
            try {
                let resp = await axios.get(url);
                this.userPermissions = resp.data;
            } catch (err) {
                console.log(error, "error");
            }
        },
        async fetchLincenses() {
            const url = `/api/v1/accounts/${this.$store.getters.getAccountId}/licenses`;
            try {
                const {data} = await axios.get(url);
                if (data.length > 0) {
                    this.licenseList = data;
                }
            } catch (error) {
                console.log(error, "error");
            }
        },
        async fetchUserLincenses() {
            const url = `/api/v1/accounts/${this.$store.getters.getAccountId}/users/${this.currentUser.id}/license`;
            try {
                const {data} = await axios.get(url);
                if (data.length > 0) {
                    // this.selectedLicense = data.map((ele) => ({ label: ele.license_pack_name, value: ele.id }));
                    this.licenses = data;
                }
            } catch (error) {
                console.log(error);
            }
        },
        async deleteLicense(license) {
            try {
                const url = `/api/v1/accounts/${this.$store.getters.getAccountId}/users/${this.currentUser.id}/license`;
                if (license?.value) {
                    const {data} = await axios.delete(url, {
                        data: {
                            license_pack_id: license.value,
                        },
                    });
                    if (data.success == true) {
                        // this.licenseList[0].values.push(license);
                        await this.fetchLincenses();
                        this.$toast.warning("License Removed!");
                        // this.licenseList[0].values[index].$isDisabled = false;
                        // this.$toast.success("License Deleted!");
                    }
                }
            } catch (error) {
                this.$toast.error(error?.response?.data?.detail);
            }
        },
        async handleSaveLicense(license) {
            try {
                const url = `/api/v1/accounts/${this.$store.getters.getAccountId}/users/${this.currentUser.id}/license`;
                if (license?.value) {
                    const {data} = await axios.post(url, {
                        license_pack_ids: [license.value],
                    });
                    if (data[0].success) {
                        await this.fetchLincenses();
                        this.$toast.success("License Added!");
                    }
                }
            } catch (error) {
                console.log(error.response);
                this.$toast.error(error?.response?.data?.detail);
            }
        },
        async handleChange({target}, license) {
            if (target.checked) {
                await this.handleSaveLicense(license);
            } else {
                await this.deleteLicense(license);
            }
        },
        getUserFullName(user) {
            return user.first_name.charAt(0).toUpperCase() + user.first_name.slice(1) + " " + user.last_name.charAt(0).toUpperCase() + user.last_name.slice(1);
        },
    },
    watch: {
        addUserPermission(newVal, oldval) {
            if (newVal.length > 0) {
                this.userPermissions.push(...newVal);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
input[type="checkbox"]:checked + label span:first-of-type {
    background-color: #10b981;
    border-color: #10b981;
    color: #fff;
}

input[type="checkbox"]:checked + label span:nth-of-type(2) {
    font-weight: 500;
}
input[type="checkbox"]:disabled + label span:nth-of-type(2) {
    color: #9ca3af;
}
</style>
