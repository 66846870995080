import analysisCard from "../ToolsCard.vue";
import Button from "@/components/button";
import Input from "@/components/input";
import Table from "@/components/table";
import Loader from "@/components/loader";
import nLoader from "@/components/n-loader";
import Pagination from "@/components/pagination";
import VueMultiselect from "@/components/vue-multiselect";
// import "vue-multiselect/dist/vue-multiselect.min.css";
import "@/assets/css/multiselect.scss";
import axios from "../../../../axios/osint.js";
import ExpandFilter from "./../ExpandFilter.vue";
import modalCompanyDetails from "@/components/modal-company-details";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {getMappingValue} from "@/utils/functions";
import NeoAnalyse from "@/components/analyse";
import ModalConfirm from "@/components/modal-confirm";
import JsonCSV from "vue-json-csv";
import NeoCorporateRecordsSidePanel from "@/components/corporate-records-sidepanel";
import NeoCorporateRecordsMore from "@/components/corporate-records-more";
import toolsHelper from "@/mixins/toolsHelper";
import neoSwitch from "@/components/toggle-switch";
import NeoTagInput from "./neo-tag-input";
import NeoResultList from "@/components/result-list";
import NeoResultExpanded from "@/components/result-expanded";
import DatePicker from "vue2-datepicker";
import {DateTime} from "luxon";
import {cloneDeep, indexOf} from "lodash";
import {EventBus} from "@/main.js";

import Tab from "@/components/tab-comments";
import CourtForm from "./form";
import {COURT_TOOL_MAPPING} from "@/utils/constants";
const generateFiltersWrapperModel = (title, src_name, value, filters, tagClass, disabled) => ({
    title,
    src_name,
    value,
    filters,
    tagClass,
    isChecked: false,
    isModalVisible: false,
    disabled,
});

export default {
    name: "company-identifier",
    components: {
        "neo-input": Input,
        "neo-button": Button,
        "neo-table": Table,
        "neo-pagination": Pagination,
        "neo-loader": Loader,
        "analysis-card": analysisCard,
        "modal-confirm": ModalConfirm,
        "neo-tab": Tab,
        "neo-tag-input": NeoTagInput,
        "court-form": CourtForm,
        VueMultiselect,
        NeoAnalyse,
        "n-loader": nLoader,
        "download-csv": JsonCSV,
        "modal-company-details": modalCompanyDetails,
        NeoCorporateRecordsSidePanel,
        NeoCorporateRecordsMore,
        neoSwitch,
        ExpandFilter,
        NeoResultList,
        NeoResultExpanded,
        DatePicker,
    },
    mixins: [toolsHelper],
    props: {
        toolData: {
            type: Array,
            default: () => [],
        },
        tool: {
            type: String,
            default: "",
        },
        searchQueries: {
            type: Array,
            default: () => [],
        },
        isQueryPanelExpanded: {
            type: Boolean,
            default: false,
        },
        toolSearchQueries: {
            type: Array,
            default: () => [],
        },

        monitoring: {
            type: Boolean,
            default: false,
        },
        frequency: {
            default: "",
        },
    },
    data() {
        return {
            resultForString: "",
            pagination: {
                current_page: 1,
                judy_records_total_pages: 1,
                uni_court_records_total_pages: 1,
                total_records: 1,
                total_max_pages: 1,
            },
            case_id: "",
            sources: [],
            dropdownOption: {
                types: [
                    {name: "Everything", value: "all"},
                    {name: "Parties", value: "parties"},
                    {name: "Attorney", value: "attorney"},
                    {name: "Case number", value: "case_number"},
                    {name: "Case name", value: "case_name"},
                    {name: "Judge", value: "judge"},
                ],
                partyTypes: [
                    {name: "Any", value: "any"},
                    {name: "Defendant", value: "defendant"},
                    {name: "Petitioner", value: "petitioner"},
                    {name: "Plaintiff", value: "plaintiff"},
                    {name: "Respondent", value: "respondent"},
                ],
                representation: [
                    {name: "Any", value: "any"},
                    {name: "Attorney represented", value: "attorney represented"},
                    {name: "Not classified by court", value: "not classified by court"},
                    {name: "Not yet classified", value: "not yet classified"},
                    {name: "Self represented", value: "self represented"},
                    {name: "Unrepresented", value: "unrepresented"},
                ],
                scopes: [
                    {name: "Contains all", value: "contains all"},
                    {name: "Contains any", value: "contains any"},
                ],
                proximity: [
                    {name: "Next to each other", value: "Next to each other"},
                    {name: "Anywhere", value: "anywhere"},
                ],
                condition: [
                    {name: "OR", value: "any"},
                    {name: "AND", value: "any"},
                ],
                additionalScopes: [
                    {name: "Contains all", value: "contains all"},
                    {name: "Does not contains all", value: "does not contains all"},
                    {name: "Contains any", value: "contains any"},
                    {name: "Does not contains any", value: "does not contains any"},
                ],
            },
            selectedDropDownItem: {
                search_type: {name: "Everything", value: "all"},
                scope: {name: "Contains any", value: "contains any"},
                proximity: {name: "Anywhere", value: "anywhere"},
                party_types: {name: "Any", value: "any"},
                representation: {name: "Any", value: "any"},
            },
            allSources: {
                judyRecords: generateFiltersWrapperModel("Judy Records", "judy_records", "judyrecords", [], "tw-bg-blue-100"),
                uniCourt: generateFiltersWrapperModel("Unicourt", "unicourt", "uniCourt", [], "tw-bg-blue-100"),
            },
            showResult: false,
            tabName: "",
            request_id: "",
            triggerRoute: true,
            loaderController: {
                nameSearch: {
                    loading: false,
                    success: false,
                    filterLoading: false,
                    filterSuccess: false,
                },
            },
            judyRecordsResults: [],
            configError: false,
            moreRecordsExist: true,
            form: {
                parties: "",
                location: "",
                attorney: "",
                judge: "",
                caseName: "",
                caseNumber: "",
            },
            tabs: [
                {
                    key: "case-search",
                    name: "Case Search",
                    enabled: true,
                    readOnly: true,
                },
                {
                    key: "legal-data",
                    name: "Legal Data Analytics",
                    enabled: true,
                    readOnly: true,
                },
            ],
            active_tab: "case-search",
            tags: [],
            tagList: {
                parties: [],
                location: [],
                attorney: [],
                judge: [],
                case_name: [],
                case_number: [],
            },
            newTagList: [],
            tagListCount: 0,
            neoResultList: {
                isSavedLoading: {},
                timeline: [],
                isOldVersionSelected: false,
                monitoring: null,
                isLoading: false,
                isAllRunQueries: false,
                expandedLoading: false,
                isResultExpanded: false,
            },
            // isAllRunQueries: false,
            // isResultExpanded: false,
            showFiltersPanel: false,
            finalSearchList: [],
            finalSearchString: "",
            selectedResultCard: null,
            caseSearchList: [],
            filters: {
                toggles: {
                    jurisdiction: false,
                    filingDate: false,
                    nature: false,
                    caseNumber: false,
                    caseStatus: false,
                },
                filterFlag: false,
                options: {
                    caseNumber: [],
                    caseType: [],
                    jurisdiction: [],
                    caseStatus: [],
                },
                selected: {
                    caseNumber: [],
                    caseType: [],
                    dateRange: [],
                    jurisdiction: [],
                    caseStatus: [],
                },
            },
            cloneResults: [],
            openSortingPanel: false,
            order_by: {
                name: "Descending",
                value: "desc",
            },
            searchedClicked: false,
            currentQuery: -1,
            appliedFilterCount: 0,
        };
    },
    watch: {
        showResult() {
            this.$el.scroll(0, 0);
        },
        "$route.query.q"(to, from) {
            if (this.triggerRoute) {
                this.redirectToQuery();
                this.currentQuery = -1;
            }
        },
    },
    computed: {
        ...mapGetters(["getAllToolsData", "getPermissions", "checkPermissionsFetched", "getSelectedToolQuery"]),

        sortOptions() {
            return [
                {
                    name: "Ascending",
                    value: "asc",
                },
                {
                    name: "Descending",
                    value: "desc",
                },
            ];
        },

        /**
         * @property
         * @name isSearchBtnDisabled
         * @return {Boolean} - check if search button is disabled (if any source is not selected)
         */
        isSearchBtnDisabled() {
            if (this.isAnyTagExist(this.tagList)) {
                return false;
            }
            return true;
        },
        /**
         * @property
         * @name isAddConditionButtonVisible
         * @return {Boolean} - check if any tag exist (any input is exist) show add condition button
         */
        isAddConditionButtonVisible() {
            return this.isAnyTagExist(this.tagList);
        },
        /**
         * @property
         * @name allSourcesChecked
         * @return {Boolean} - toggle for select all or de-select all
         */
        allSourcesChecked() {
            let allSourcesChecked = true;
            for (let source in this.allSources) {
                if (!this.allSources[source].isChecked) {
                    allSourcesChecked = false;
                    break;
                }
            }
            return allSourcesChecked;
        },
        /**
         * To enable the apply filter button
         * @function
         * @name isEnabledApplyButton
         */
        isEnabledApplyButton() {
            for (const key in this.filters.selected) {
                if (this.filters.selected[key].length > 0) return false;
            }
            return true;
        },
        isRequiredField() {
            let requiredField = [];
            if (this.allSources["uniCourt"].isChecked && this.tagList["parties"].length > 0) {
                requiredField.push("location");
            }
            // if (this.allSources["judyRecords"].isChecked) {
            //     requiredField.push("parties");
            // }
            return requiredField;
        },
        formattedDate() {
            let formatDate = "";
            if (this.filters.selected.dateRange.length > 0) {
                let start = new Date(this.filters.selected.dateRange[0]).toISOString();
                start = DateTime.fromISO(start);
                start = start.toFormat("dd MMM yyyy");
                let end = new Date(this.filters.selected.dateRange[1]).toISOString();
                end = DateTime.fromISO(end);
                end = end.toFormat("dd MMM yyyy");
                formatDate = start + " ~ " + end;
                return formatDate;
            } else return formatDate;
        },
        formatSourceName() {
            const names = this.sources.map((src) => {
                let name = src.toLowerCase();
                return name[0].toUpperCase() + name.slice(1);
            });
            return names.join(", ");
        },
    },
    async mounted() {
        this.case_id = this.$route.params.id;
        this.tabName = this.$route.name;
        const dataRes = this.getToolsData(this.tabName, "court-records");
        if (dataRes) this.redirectToQuery(dataRes);
        // await this.getToolData();
        EventBus.$on("openShowMorePanel", async (data) => {
            await this.expandResult(data);
        });
    },
    // created() {
    //     EventBus.$on("openShowMorePanel", async (data) => {
    //         await this.expandResult(data);
    //     });
    // },
    beforeDestroy() {
        this.setToolsData(this.tabName, "court-records");
        EventBus.$off("openShowMorePanel");
    },
    methods: {
        ...mapMutations(["SET_ALL_TOOLS_DATA"]),
        ...mapActions(["getAllCaseNames", "setToolsFilterData", "getToolsStatus"]),

        getMappingValue,

        last_run_date() {
            if (this.getSelectedToolQuery?.query?.last_runtime) {
                return this.getFormattedTime(this.getSelectedToolQuery?.query?.last_runtime);
            } else {
                return false;
            }
        },

        handleOutside() {
            this.open = false;
        },

        resultListStyles() {
            // isResultExpanded && 'width:40% !important;' && isMonitoringTab ? 'max-height: calc(100% - 202px)' : 'max-height: calc(100% - 128px)'
            let styles = "";
            if (this.isQueryPanelExpanded && this.neoResultList.isResultExpanded) styles += "width:30% !important ;";
            else if (this.neoResultList.isResultExpanded && !this.isQueryPanelExpanded) styles += "width:50% !important;";
            return styles;
        },
        // isResultExpanded ? 'width:60% !important;' : $route.name == 'monitoring' ? ' max-height: calc(100% - 240px)!important; ' : ' max-height: calc(100% - 140px)!important;'
        getStyles() {
            let styles = " ";
            if (this.isQueryPanelExpanded && this.neoResultList.isResultExpanded) styles += "width:70% !important ;";
            else if (this.neoResultList.isResultExpanded && !this.isQueryPanelExpanded) styles += "width:50% !important;";
            // if (this.$route.name == "monitoring") styles += " max-height: 100% - 100px)!important; ";
            // else styles += " max-height: 100% !important; ";
            return styles;
        },

        getFormattedTime(ts) {
            if (ts) {
                let date = new Date(ts).toISOString();
                date = DateTime.fromISO(date);
                return date.toFormat("dd MMM yyyy");
            }
            return "-";
        },

        searchQueryExists() {
            let flag = true;
            if (this.toolSearchQueries.findIndex((el) => el._id === this.getSelectedToolQuery?.query_id) < 0) flag = false;
            return flag;
        },

        async handlePageChange(page_number) {
            console.log("page", page_number);
            this.pagination.current_page = page_number;
            this.closeExpandedResult();
            this.scrollToTop();
            this.manualSearch();
            this.closeFilterPanel();
            // if (this.showFiltersPanel) {
            //     const keys = ["caseNumber", "caseType", "caseStatus", "jurisdiction"];
            //     keys.forEach((key) => {
            //         this.filters.options[key] = this.judyRecordsResults.map((el) => el[key]).filter(this.onlyUnique);
            //     });
            // }
        },

        /**
         * To Check if either uni court or judy record selected or not
         * @function
         * @name isAnyFilterChecked
         */
        isAnyFilterChecked() {
            let isCheckedFilterExists = false;
            for (const filter in this.allSources) {
                if (this.allSources[filter].isChecked) {
                    isCheckedFilterExists = true;
                    break;
                }
            }
            return isCheckedFilterExists;
        },
        /**
         * To Check if any tag(input) is exist or not for initial search
         * @function
         * @name isAnyTagExist
         */
        isAnyTagExist(tagList) {
            let tagExist = false;
            for (const key in tagList) {
                if (tagList[key] && tagList[key].length > 0) {
                    tagExist = true;
                    break;
                }
            }
            return tagExist;
        },
        /**
         * To Check if any additional tag(input) is exist or not for search
         * @function
         * @name isAnyAdditionalTagExist
         */
        isAnyAdditionalTagExist() {
            let additionalTagExist = false;
            if (this.newTagList?.length > 0) {
                this.newTagList.forEach((item) => {
                    if (this.isAnyTagExist(item)) {
                        additionalTagExist = true;
                        return;
                    }
                });
            }
            return additionalTagExist;
        },

        /**
         * To start Loader
         * @function
         * @name startLoader
         * @params {string} - section of loader
         */
        startLoader(section) {
            this.loaderController[section].success = false;
            this.loaderController[section].loading = true;
            this.isLoading = true;
        },
        /**
         * To success Loader
         * @function
         * @name startLoader
         * @params {string} - section of loader
         */
        successLoader(section) {
            this.loaderController[section].success = true;
            this.loaderController[section].loading = false;
        },
        /**
         * To reset Loader
         * @function
         * @name startLoader
         * @params {string} - section of loader
         */
        resetLoader(section) {
            this.loaderController[section].success = false;
            this.loaderController[section].loading = false;
            this.isLoading = false;
        },

        /**
         * To go to back to search
         * @function
         * @name onBackToSearch

         */
        onBackToSearch() {
            this.showResult = false;
            this.query_id = "";
            this.request_id = "";
            this.searchQuery = "";

            this.pagination = {
                current_page: 1,
                judy_records_total_pages: 1,
                uni_court_records_total_pages: 1,
                total_records: 1,
                total_max_pages: 1,
            };

            if (this.showFiltersPanel) {
                this.closeFilterPanel();
            }
        },

        handleSearch() {
            let finalSearchString = "";
            for (const key in this.tagList) {
                if (this.tagList[key].length > 0) {
                    finalSearchString = finalSearchString + " " + this.tagList[key].join(" ");
                }
            }
            this.finalSearchString = finalSearchString.trim();

            if (!this.isSearchBtnDisabled) {
                this.manualSearch();
            }
        },
        manualSearch(refreshFlag) {
            this.triggerRoute = false;
            this.search(refreshFlag);
        },

        closeExpandedResult() {
            this.isResultExpanded = false;
            this.selectedResultCard = null;
            this.isOldVersionSelected = false;
        },
        /**
         * @function
         * @name search
         * function calls to perform search on the input filters
         * calls sources API to create a search query
         * calls search APIs on the selected sources
         */
        async search(refreshFlag, isSearchWithSearchQuery = false) {
            this.closeFilterPanel();
            this.closeExpandedResult();
            this.configError = false;
            this.judyRecordsResults = [];
            this.cloneResults = [];
            this.currentQuery = -1;

            this.moreRecordsExist = true;
            this.neoResultList.isResultExpanded = false;

            this.makeResultForString();

            if (!this.sources.length) {
                this.$toast.error("Please Select a Source.");
                return;
            }

            // if (this.allSources["judyRecords"].isChecked && !this.tagList["parties"].length) {
            //     this.$toast.error(`Parties is required.`);
            //     return;
            // }

            if (this.allSources["uniCourt"].isChecked && this.tagList["parties"].length > 0 && !this.tagList["location"].length) {
                this.$toast.error(`Location is required.`);
                return;
            }

            if (!this.isAnyTagExist(this.tagList)) {
                this.$toast.error(`input field is required.`);
                return;
            }
            if (!this.isSourceAuth("judy_records")) {
                this.allSources["judyRecords"].isChecked = false;
                this.sources = this.sources.filter((e) => e !== "judyRecords");
            }

            if (!this.isSourceAuth("unicourt")) {
                this.allSources["uniCourt"].isChecked = false;
                this.sources = this.sources.filter((e) => e !== "uniCourt");
            }

            if (this.allSources["uniCourt"].isChecked && !this.checkMandatory()) {
                this.$toast.error(`${this.selectedDropDownItem.search_type.name} field is required.`);
                return;
            }

            this.sources = this.sources.sort((a, b) => a.localeCompare(b));
            this.searchedClicked = true;

            let uniCourts, judyRecords;
            if (this.allSources["judyRecords"].isChecked || this.allSources["uniCourt"].isChecked) {
                this.showResult = true;
                /**
                 * make data for case search request body
                 * only make when its call is not coming from search panel search query click
                 * when click on search query caseSearch list data already passed
                 */
                if (!isSearchWithSearchQuery) {
                    let additionalQueryData = [];
                    // check initial tag exist or not
                    const initialTagExist = this.isAnyTagExist(this.tagList);
                    // Make query data for initial tag
                    const initialQueryData = initialTagExist ? this.makeDataForPayload(this.tagList, "initial") : {};
                    // check initial tag exist or not if yes  Make query data for additional tag
                    if (this.allSources["uniCourt"].isChecked) {
                        additionalQueryData = this.isAnyAdditionalTagExist() ? this.makeDataForPayload(this.newTagList, "additional") : [];
                    }

                    if (additionalQueryData && additionalQueryData.length > 0) {
                        additionalQueryData.unshift(initialQueryData);
                        this.caseSearchList = [...additionalQueryData];
                    } else if (initialTagExist) {
                        this.caseSearchList = [initialQueryData];
                    }
                }

                // Header for api call
                const headers = {
                    "x-tool-name": "court-records-tool",
                    "x-visited": true,
                    "x-refresh": refreshFlag || false,
                };
                // Request body for api call
                let requestBody = {
                    case_search_list: this.caseSearchList,
                    order: this.order_by.value,
                    sort: "filedDate",
                    sources: this.sources.map((ele) => ele.toLowerCase()).sort((a, b) => a.localeCompare(b)),
                };

                /**
                 * APi Call for getting the search query id
                 */
                try {
                    this.startLoader("nameSearch");
                    this.neoResultList.isLoading = true;
                    const {data: response} = await axios.post("/court/search_sources", requestBody, {headers});

                    if (response?.request_id) {
                        this.request_id = response?.request_id;
                        this.redirectTo();
                        requestBody.query_id = response?.request_id;
                        requestBody.page_number = this.pagination.current_page;
                        requestBody.monitoring_status = false;
                        requestBody.saved = false;

                        if (this.allSources["judyRecords"].isChecked) {
                            judyRecords = await this.fetchJudyRecords(requestBody, headers);
                        }
                        if (this.allSources["uniCourt"].isChecked) {
                            uniCourts = await this.fetchUniCourtRecords(requestBody, headers);
                        }
                        this.resetLoader("nameSearch");
                        this.neoResultList.isLoading = false;
                    }
                } catch (err) {
                    console.error(err);
                    this.resetLoader("nameSearch");
                    this.neoResultList.isLoading = false;
                }
            }

            if (uniCourts || judyRecords) {
                Promise.allSettled([uniCourts, judyRecords])
                    .then(async (results) => {
                        let values = results.map((result) => result.value);
                        // Uni court
                        if (values && values[0]?.length) {
                            this.judyRecordsResults = [...this.judyRecordsResults, ...values[0]];
                        }
                        // Judy records
                        if (values && values[1]?.length) {
                            this.judyRecordsResults = [...this.judyRecordsResults, ...values[1]];
                        }
                        if (this.judyRecordsResults.length > 0) {
                            this.cloneResults = [];
                            this.resetLoader("nameSearch");
                            this.neoResultList.isLoading = false;
                            this.cloneResults = cloneDeep(this.judyRecordsResults);
                        } else if (!this.configError) {
                            this.$toast.error("No data found.");
                            this.resetLoader("nameSearch");
                            this.neoResultList.isLoading = false;
                        }
                        if (results[0]?.status === "rejected" || values[0]?.status === 500) {
                            this.showResult = false;
                            this.resetLoader("nameSearch");
                            this.neoResultList.isLoading = false;
                            this.$toast.error(`Unicourt: Something went wrong. Please try again.`);
                        }
                        if (results[1]?.status === "rejected" || values[1]?.status === 500) {
                            this.showResult = false;
                            this.resetLoader("nameSearch");
                            this.neoResultList.isLoading = false;
                            this.$toast.error(`Judy Records: Something went wrong. Please try again.`);
                        }
                    })
                    .catch((err) => {
                        console.error(err);
                        this.$toast.error(`Something went wrong. Please try again.`);
                        this.showResult = false;
                        this.resetLoader("nameSearch");
                        this.neoResultList.isLoading = false;
                    });
            }
            // refresh the search query panel
            this.$emit("refreshQueries");
            this.SET_ALL_TOOLS_DATA({...this._data, "tool-name": "court-records"});
        },
        /**
         * @function
         * @name getTotalPages
         * To get total maximum pages for pagination
         */
        getTotalPages() {
            const maxPages = 200;
            this.pagination.total_max_pages = this.pagination.judy_records_total_pages > this.pagination.uni_court_records_total_pages ? this.pagination.judy_records_total_pages : this.pagination.uni_court_records_total_pages;
            if (this.pagination.total_max_pages > maxPages) {
                this.pagination.total_max_pages = maxPages;
            }
        },
        /**
         * @function
         * @name getFinalResult
         * To get final search string for judy record request body
         */
        getFinalResult(list) {
            let finalSearchList = [];
            for (const key in list) {
                if (list[key].length > 0) {
                    finalSearchList.push(list[key]);
                }
            }
            return finalSearchList.flat();
            // this.finalSearchList = this.finalSearchList.flat();
        },
        /**
         * @function
         * @name makeResultForString
         * To get final search string for judy record request body
         */
        makeResultForString() {
            let resultFor = [];
            let additionalTagList = [];
            let initialTagList = [];
            if (this.tagList) {
                initialTagList = this.getFinalResult(this.tagList);
            }
            if (this.newTagList.length > 0) {
                this.newTagList.forEach((item) => {
                    const list = this.getFinalResult(item);
                    additionalTagList = [...additionalTagList, ...list];
                });
            }
            resultFor = [...initialTagList, ...additionalTagList];
            this.resultForString = resultFor.join(", ");
        },
        /**
         * @function
         * @name fetchJudyRecords
         * @returns [Array] - judy record result
         * To fetch judy records result
         */
        async fetchJudyRecords(headers) {
            let judyResult = [];
            try {
                let body = {
                    search_query: this.finalSearchString,
                    page_no: this.pagination.current_page,
                    request_id: this.request_id,
                };
                const {data: response} = await axios.post("/scraping/judyrecords", body, {headers});
                this.pagination.judy_records_total_pages = response?.no_of_pages;
                this.pagination.total_records = response?.no_of_results;
                this.getTotalPages();
                const res = response?.result;
                if (res && res.length > 0) {
                    const mappingValue = this.getMappingValue(res, "judyRecords", COURT_TOOL_MAPPING);
                    judyResult = mappingValue;
                } else if (res && res.length === 0 && res.status_code === 2004) {
                    this.configError = true;
                    this.$toast.error(res?.message || "Something went wrong.");
                }
            } catch (err) {
                console.error(err);
                this.$toast.error("Something went wrong.");
                this.resetLoader("nameSearch");
                this.neoResultList.isLoading = false;
            }
            return judyResult;
        },
        /**
         * @function
         * @name fetchUniCourtRecords
         * @params {object} - requestBody, {object} - headers
         * @returns [Array] - uni court result
         * To fetch uni court result
         */
        async fetchUniCourtRecords(requestBody, headers) {
            let uniCourtResult = [];
            try {
                const {data} = await axios.post("/court/case_search", requestBody, {headers});
                this.pagination.uni_court_records_total_pages = data?.total_pages;
                this.getTotalPages();
                const result = data?.case_search_result_array;
                if (result) {
                    const mappingValue = this.getMappingValue(result, "unicourt", COURT_TOOL_MAPPING);
                    uniCourtResult = mappingValue;
                } else if (data && data.status_code === 2004) {
                    this.configError = true;
                    this.$toast.error(data?.message || "Something went wrong.");
                }
            } catch (err) {
                this.resetLoader("nameSearch");
                console.error(err);
                this.neoResultList.isLoading = false;
            }
            this.resetLoader("nameSearch");
            this.neoResultList.isLoading = false;
            return uniCourtResult;
        },

        scrollToTop() {
            const resultList = document.querySelector("section#result-list");
            if (resultList) {
                resultList.scrollTo({
                    top: 1,
                    behavior: "smooth",
                });
                // resultList.scrollTop = 0;
            }
        },

        // async getSessionDetails() {
        //     this.sessionDetails = {};
        //     const response = await axios.get("/intel/session-data?conn=keep-alive");
        //     let session_id = response.data.forEach((el) => {if(el.query_id == this.request_id) this.sessionDetails = el.session_id })
        // },
        /**
         * @function
         * @name redirectToQuery
         * @params {object} - dataRes - search filters data from the route to be autofill in the input fiels and filters
         */

        redirectToQuery(dataRes) {
            if (this.$route?.query?.query_id) {
                const {courtFormRef} = this.$refs;
                let {query_id} = this.$route.query;
                let selected_query = this.toolSearchQueries?.find((el) => el._id === query_id);
                // clear all the initial input data
                for (const key in this.tagList) {
                    this.tagList[key] = [];
                }

                // clear all the additional input data
                if (this.newTagList) {
                    this.newTagList.forEach((item) => {
                        for (const key in item) {
                            item[key] = [];
                        }
                    });
                }

                this.sources = [];

                if (selected_query?.query?.case_search_list.length > 0) {
                    const initialInputsTags = selected_query?.query?.case_search_list[0];
                    const dropDownItems = ["condition_operator", "party_types", "proximity", "representation", "scope", "search_type"];
                    // for initial inputs
                    for (const input in initialInputsTags) {
                        if (!dropDownItems.includes(input)) {
                            let str = "";
                            let value = initialInputsTags[input];
                            if (typeof value === "string") {
                                str = value
                                    .replaceAll(" AND ", "->")
                                    .replaceAll(" OR ", "->")
                                    .replaceAll(" NOT ", "->");
                            }
                            if (Array.isArray(value)) {
                                str = value[0];
                            }
                            this.tagList[input] = str.split("->");
                            if (input === "attorney" || input === "judge" || input === "case_name" || input === "case_number") {
                                // get child component
                                // const { courtFormRef } = this.$refs;
                                if (courtFormRef) {
                                    courtFormRef.toggle.moreSearchOptions = true;
                                }
                                // courtFormRef.toggle.moreSearchOptions = true;
                            }
                        } else if (input !== "condition_operator" && selected_query?.query?.sources.includes("unicourt")) {
                            switch (input) {
                                case "search_type": {
                                    let displayName = "";
                                    if (initialInputsTags[input] === "case_number") {
                                        displayName = "Case number";
                                    } else if (initialInputsTags[input] === "case_name") {
                                        displayName = "Case name";
                                    } else if (initialInputsTags[input] === "all") {
                                        displayName = "Everything";
                                    } else {
                                        displayName = this.getDisplayDropDownName(initialInputsTags[input]);
                                    }
                                    this.selectedDropDownItem[input] = {name: displayName, value: initialInputsTags[input]};
                                    break;
                                }
                                case "scope": {
                                    let displayName = this.getDisplayDropDownName(initialInputsTags[input]);
                                    this.selectedDropDownItem[input] = {name: displayName, value: initialInputsTags[input]};
                                    break;
                                }
                                case "proximity": {
                                    let displayName = this.getDisplayDropDownName(initialInputsTags[input]);
                                    this.selectedDropDownItem[input] = {name: displayName, value: initialInputsTags[input]};
                                    break;
                                }
                                case "party_types": {
                                    let displayName = this.getDisplayDropDownName(initialInputsTags[input]);
                                    this.selectedDropDownItem[input] = {name: displayName, value: initialInputsTags[input]};
                                    break;
                                }
                                case "representation": {
                                    let displayName = this.getDisplayDropDownName(initialInputsTags[input]);
                                    this.selectedDropDownItem[input] = {name: displayName, value: initialInputsTags[input]};
                                    break;
                                }
                            }
                        }
                    }
                    // for additional inputs
                    if (selected_query?.query?.length > 1) {
                        selected_query?.query?.case_search_list.forEach((el, index) => {
                            if (index > 0 && index < selected_query?.query?.case_search_list.length) {
                                let obj = {};
                                for (const input in el) {
                                    if (!dropDownItems.includes(input)) {
                                        let str = "";
                                        let value = selected_query?.query?.case_search_list[index][input];
                                        if (typeof value === "string") {
                                            str = value
                                                .replaceAll(" AND ", "->")
                                                .replaceAll(" OR ", "->")
                                                .replaceAll(" NOT ", "->");
                                        }
                                        if (Array.isArray(value)) {
                                            str = value[0];
                                        }
                                        obj[input] = str.split("->");
                                    } else if (selected_query?.query?.sources.includes("unicourt")) {
                                        switch (input) {
                                            case "search_type": {
                                                let displayName = "";
                                                if (el[input] === "case_number") {
                                                    displayName = "Case number";
                                                } else if (el[input] === "case_name") {
                                                    displayName = "Case name";
                                                } else if (el[input] === "all") {
                                                    displayName = "Everything";
                                                } else {
                                                    displayName = this.getDisplayDropDownName(el[input]);
                                                }
                                                obj[input] = {name: displayName, value: el[input]};
                                                break;
                                            }
                                            case "scope": {
                                                let displayName = this.getDisplayDropDownName(el[input]);
                                                obj[input] = {name: displayName, value: el[input]};
                                                break;
                                            }
                                            case "proximity": {
                                                let displayName = this.getDisplayDropDownName(el[input]);
                                                obj[input] = {name: displayName, value: el[input]};
                                                break;
                                            }
                                            case "party_types": {
                                                let displayName = this.getDisplayDropDownName(el[input]);
                                                obj[input] = {name: displayName, value: el[input]};
                                                break;
                                            }
                                            case "representation": {
                                                let displayName = this.getDisplayDropDownName(el[input]);
                                                obj[input] = {name: displayName, value: el[input]};
                                                break;
                                            }
                                            case "condition_operator": {
                                                obj[input] = {name: el[input]};
                                                break;
                                            }
                                        }
                                    }
                                }
                                let selectedIndex = index - 1;
                                this.newTagList[selectedIndex] = obj;
                            }
                        });
                    }
                    if (Array.isArray(selected_query?.query?.sources) && selected_query?.query?.sources.includes("unicourt")) {
                        // tools.handleSourceExpand("uniCourt", "uniCourt", true);
                        this.allSources["uniCourt"].isChecked = true;
                        this.sources.push("uniCourt");
                        // for api payload
                        this.caseSearchList = selected_query?.query?.case_search_list;
                    }
                    if (Array.isArray(selected_query?.query?.sources) && selected_query?.query?.sources.includes("judyrecords")) {
                        this.allSources["judyRecords"].isChecked = true;
                        this.sources.push("judyRecords");
                        // tools.handleSourceExpand("judyRecords", "judyRecords", true);
                        // for api payload
                        // this.finalSearchString = selected_query?.query?.judySearchString || "";
                        let finalSearchString = "";
                        for (const key in this.tagList) {
                            if (this.tagList[key].length > 0) {
                                finalSearchString = finalSearchString + " " + this.tagList[key].join(" ");
                            }
                        }
                        this.finalSearchString = finalSearchString.trim();
                    }
                    if (!dataRes) {
                        this.search();
                    }
                } else if (this.searchedClicked) {
                    if (!dataRes) this.search();
                    else this.redirectTo();
                }
            } else {
                this.redirectTo();
            }
        },

        /**
         * @function
         * @name redirectTo - function to update input filters data to the route
         */
        redirectTo() {
            let query = {};
            if (this.request_id)
                query = {
                    query_id: this.request_id,
                };
            this.$router.push({path: this.$route.path, query: query}).catch(() => {});
        },
        resetInputData() {
            this.configError = false;
            this.request_id = "";
            this.request_id = null;
            this.judyRecordsResults = [];
            this.cloneResults = [];
            this.moreRecordsExist = true;

            for (const key in this.tagList) {
                this.tagList[key] = [];
            }

            if (this.newTagList) {
                this.newTagList.forEach((item) => {
                    for (const key in item) {
                        item[key] = [];
                    }
                });
            }
            this.sources = [];
            this.allSources["uniCourt"].isChecked = false;
            this.allSources["judyRecords"].isChecked = false;
            this.resetDropDownValue();
            this.redirectTo();
            this.$forceUpdate();
            this.newTagList = [];
        },
        // getClipboardData(e) {
        //     e.stopPropagation();
        //     e.preventDefault();
        //     e.cancelBubble = true;
        //     const clipboardData = e.clipboardData?.getData("Text");
        //     let newclipboardData = clipboardData.replace(/,/g, "\n");
        //     this.addTag(newclipboardData);
        // },
        // addTag(newTag) {
        //     let newTags = [];
        //     newTags = [
        //         ...newTags,
        //         ...newTag
        //             ?.split(/\t/)[0]
        //             ?.split("\n")
        //             ?.filter((el) => el && (el !== " " || el !== "")),
        //     ];

        //     let newTagsData = newTags.filter(function(item) {
        //         return item.trim() != "";
        //     });
        //     newTagsData.forEach((tg) => {
        //         if (tg !== "" && tg !== " ") {
        //             tg = tg?.trim();
        //             const tag = {
        //                 name: tg,
        //                 value: tg?.trim(),
        //                 code: tg.substring(0, 2) + Math.floor(Math.random() * 10000000),
        //             };
        //             let index = this.selectedItem?.findIndex((item) => item?.value === tg);
        //             let secIndex = this.nameSearches[0]?.categories.findIndex((item) => item?.value === tg);
        //             if (index > -1) {
        //             } else {
        //                 this.selectedItem.push(tag);
        //             }
        //             if (secIndex > -1) {
        //             } else {
        //                 this.nameSearches[0]?.categories.push(tag);
        //             }
        //             this.searchKeyword = this.selectedItem[0].value ?? "";
        //         }
        //     });
        // },
        /**
         * @function
         * @name isSourceAuth
         * @params {String} src - selected source (judy_records)
         */

        isSourceAuth(src) {
            // const permission = ["judy_records__court_records", "uni_court__court_records"];
            // if (src) return permission.includes(`${src}__court_records`);
            if (src) return this.getPermissions.includes(`${src}__court_records`);
            else return true;
        },

        /**
         * @function
         * @name toggleAllSources - toggle selection of all sources
         */
        toggleAllSources() {
            if (!this.allSourcesChecked) {
                for (let source in this.allSources) this.allSources[source].isChecked = true;
                this.sources = ["judyRecords", "uniCourt"];
            } else {
                for (let source in this.allSources) this.allSources[source].isChecked = false;
                this.sources = [];
            }
        },

        /**
         * @function
         * @name handleSourceChange
         * @params {e} - pointer event for source input checkbox (Complyadvantage / worldcheck / wl / soe / ame / offshoreleaks)
         */
        handleSourceChange(e) {
            if (this.sources.includes(e.target.value)) {
                const filterSource = this.sources.filter((el) => el !== e.target.value);
                this.sources = filterSource;
            } else {
                this.sources.push(e.target.value);
            }

            for (let source in this.allSources) {
                if (this.allSources[source].value === e.target.value) {
                    this.allSources[source].isChecked = !this.allSources[source].isChecked;
                    break;
                }
            }
        },
        /**
         * @function
         * @name handleSourceExpand
         * @params {source, value} - source & value (Complyadvantage / worldcheck / wl / soe / ame / offshoreleaks)
         */
        handleSourceExpand(source, value, toggle) {
            if (toggle) {
                this.allSources[source].isChecked = !this.allSources[source].isChecked;
            }
            if (this.sources.includes(value)) {
                const filterSource = this.sources.filter((el) => el !== value);
                this.sources = filterSource;
            } else {
                this.sources.push(value);
            }
        },

        onTopTabChangeComment(tab) {
            this.active_tab = tab;
            if (tab === "legal-data") {
                this.newTagList = [];
            }
            this.resetDropDownValue();
        },
        /**
         * Add tag into tag list on click enter in input box
         * @function
         * @name addTagIntoTagList
         */
        addTagIntoTagList(event, tagListType, tagList) {
            // const type = tagListType;
            // event.preventDefault();
            // let val = event.target.value.trim();
            // if (val.length > 0) {
            //     if (this.tagList[type].length >= 1) {
            //         for (let i = 0; i < this.tagList[type].length; i++) {
            //             if (this.tagList[type][i] === val) {
            //                 return false;
            //             }
            //         }
            //     }
            //     this.tagList[type].push(val);
            //     event.target.value = "";
            // }
            const type = tagListType;
            event.preventDefault();
            let val = event.target.value.trim();
            if (val.length > 0) {
                if (tagList[type].length >= 1) {
                    for (let i = 0; i < tagList[type].length; i++) {
                        if (tagList[type][i] === val) {
                            return false;
                        }
                    }
                }
                tagList[type].push(val);
                event.target.value = "";
            }
        },
        /**
         * remove tag from tag list on click on X icon from tag in input box
         * @function
         * @name removeTagFromTagList
         */
        removeTagFromTagList(index, tagListType, tagList) {
            const selectedIndex = index;
            if ((selectedIndex || selectedIndex === 0) && tagListType) {
                tagList[tagListType].splice(selectedIndex, 1);
            } else {
                return;
            }
        },
        /**
         * remove last tag from tag list on backspace
         * @function
         * @name removeTagFromTagList
         */
        removeLastTag(event, tagListType, tagList) {
            if (tagListType && tagList && (event.key === "Backspace" || event.key === "Delete")) {
                this.removeTagFromTagList(tagList[tagListType].length - 1, tagListType, tagList);
            }
        },
        /**
         * add new row of additional inputs
         * @function
         * @name addNewEntity
         */
        addNewEntity() {
            this.tagListCount += 1;
            if (this.tagListCount <= 10) {
                this.newTagList.push({
                    parties: [],
                    location: [],
                    attorney: [],
                    judge: [],
                    case_name: [],
                    case_number: [],
                    search_type: {name: "Everything", value: "all"},
                    scope: {name: "Contains any", value: "contains any"},
                    proximity: {name: "Anywhere", value: "anywhere"},
                    party_types: {name: "Any", value: "any"},
                    representation: {name: "Any", value: "any"},
                    condition_operator: {name: "OR"},
                });
            } else {
                this.$toast.error("Unicourt allows maximum 10 conditions in the search");
            }
        },
        /**
         * Remove clicked row of additional inputs
         * @function
         * @name addNewEntity
         */
        removeEntity(index) {
            this.tagListCount -= 1;
            this.newTagList.splice(index, 1);
        },
        /**
         * expand the card to see the detail on click on card
         * @function
         * @name expandResult
         */
        async expandResult(data) {
            const result = data?.result;
            const index = data?.cardIndex;
            // this.judyRecordsResults.curr_index = index;
            // const isOldVersion = result.isOldVersion;
            this.isExpandedLoaded = true;
            if (result.key === "judyRecords") {
                this.expandedResultCard = result;
            }
            if (result.key === "unicourt") {
                const headers = {
                    "x-tool-name": "court-records-tool",
                    "x-visited": true,
                    "x-refresh": false,
                };
                let body = {
                    case_id: result.api_all_data?.case_id,
                    query_id: this.request_id,
                };
                const partyInfoResponse = await this.fetchPartyInfo(headers, body);
                const docketInfoResponse = await this.fetchDocketInfo(headers, body);
                const caseAttorneyResponse = await this.fetchAttorneyInfo(headers, body);
                const judgeInfoResponse = await this.fetchCaseJudgeInfo(headers, body);

                result.api_all_data.party_info = partyInfoResponse || [];
                result.api_all_data.docket_info = docketInfoResponse || [];
                result.api_all_data.judge_info = judgeInfoResponse || [];
                result.api_all_data.case_attorney_info = caseAttorneyResponse || [];

                this.expandedResultCard = result;
            }
            let detailsExpanded = true;
            if (result.detailsExpanded === false) {
                detailsExpanded = false;
            }
            if (detailsExpanded) {
                this.selectedResultCard = index;
                this.expandedResultCard = result;
                this.neoResultList.isResultExpanded = true;
                this.isComparisonModeOn = false;
                // this.$emit("searchResult", { data: this.adverseResults, source: "adverse-media", searchType: "name" });
                if (result.expanded == undefined) result.expanded = true;
                else {
                    result.expanded = !result.expanded;
                }
                this.$forceUpdate();
                const resultContainer = document.getElementById(`result-expanded`);
                if (resultContainer) {
                    resultContainer.scrollTo({
                        top: 1,
                        behavior: "smooth",
                    });
                }
                this.isExpandedLoaded = false;
            }
        },
        /**
         * fetch the party info for detail card only for unicourt
         * @function
         * @name fetchPartyInfo
         */
        async fetchPartyInfo(headers, body) {
            try {
                const res = await axios.post("court/case_parties", body, {headers});
                return res?.data?.party_array;
            } catch (err) {
                console.error("err", err);
            }
        },
        /**
         * fetch the docket info for detail card only for unicourt
         * @function
         * @name fetchDocketInfo
         */
        async fetchDocketInfo(headers, body) {
            try {
                const res = await axios.post("court/case_docket_entries", body, {headers});
                return res?.data?.docket_entry_array;
            } catch (err) {
                console.error("err", err);
            }
        },
        /**
         * fetch the attorney info for detail card only for unicourt
         * @function
         * @name fetchAttorneyInfo
         */
        async fetchAttorneyInfo(headers, body) {
            try {
                const res = await axios.post("court/case_attroney", body, {headers});
                return res?.data?.attorney_array;
            } catch (err) {
                console.error("err", err);
            }
        },
        /**
         * fetch the Judge info for detail card only for unicourt
         * @function
         * @name fetchCaseJudgeInfo
         */
        async fetchCaseJudgeInfo(headers, body) {
            try {
                const res = await axios.post("court/case_judges", body, {headers});
                return res?.data?.judge_array;
            } catch (err) {
                console.error("err", err);
            }
        },
        /**
         * on close expanded card
         * @function
         * @name closeExpandedResult
         */
        closeExpandedResult() {
            this.neoResultList.isResultExpanded = false;
            this.selectedResultCard = null;
            this.isOldVersionSelected = false;
        },
        /**
         * make data for payload
         * @function
         * @name makeDataForPayload
         */
        makeDataForPayload(tagList, type) {
            let payload = {};
            let additionalTagList = [];
            switch (type) {
                case "initial": {
                    const useKeys = ["condition_operator", "party_types", "proximity", "representation", "scope", "search_type"];
                    for (const key in tagList) {
                        if (tagList[key].length > 0) {
                            let joinWith = " ";
                            if (this.selectedDropDownItem.scope.name === "Contains all") {
                                joinWith = " AND ";
                            } else if (this.selectedDropDownItem.scope.name === "Contains any") {
                                joinWith = " OR ";
                            }
                            if (!useKeys.includes(key)) {
                                // let payloadKey = key === "caseNumber" ? "case_number" : key;
                                payload[key] = tagList[key].join(joinWith);
                            }
                        }
                    }
                    // for (const key in this.selectedDropDownItem) {
                    //     if (key === "search_type") {
                    //         payload[key] = this.selectedDropDownItem[key]?.name?.toLowerCase() === "everything" ? "all" : this.selectedDropDownItem[key]?.name?.toLowerCase();
                    //     }
                    //     if (key === "proximity") {
                    //         payload[key] = this.selectedDropDownItem[key]?.name === "Next to each other" ? this.selectedDropDownItem[key]?.name : this.selectedDropDownItem[key]?.name.toLowerCase();
                    //     }
                    //     payload[key] = this.selectedDropDownItem[key]?.name?.toLowerCase();
                    // }
                    payload["scope"] = this.selectedDropDownItem?.scope?.value;
                    payload["search_type"] = this.selectedDropDownItem?.search_type?.value;
                    payload["proximity"] = this.selectedDropDownItem?.proximity?.value;
                    payload["party_types"] = this.selectedDropDownItem?.party_types?.value;
                    payload["representation"] = this.selectedDropDownItem?.representation?.value;
                    payload["condition_operator"] = "AND";
                    // payload["condition_operator"] = "AND";
                    return payload;
                    // break;
                }
                case "additional": {
                    tagList.forEach((el) => {
                        let payload = {};
                        for (const key in el) {
                            if (Array.isArray(el[key]) && el[key].length > 0) {
                                let joinWith = " ";
                                if (el["scope"].name === "Contains all" || el["scope"].name === "Does not contains all") {
                                    joinWith = " AND ";
                                } else if (el["scope"].name === "Contains any" || el["scope"].name === "Does not contains any") {
                                    joinWith = " OR ";
                                }
                                if (key !== "scope" || key !== "type" || key !== "proximity") {
                                    payload[key] = el[key].join(joinWith);
                                }
                            } else if (key === "scope" || key === "type" || key === "proximity" || key === "party_types" || key === "representation" || key === "search_type") {
                                payload[key] = el[key].value;
                            } else if (key === "condition_operator") {
                                payload[key] = el[key].name;
                            }
                        }
                        additionalTagList.push(payload);
                    });
                    return additionalTagList;
                    // break;
                }
            }
        },
        /**
         * set the condition (AND/OR) for particular add more condition inputs row
         * @function
         * @name onSelectCondition
         */
        onSelectCondition(value, index) {
            this.newTagList[index]["condition_operator"] = {name: value};
        },

        handleShowFilterClick() {
            this.showFiltersPanel = !this.showFiltersPanel;
            if (this.showFiltersPanel) {
                const keys = ["caseNumber", "caseType", "caseStatus", "jurisdiction"];
                keys.forEach((key) => {
                    this.filters.options[key] = this.cloneResults.map((el) => el[key]).filter(this.onlyUnique);
                });
            } else {
                this.closeFilterPanel();
            }
        },

        closeFilterPanel() {
            this.showFiltersPanel = false;
            for (const key in this.filters.toggles) {
                this.filters.toggles[key] = false;
            }
            this.clearFilters();
        },

        onlyUnique(value, index, array) {
            return array.indexOf(value) === index && value !== "";
        },

        compareDate(el) {
            const startDate = new Date(this.filters.selected.dateRange[0]);
            const endDate = new Date(this.filters.selected.dateRange[1]);
            const date = new Date(el["filingDate"]);
            if (date.getTime() >= startDate.getTime() && date.getTime() <= endDate.getTime()) {
                return true;
            } else {
                return false;
            }
        },

        onApplyFilter() {
            this.appliedFilterCount = 0;
            this.startLoader("nameSearch");
            this.neoResultList.isLoading = true;
            this.filters.filterFlag = true;
            let allData = cloneDeep(this.cloneResults);
            const filterItems = ["caseNumber", "caseType", "jurisdiction", "caseStatus", "dateRange"];
            filterItems.forEach((el) => {
                if (this.filters.selected[el].length > 0) {
                    let temp = [];
                    allData.forEach((data) => {
                        if (this.filters.selected[el].includes(data[el])) {
                            temp.push(data);
                        }
                        if (el === "dateRange" && this.compareDate(data)) {
                            temp.push(data);
                        }
                    });
                    allData = temp;
                }
                this.appliedFilterCount += this.filters.selected[el].length;
            });

            this.judyRecordsResults = allData;
            this.resetLoader("nameSearch");
            this.neoResultList.isLoading = false;
            if (this.neoResultList.isResultExpanded) {
                this.neoResultList.isResultExpanded = false;
            }
        },

        clearFilters() {
            for (const key in this.filters.selected) {
                this.filters.selected[key] = [];
            }
            this.appliedFilterCount = 0;
            this.judyRecordsResults = this.cloneResults;
            this.filters.filterFlag = false;
            this.resetDropDownValue();
            this.currentQuery = -1;
        },

        handleClearDateTime() {
            if (this.filters.selected.dateRange.length > 0) {
                this.filters.selected.dateRange = [];
            }
        },

        handleOutside() {
            this.openSortingPanel = false;
        },

        onSelectOption(option) {
            this.order_by = option;
            this.search();
        },

        resetDropDownValue() {
            this.selectedDropDownItem.search_type = {name: "Everything", value: "all"};
            this.selectedDropDownItem.scope = {name: "Contains any", value: "contains any"};
            this.selectedDropDownItem.proximity = {name: "Anywhere", value: "anywhere"};
            this.selectedDropDownItem.party_types = {name: "Any", value: "any"};
            this.selectedDropDownItem.representation = {name: "Any", value: "any"};
        },

        containsTagValue(name) {
            return this.tagList[name].length;
        },

        checkMandatory() {
            let exp = this.selectedDropDownItem.search_type.name;
            switch (exp) {
                case "Parties":
                    return this.containsTagValue("parties");
                case "Attorney":
                    return this.containsTagValue("attorney");
                case "Case name":
                    return this.containsTagValue("case_name");
                case "Case number":
                    return this.containsTagValue("case_number");
                case "Judge":
                    return this.containsTagValue("judge");
                default:
                    return true;
            }
        },
        // for Court Record Tool -> unicourt
        getDisplayDropDownName(value) {
            return value.charAt(0).toUpperCase() + value.slice(1);
        },
    },
};
