import NeoAnalyse from "../analyse";
import "keylines";
import KlChart from "@/components/Chart";

export default {
    name: "modal-company-details-search",
    props: ["companyDetails"],
    data() {
        return {
            requireHeading: false,
            stabilizing: false,
        };
    },
    methods: {
        klReady(chart) {
            this.chart = chart;
            this.chart.options({handMode: true});
            this.chart.layout("sequential", {tightness: 3, orientation: "down"});
        },
        chainDataCheck(companyDetails) {
            return companyDetails?.company?.chain_data?.entity?.filter((n) => n.owner).length && companyDetails?.company?.chain_data?.entity?.filter((n) => n.ultimate_beneficiary).length;
        },
        graphCheck(companyDetails) {
            return companyDetails?.company?.chain_data?.entity.length > 0 && companyDetails?.company?.chain_data?.entity.length > 0;
        },
        async StabilizeGraph(klId) {
            this.stabilizing = true;
            await window.KeyLines.charts[`kl_corporate_records${klId}`].layout("sequential", {tightness: 3, orientation: "down"});
            this.stabilizing = false;
        },
    },
    computed: {},
    components: {
        NeoAnalyse,
        KlChart,
    },
};
