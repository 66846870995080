import {mapActions, mapGetters} from "vuex";
import axios from "@/axios";
import {TippyComponent} from "vue-tippy";
import Button from "../../components/button";

export default {
    name: "neo-navigation",
    components: {
        tippy: TippyComponent,
        "neo-button": Button,
    },
    data() {
        return {
            brand: "logo",
            navigations: [
                {
                    title: "Case Dashboard",
                    nav: "case-dashboard",
                    icon: "chart-line",
                    access_case: true,
                    enabled: true,
                    readOnly: true,
                },
                {
                    title: "Case List",
                    nav: "case-list",
                    icon: "clone",
                    access_case: true,
                    enabled: true,
                    readOnly: true,
                },
                // { nav: 'research', icon: 'search-plus' },
                {
                    title: "Org Users",
                    nav: "org-users",
                    icon: "user",
                    admin: true,
                    enabled: true,
                    readOnly: false,
                },
                {
                    title: "Product Admin",
                    nav: "product",
                    icon: "user-shield",
                    admin: true,
                    monitoring: true,
                    enabled: true,
                    readOnly: false,
                },
                //     // sub_menu: [
                //     //     { title: "Product Admin", nav: `product`, icon: 'user-shield' },
                //     // ]
                // },
                {
                    title: "Credit Packs",
                    nav: "credit-packs",
                    svg: "credit-packs",
                    admin: false,
                    access_case: true,
                    enabled: true,
                    readOnly: false,
                },
                {
                    title: "Admin",
                    nav: "admindata",
                    icon: "list-alt",
                    admin: true,
                    monitoring: true,
                    enabled: true,
                    readOnly: false,
                    sub_menu: [
                        {
                            title: "Sources",
                            nav: "Sources",
                            icon: "project-diagram",
                            admin: true,
                            enabled: true,
                            readOnly: false,
                        },
                        {
                            title: "Research Methodology",
                            nav: "Research-Methodology",
                            admin: true,
                            icon: "sitemap",
                            enabled: true,
                            readOnly: false,
                        },
                        {
                            title: "Search Engine Admin",
                            nav: "search-engine",
                            icon: "search",
                            admin: true,
                            enabled: true,
                        },
                        {
                            title: "Report Templates Admin",
                            nav: "report-templates",
                            icon: "clipboard",
                            admin: true,
                            enabled: true,
                        },
                        {
                            title: "Keywords Admin",
                            nav: "manage-keywords",
                            icon: "list-alt",
                            admin: true,
                            monitoring: true,
                            enabled: true,
                        },
                        {
                            title: "Output Templates",
                            nav: "report-builder",
                            icon: "chart-bar",
                            admin: true,
                            monitoring: true,
                            enabled: true,
                        },
                    ],
                },

                // { nav: "groups", icon: "users" },
                // { nav: 'monitor', icon: 'desktop' },
                // { nav: 'reports', icon: 'clipboard' },
                // { nav: 'settings', icon: 'cog' },
                // {
                //     nav: 'admin',
                //     icon: 'user-cog',

                // },
            ],
            // readOnly: false,
        };
    },
    mounted() {},
    computed: {
        ...mapGetters(["getPanelStatus", "getDirectIpLogin", "getReadOnlyMode", "getPermissions", "getLicenses", "checkPermissionsFetched", "getIsUserAdmin", "getUserCaseAccess"]),
        isAuth() {
            return this.getLicenses?.includes("case_create__case_data");
        },
        isMonitoringEnabled() {
            // return (process.env.VUE_APP_TAG_NAME === "local" || process.env.VUE_APP_TAG_NAME === "development");
            return true;
        },
        readOnly() {
            return this.getReadOnlyMode ?? false;
        },
        filteredNavigations() {
            if (this.readOnly) return this.navigations.filter((nav) => nav.readOnly);
            else return this.navigations;
        },
        // showSideBar() {
        //     return this.getLicenses.includes("methodology__methodology") || this.getLicenses.includes("sources__sources") || this.getLicenses.includes("monitoring__monitoring")
        //     || this.getLicenses.includes("social_media_automation__social_media");
        // },
        // isShowResearchTab() {
        //     const researchTabs = ["outputs__case_data", "detail_section__case_data", "social_profiles__case_data", "associated_entities__case_data", "media__case_data"];
        //     return this.getLicenses.some((permission) => researchTabs.includes(permission));
        // },
        // isTools() {
        //     return this.getLicenses.includes("analysis_tools__tools")
        // },
        showSideBar() {
            return window == window.parent;
        },
    },
    methods: {
        ...mapActions(["togglePanel", "fetchPermissions"]),
        subMenuToggle(nav) {
            this.$set(nav, "toggleOn", !nav.toggleOn);
        },
        selectProduct() {
            this.$router.push("/case/product");
        },
        async createCase() {
            const {data} = await axios.post(`/case-info/case-create`);
            if (data.case_id) {
                this.$toast.success(`Case ${data.case_id} created.`);
                // this.$router.push({path:`/research/${data.case_id}`});
                // this.$router.go();
                window.open(`/research/${data.case_id}`, "_self");
                sessionStorage.clear();
                localStorage.clear();
            } else {
                this.$toast.error("Something went wrong while creating new case.");
            }
        },
        onToggle() {
            this.togglePanel();
            !this.getPanelStatus ? (this.brand = "logo") : (this.brand = "brand-mark");
            this.$emit("toggle", this.getPanelStatus);
        },
        goHome() {
            if (this.getReadOnlyMode) {
                return;
            } else this.$router.push({path: "/case-list"}).catch(() => null);
        },
    },
    async created() {
        // await this.fetchPermissions();
    },
};
