import router from "@/router";
import JWT from "jsonwebtoken";
import VueCookies from "vue-cookies";
import axios from "@/axios";
import api from "@/axios";
import osintApi from "@/axios/osint";
import {DateTime} from "luxon";
import {logoutFromSaas} from "@/utils/auth";
import {SSO_PUBLIC_CERT, BLACK_BOX_LOGOUT_URL, SSO_LOGOUT_URL} from "@/utils/constants";
import {blackboxApi, iamApi, caseApi, userApi, jwtBlackboxApi, osintDataUrl, riskApi} from "@/main";

let endpoints = [api, osintApi, blackboxApi, iamApi, caseApi, userApi, jwtBlackboxApi, osintDataUrl, riskApi];

function setupAuthDetails(decoded) {
    let email = decoded.email;
    let username = decoded.given_name;
    let cognito_user_id = decoded["cognito:username"];
    let userRole = decoded["cognito:groups"]?.join(",") || "";
    let name = decoded.family_name ? `${decoded.given_name} ${decoded.family_name}` : `${decoded.given_name}`;
    let payload = {};
    payload.userId = cognito_user_id;
    payload.username = username;
    payload.email = email;
    payload.userRole = userRole;
    payload.name = name;
    return payload;
}

export default {
    state: () => ({
        userLoggedIn: false,
        userObj: null,
        userToken: null,
        logoutTimer: null,
        serverResponse: {},
        isUserAdmin: false,
        access_all_cases: false,
    }),
    mutations: {
        SET_LOGIN_STATE(state, payload) {
            state.userObj = setupAuthDetails(payload.decodedToken);
            state.userToken = payload.token;
            VueCookies.set("token", payload.token);
            state.userLoggedIn = true;
        },
        SET_SERVER_RESPONSE(state, payload) {
            state.serverResponse = payload;
        },
        SET_USER_ADMIN_STATUS(state, payload) {
            state.isUserAdmin = payload.isAdmin;
        },
        SET_USER_ACCESSS_ALL_CASES(state, payload) {
            state.access_all_cases = payload.access_all_cases;
        },
    },
    actions: {
        setUserAdminStatus({commit}, payload) {
            commit("SET_USER_ADMIN_STATUS", payload);
        },
        setUserAccessAlCases({commit}, payload) {
            commit("SET_USER_ACCESSS_ALL_CASES", payload);
        },
        setLoginSession({commit}, payload) {
            VueCookies.set("token", payload.token, null, null, null, "true", "none");
            commit("SET_LOGIN_STATE", payload);
        },
        async logoutUser({commit}) {
            try {
                await logoutFromSaas();
            } catch (error) {
            } finally {
                VueCookies.remove("token");
                VueCookies.remove("ACCOUNT_ID");
                const loginPath = router.resolve({name: "Login"});
                VueCookies.set("next-url", loginPath.href);

                window.location.href = SSO_LOGOUT_URL;
            }
        },
    },
    getters: {
        loggedIn: (state) => state.userLoggedIn,
        user: (state) => state.userObj,
        isTokenValid: (state) => state.isTokenValid,
        getUserAuthDetails: (state) => state.userObj,
        getUserRoles: (state) => state.userObj.userRole,
        getIsUserAdmin: (state) => state.isUserAdmin,
        getUserCaseAccess: (state) => state.access_all_cases,
    },
};
