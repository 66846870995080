import Header from "@/containers/header";
import Navigation from "@/containers/navigation";
import {mapActions, mapGetters} from "vuex";
import axios from "@/axios/user";
import SaasAxios from "@/axios/index.js";
import onBoardingPanel from "@/views/onboarding";
import AddEntityPanel from "@/containers/add-entity-panel";
import VueCookies from "vue-cookies";
import {EventBus} from "@/main.js";
import moment from "moment";
// Dynamic imports for components that are not used on first load
const GenericModal = () => import("@/components/genericModal");
const DatePicker = () => import("vue2-datepicker");
import {required} from "vuelidate/lib/validators";
import {datetime, RRule, RRuleSet, rrulestr} from "rrule";
import {decodeToken} from "@/utils/auth";

export default {
    name: "neo-core",
    data() {
        return {
            fetchingAccountFinished: false,
            navOpen: true,
            overflowHidden: false,
            isShowNavbar: true,
            accountName: null,
            isHeader: true,
            isDueHeader: true,
            review_date: null,
            due_date: null,
            loading: false,
            frequencyType: "daily",
            everyDay: true,
            everyWeekDay: "",
            enddateAfter: "",
            weekdays: "",
            days: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
            months: "",
            frequency: [
                {id: 1, checked: true, type: "daily"},
                {id: 2, checked: false, type: "weekly"},
                {id: 3, checked: false, type: "monthly"},
                {id: 4, checked: false, type: "yearly"},
            ],
            repeatWeek: ["First", "Second", "Third", "Fourth", "Last"],
            monthsInYear: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
            daysInWeek: [
                {id: 1, dayName: "Monday", checked: false, label: "MO"},
                {id: 2, dayName: "Tuesday", checked: false, label: "TU"},
                {id: 3, dayName: "Wednesday", checked: false, label: "WE"},
                {id: 4, dayName: "Thursday", checked: false, label: "TH"},
                {id: 5, dayName: "Friday", checked: false, label: "FR"},
                {id: 6, dayName: "Saturday", checked: false, label: "SA"},
                {id: 7, dayName: "Sunday", checked: false, label: "SU"},
            ],
            noEndDate: false,
            endAfter: false,
            endBy: true,
            occurrences: "",
            start_date: new Date(),
            end_date: null,
            recurYear: 1,
            absoluteMonth: {dayNumber: new Date().getDate().toString(), monthNumber: 1},
            relativeMonth: {
                weeklyRepeat: this.getWeekOfMonth(),
                dayRepeat: "",
                everyMonth: 1,
            },
            absoluteYear: {
                dayMonth: new Date().getDate(),
                monthYear: this.getMonthNameFull(),
            },
            relativeYear: {
                week: this.getWeekOfMonth(),
                everyMonthYear: this.getMonthNameFull(),
                yearDay: "",
            },
            afterDays: 1,
            noOfdays: 1,
            pattern: "",
            absoluteMonthly: true,
            relativeMonthly: false,
            absoluteYearly: true,
            relativeYearly: false,
            dayOfMonth: "",
            daysOfWeek: [],
            next_occurrence: null,
            weekDaysSelected: false,
        };
    },
    watch: {
        async $route(to, from) {
            if (!this.getCaseId || to.params.id !== this.getCaseId) {
                this.$store.commit("setCase", {caseId: to.params.id});
                this.$store.dispatch("setCaseId", to.params.id);
            }
            await this.initializeData();
            if (this.getCaseId) {
                await this.fetchCaseValid();
                await this.fetchCaseFreezeDate();
            }
        },
    },

    components: {
        "neo-navigation": Navigation,
        "neo-header": Header,
        "onboarding-panel": onBoardingPanel,
        AddEntityPanel,
        "neo-generic-modal": GenericModal,
        "neo-datepicker": DatePicker,
    },
    computed: {
        ...mapGetters(["getPanelStatus", "getOnBoardingStatus", "getCaseReviewDatePopup", "getSocketIO", "getCaseDueDatePopup", "getCasePersonalData", "checkPermissionsFetched", "getCaseDataLevel", "getCaseId"]),
        // getPageGridColumnTamplate() {
        //     if (!this.getOnBoardingStatus) {
        //         return null;
        //     } else {
        //         if (!this.getPanelStatus) {
        //             return { gridTemplateColumns: "minmax(64px, 1fr) 29fr" };
        //         } else return { gridTemplateColumns: "minmax(256px, 1fr) 9fr" };
        //     }
        // },

        getCaseReviewDate() {
            this.review_date = this.getCasePersonalData?.upcoming_review_date ? new Date(this.getCasePersonalData.upcoming_review_date) : new Date();
        },
        getCaseDueDate() {
            this.due_date = this.getCasePersonalData?.case_due_date ? new Date(this.getCasePersonalData.case_due_date) : new Date();
        },
        handleChecked() {
            if (this.everyDay) return true;
        },
        isExtendedVersion() {
            const token = VueCookies.get("token");
            if (token) {
                const decodedToken = decodeToken(token);
                if (decodedToken?.session_src === "neotaswlp") return true;
                else return false;
            } else return false;
        },
        noheaderURLS() {
            return ["Redirect", "LoginCallback", "Login", "forgot-password", "update-password", "otp-login"].includes(this.$route.name) ? true : false;
        },
    },
    async mounted() {
        await this.switchSocketIOConn();
        this.end_date = this.getEndDate(90);
        this.relativeYear.yearDay = this.getDayNameFull();
        this.relativeMonth.dayRepeat = this.getDayNameFull();
    },
    async created() {
        // this.getReadOnlyLicense()
        const topprogressActions = {
            start: this.$refs?.topProgress?.start,
            done: this.$refs?.topProgress?.done,
        };
        EventBus.$on("topprogress", (data) => {
            topprogressActions[data]();
        });
        EventBus.$on("editReview", (data) => {
            if (data) this.editReviewFreq();
        });
        EventBus.$on("resetMonitoring", (data) => {
            if (data) this.resetReviewFrequency();
        });
    },
    validations: {
        absoluteMonth: {
            dayNumber: {
                required,
            },
            monthNumber: {
                required,
            },
        },
        relativeMonth: {
            everyMonth: {
                required,
            },
        },
        absoluteYear: {
            dayMonth: {
                required,
            },
        },
        recurYear: {
            required,
        },
        noOfdays: {
            required,
        },
        afterDays: {
            required,
        },
        occurrences: {
            required,
        },
    },
    methods: {
        ...mapActions(["togglePanel", "fetchPermissions", "fetchCaseValid", "fetchCaseFreezeDate", "getCaseListReadOnly", "createSocketIOConn"]),
        onHideOverflow(overflow) {
            this.overflowHidden = overflow;
        },
        showNavbar() {
            return ["Not Found", "Redirect", "LoginCallback", "Login", "forgot-password", "update-password", "otp-login", "Unauthorized"].includes(this.$route.name) ? false : true;
        },
        getEndDate(days) {
            var date = new Date();
            date.setDate(date.getDate() + days);
            return date;
        },
        getWeekOfMonth() {
            var date = new Date();
            const startWeekDayIndex = 1; // 1 MonthDay 0 Sundays
            const firstDate = new Date(date.getFullYear(), date.getMonth(), 1);
            const firstDay = firstDate.getDay();

            let weekOfMonth = Math.ceil((date.getDate() + firstDay) / 7);
            if (startWeekDayIndex === 1) {
                if (date.getDay() === 0 && date.getDate() > 1) {
                    weekOfMonth -= 1;
                }

                if (firstDate.getDate() === 1 && firstDay === 0 && date.getDate() > 1) {
                    weekOfMonth += 1;
                }
            }
            if (weekOfMonth === 1) return "First";
            if (weekOfMonth === 2) return "Second";
            if (weekOfMonth === 3) return "Third";
            if (weekOfMonth === 4) return "Fourth";
            if (weekOfMonth === 5) return "Last";
        },
        getMonthNameFull() {
            const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            const d = new Date();
            return month[d.getMonth()];
        },
        getDayNameFull() {
            const day = new Date().getDay();
            const dayMap = {
                0: "Sunday",
                1: "Monday",
                2: "Tuesday",
                3: "Wednesday",
                4: "Thursday",
                5: "Friday",
                6: "Saturday",
            };
            return dayMap[day];
        },
        getEndDt(days) {
            var date = new Date();
            date.setDate(date.getDate() + days);
            return date;
        },
        handleDayNumber() {
            if (this.absoluteMonth.dayNumber && (this.absoluteMonth.dayNumber > 31 || this.absoluteMonth.dayNumber < 0)) return true;
        },
        handleEndAfter() {
            if (this.endAfter && (this.occurrences === "" || this.occurrences <= 0)) return true;
        },
        async updateReviewFrequency() {
            this.$v.$touch();
            if (this.frequencyType === "weekly" && this.daysOfWeek.length == 0) this.weekDaysSelected = true;
            else this.weekDaysSelected = false;
            if (this.endDate && (this.end_date == "" || this.end_date !== null || moment(this.start_date).format("YYYY-MM-DD") < moment(this.end_date).format("YYYY-MM-DD"))) {
                this.endDateError = true;
            } else this.endDateError = false;

            if (
                ((this.frequencyType === "daily" && this.everyDay && this.noOfdays > 0) ||
                    (this.frequencyType === "daily" && this.everyWeekDay) ||
                    (this.frequencyType === "weekly" && this.daysOfWeek.length > 0 && this.handleAfterDay() !== true) ||
                    (this.frequencyType === "monthly" && this.absoluteMonth.dayNumber && this.absoluteMonthly && this.absoluteMonth.monthNumber && this.handleDayNumber() !== true && this.handleMonthNumber() !== true) ||
                    (this.frequencyType === "monthly" && this.relativeMonth.everyMonth && this.relativeMonthly && this.handleEveryMonth() !== true) ||
                    (this.frequencyType === "yearly" && this.absoluteYearly && this.absoluteYear.dayMonth !== "" && !this.handleDayMonth() && this.recurYear !== "") ||
                    (this.frequencyType === "yearly" && this.relativeYearly)) &&
                !this.endDateError &&
                !this.handleEndAfter()
            ) {
                let payload;
                let range_occurence;
                let dateFormat = moment(this.start_date)
                    .format("YYYY-MM-DD")
                    .split("-")
                    .join("");
                if (this.noEndDate) {
                    let endDate = new Date();
                    endDate.setFullYear(endDate.getFullYear() + 5);
                    range_occurence = {
                        range_type: "no_end",
                        start_date: moment(this.start_date).format("YYYY-MM-DD"),
                        end_date: moment(endDate).format("YYYY-MM-DD"),
                    };
                }
                if (this.endAfter) {
                    range_occurence = {
                        range_type: "numbered",
                        start_date: moment(this.start_date).format("YYYY-MM-DD"),
                        numberofoccurrences: this.occurrences,
                    };
                }
                if (this.endBy == true) {
                    range_occurence = {
                        range_type: "end_date",
                        start_date: moment(this.start_date).format("YYYY-MM-DD"),
                        end_date: moment(this.end_date).format("YYYY-MM-DD"),
                    };
                }
                if (this.everyDay && this.frequencyType === "daily") {
                    let rrule_obj = rrulestr(`DTSTART:${dateFormat} RRULE:FREQ=DAILY;INTERVAL=${this.noOfdays}`);
                    this.next_occurrence = rrule_obj.after(new Date());
                    payload = {
                        state_name: "Monitoring",
                        case_id: this.getCaseId,
                        recurrence: {
                            pattern: {
                                pattern_type: "daily",
                                interval: this.noOfdays,
                            },
                            range_occurence: range_occurence,
                        },
                        review_freq_format: `DTSTART:${dateFormat} RRULE:FREQ=DAILY;INTERVAL=${this.noOfdays}`,
                    };
                } else if (this.everyWeekDay && this.frequencyType === "daily") {
                    let rrule_obj = rrulestr(`DTSTART:${dateFormat} RRULE:FREQ=WEEKLY;BYDAY=MO,TU,WE,TH,FR`);
                    this.next_occurrence = rrule_obj.after(new Date());
                    payload = {
                        state_name: "Monitoring",
                        case_id: this.getCaseId,
                        recurrence: {
                            pattern: {
                                pattern_type: "daily",
                                daysofweek: ["MO", "TU", "WE", "TH", "FR"],
                            },
                            range_occurence: range_occurence,
                        },
                        review_freq_format: `DTSTART:${dateFormat} RRULE:FREQ=WEEKLY;BYDAY=MO,TU,WE,TH,FR`,
                    };
                } else if (this.frequencyType === "weekly") {
                    let weekDays = this.daysOfWeek.join();
                    payload = {
                        state_name: "Monitoring",
                        case_id: this.getCaseId,
                        recurrence: {
                            pattern: {
                                pattern_type: "weekly",
                                interval: this.afterDays,
                                daysofweek: this.daysOfWeek,
                            },
                            range_occurence: range_occurence,
                        },
                        review_freq_format: `DTSTART:${dateFormat} RRULE:FREQ=WEEKLY;INTERVAL=${this.afterDays};BYDAY=${weekDays}`,
                    };
                    let rrule_obj = rrulestr(`DTSTART:${dateFormat} RRULE:FREQ=WEEKLY;INTERVAL=${this.afterDays};BYDAY=${weekDays}`);
                    this.next_occurrence = rrule_obj.after(new Date());
                } else if (this.frequencyType === "monthly" && this.absoluteMonthly === true) {
                    let rrule_obj = rrulestr(`DTSTART:${dateFormat} RRULE:FREQ=MONTHLY;INTERVAL=${this.absoluteMonth.monthNumber};BYMONTHDAY=${this.absoluteMonth.dayNumber}`);
                    this.next_occurrence = rrule_obj.after(new Date());
                    payload = {
                        state_name: "Monitoring",
                        case_id: this.getCaseId,
                        recurrence: {
                            pattern: {
                                pattern_type: "absoluteMonthly",
                                interval: this.absoluteMonth.monthNumber,
                                dayofmonth: this.absoluteMonth.dayNumber,
                            },
                            range_occurence: range_occurence,
                        },
                        review_freq_format: `DTSTART:${dateFormat} RRULE:FREQ=MONTHLY;INTERVAL=${this.absoluteMonth.monthNumber};BYMONTHDAY=${this.absoluteMonth.dayNumber}`,
                    };
                } else if (this.frequencyType === "monthly" && this.relativeMonthly) {
                    payload = {
                        state_name: "Monitoring",
                        case_id: this.getCaseId,
                        recurrence: {
                            pattern: {
                                pattern_type: "relativeMonthly",
                                interval: this.relativeMonth.everyMonth,
                                index: this.relativeMonth.weeklyRepeat.toLowerCase(),
                                daysofweek: [this.getDayName(this.relativeMonth.dayRepeat)],
                            },
                            range_occurence: range_occurence,
                        },
                        review_freq_format: `DTSTART:${dateFormat} RRULE:FREQ=MONTHLY;INTERVAL=${this.relativeMonth.everyMonth};BYDAY=${this.getWeekIndex(this.relativeMonth.weeklyRepeat)}${this.getDayName(this.relativeMonth.dayRepeat)}`,
                    };
                    let rrule_obj = rrulestr(`DTSTART:${dateFormat} RRULE:FREQ=MONTHLY;INTERVAL=${this.relativeMonth.everyMonth};BYDAY=${this.getWeekIndex(this.relativeMonth.weeklyRepeat)}${this.getDayName(this.relativeMonth.dayRepeat)}`);
                    this.next_occurrence = rrule_obj.after(new Date());
                } else if (this.frequencyType === "yearly" && this.absoluteYearly) {
                    payload = {
                        state_name: "Monitoring",
                        case_id: this.getCaseId,
                        recurrence: {
                            pattern: {
                                pattern_type: "absoluteYearly",
                                interval: this.recurYear,
                                month: this.monthsInYear.indexOf(this.absoluteYear.monthYear) + 1,
                                dayofmonth: this.absoluteYear.dayMonth,
                            },
                            range_occurence: range_occurence,
                        },
                        review_freq_format: `DTSTART:${dateFormat} RRULE:FREQ=YEARLY;INTERVAL=${this.recurYear};BYMONTH=${this.monthsInYear.indexOf(this.absoluteYear.monthYear) + 1};BYMONTHDAY=${this.absoluteYear.dayMonth}`,
                    };
                    let rrule_obj = rrulestr(`DTSTART:${dateFormat} RRULE:FREQ=YEARLY;INTERVAL=${this.recurYear};BYMONTH=${this.monthsInYear.indexOf(this.absoluteYear.monthYear) + 1};BYMONTHDAY=${this.absoluteYear.dayMonth}`);
                    this.next_occurrence = rrule_obj.after(new Date());
                } else if (this.frequencyType === "yearly" && this.relativeYearly) {
                    payload = {
                        state_name: "Monitoring",
                        case_id: this.getCaseId,
                        recurrence: {
                            pattern: {
                                pattern_type: "relativeYearly",
                                interval: this.recurYear,
                                index: this.relativeYear.week.toLowerCase(),
                                daysofweek: [this.getDayName(this.relativeYear.yearDay)],
                                month: this.monthsInYear.indexOf(this.relativeYear.everyMonthYear) + 1,
                            },
                            range_occurence: range_occurence,
                        },
                        review_freq_format: `DTSTART:${dateFormat} RRULE:FREQ=YEARLY;INTERVAL=${this.recurYear};BYMONTH=${this.monthsInYear.indexOf(this.relativeYear.everyMonthYear) + 1};BYDAY=${this.getWeekIndex(this.relativeYear.week)}${this.getDayName(this.relativeYear.yearDay)}`,
                    };
                    let rrule_obj = rrulestr(`DTSTART:${dateFormat} RRULE:FREQ=YEARLY;INTERVAL=${this.recurYear};BYMONTH=${this.monthsInYear.indexOf(this.relativeYear.everyMonthYear) + 1};BYDAY=${this.getWeekIndex(this.relativeYear.week)}${this.getDayName(this.relativeYear.yearDay)}`);
                    this.next_occurrence = rrule_obj.after(new Date());
                }
                // const res = await SaasAxios.post('/case-info/upcoming-review-date',payload);
                if (moment(this.end_date).format("YYYY-MM-DD") < moment(this.next_occurrence).format("YYYY-MM-DD")) {
                    var date = this.next_occurrence;
                    date?.setDate(date.getDate() + 30);
                    this.end_date = date;
                } else {
                    this.$store.dispatch("setCaseReviewDate", payload);
                    this.$store.commit("SET_CASE_REVIEW_DATE_POPUP", false);
                }
            }
        },
        getWeekday(day) {
            let details = this.daysInWeek.find((el) => el.label === day);
            return details.dayName;
        },
        getMonthName(monthNumber) {
            const date = new Date();
            date.setMonth(monthNumber - 1);

            return date.toLocaleString("en-US", {
                month: "long",
            });
        },

        async editReviewFreq() {
            this.weekDaysSelected = false;
            this.resetReviewFrequency();
            const res = await SaasAxios.get(`/case-info/upcoming-review-date?case_id=${this.getCaseId}`);
            this.start_date = new Date();
            let dateFormat = moment(this.start_date)
                .format("YYYY-MM-DD")
                .split("-")
                .join("");
            if (res.data.details.recurrence_pattern.pattern_type === "absoluteMonthly" || res.data.details.recurrence_pattern.pattern_type === "relativeMonthly") {
                this.frequencyType = "monthly";
                let week = res.data.details.recurrence_pattern.index ? res.data.details.recurrence_pattern.index : "";
                this.absoluteMonthly = res.data.details.recurrence_pattern.pattern_type === "absoluteMonthly" ? true : false;
                this.relativeMonthly = res.data.details.recurrence_pattern.pattern_type === "relativeMonthly" ? true : false;
                this.absoluteMonth.monthNumber = res.data.details.recurrence_pattern.pattern_type === "absoluteMonthly" ? res.data.details.recurrence_pattern.interval : 1;
                this.relativeMonth.everyMonth = res.data.details.recurrence_pattern.pattern_type === "relativeMonthly" ? res.data.details.recurrence_pattern.interval : 1;
                this.absoluteMonth.dayNumber = res.data.details.recurrence_pattern.dayofmonth ? res.data.details.recurrence_pattern.dayofmonth : "";
                this.relativeMonth.weeklyRepeat = res.data.details.recurrence_pattern.index ? week.charAt(0).toUpperCase() + week.slice(1) : this.relativeMonth.weeklyRepeat;
                this.relativeMonth.dayRepeat = res.data.details.recurrence_pattern.daysofweek ? this.getWeekday(res.data.details.recurrence_pattern.daysofweek[0]) : this.relativeMonth.dayRepeat;
                if (this.absoluteMonthly) {
                    let rrule_obj = rrulestr(`DTSTART:${dateFormat} RRULE:FREQ=MONTHLY;INTERVAL=${this.absoluteMonth.monthNumber};BYMONTHDAY=${this.absoluteMonth.dayNumber}`);
                    this.next_occurrence = rrule_obj.after(new Date());
                } else if (this.relativeMonthly) {
                    let rrule_obj = rrulestr(`DTSTART:${dateFormat} RRULE:FREQ=MONTHLY;INTERVAL=${this.relativeMonth.everyMonth};BYDAY=${this.getWeekIndex(this.relativeMonth.weeklyRepeat)}${this.getDayName(this.relativeMonth.dayRepeat)}`);
                    this.next_occurrence = rrule_obj.after(new Date());
                }
            } else if (res.data.details.recurrence_pattern.pattern_type === "daily") {
                this.frequencyType = "daily";
                this.noOfdays = res.data.details.recurrence_pattern.interval ? res.data.details.recurrence_pattern.interval : 1;
                this.everyWeekDay = res.data.details.recurrence_pattern.daysofweek?.length > 0 ? true : false;
                this.everyDay = res.data.details.recurrence_pattern.interval ? true : false;
                if (this.everyDay) {
                    let rrule = rrulestr(`DTSTART:${dateFormat} RRULE:FREQ=DAILY;INTERVAL=${this.noOfdays}`);
                    this.next_occurrence = rrule.after(new Date());
                }
                if (this.everyWeekDay) {
                    let rrule_obj = rrulestr(`DTSTART:${dateFormat} RRULE:FREQ=WEEKLY;BYDAY=MO,TU,WE,TH,FR`);
                    this.next_occurrence = rrule_obj.after(new Date());
                }
            } else if (res.data.details.recurrence_pattern.pattern_type === "weekly") {
                this.frequencyType = "weekly";
                this.afterDays = res.data.details.recurrence_pattern.interval ? res.data.details.recurrence_pattern.interval : "";
                this.daysOfWeek = [];
                this.daysInWeek.map((el) => (el.checked = false));
                if (res.data.details.recurrence_pattern.daysofweek && res.data.details.recurrence_pattern.daysofweek.length > 0) {
                    res.data.details.recurrence_pattern.daysofweek.forEach((el) => {
                        this.daysInWeek.find((day) => {
                            if (day.label === el) {
                                day.checked = true;
                                this.daysOfWeek.push(el);
                            }
                        });
                    });
                }
                let weekDays = this.daysOfWeek.join();
                let rrule_obj = rrulestr(`DTSTART:${dateFormat} RRULE:FREQ=WEEKLY;INTERVAL=${this.afterDays};BYDAY=${weekDays}`);
                this.next_occurrence = rrule_obj.after(new Date());
            } else if (res.data.details.recurrence_pattern.pattern_type === "relativeYearly" || res.data.details.recurrence_pattern.pattern_type === "absoluteYearly") {
                this.frequencyType = "yearly";
                this.recurYear = res.data.details.recurrence_pattern.interval ? res.data.details.recurrence_pattern.interval : "";
                let week = res.data.details.recurrence_pattern.index ? res.data.details.recurrence_pattern.index : "";
                if (res.data.details.recurrence_pattern.pattern_type === "absoluteYearly") {
                    this.absoluteYearly = true;
                    this.relativeYearly = false;
                    this.absoluteYear.monthYear = res.data.details.recurrence_pattern.month ? this.getMonthName(res.data.details.recurrence_pattern.month) : this.absoluteYear.monthYear;
                    this.absoluteYear.dayMonth = res.data.details.recurrence_pattern.dayofmonth ? res.data.details.recurrence_pattern.dayofmonth : "";
                    let rrule_obj = rrulestr(`DTSTART:${dateFormat} RRULE:FREQ=YEARLY;INTERVAL=${this.recurYear};BYMONTH=${this.monthsInYear.indexOf(this.absoluteYear.monthYear) + 1};BYMONTHDAY=${this.absoluteYear.dayMonth}`);
                    this.next_occurrence = rrule_obj.after(new Date());
                } else if (res.data.details.recurrence_pattern.pattern_type === "relativeYearly") {
                    this.relativeYearly = true;
                    this.absoluteYearly = false;
                    this.relativeYear.everyMonthYear = res.data.details.recurrence_pattern.month ? this.getMonthName(res.data.details.recurrence_pattern.month) : this.relativeYear.everyMonthYear;
                    this.relativeYear.week = res.data.details.recurrence_pattern.index ? week.charAt(0).toUpperCase() + week.slice(1) : this.relativeYear.week;
                    this.relativeYear.yearDay = res.data.details.recurrence_pattern.daysofweek ? this.getWeekday(res.data.details.recurrence_pattern.daysofweek[0]) : this.relativeYear.yearDay;
                    let rrule_obj = rrulestr(`DTSTART:${dateFormat} RRULE:FREQ=YEARLY;INTERVAL=${this.recurYear};BYMONTH=${this.monthsInYear.indexOf(this.relativeYear.everyMonthYear) + 1};BYDAY=${this.getWeekIndex(this.relativeYear.week)}${this.getDayName(this.relativeYear.yearDay)}`);
                    this.next_occurrence = rrule_obj.after(new Date());
                }
            }
            this.frequency.map((el) => {
                if (el.type === this.frequencyType) el.checked = true;
                else el.checked = false;
            });
            this.occurrences = res.data.details.numberofoccurrences ? res.data.details.numberofoccurrences : "";
            this.noEndDate = res.data.details.range_type === "no_end" ? true : false;
            this.endAfter = res.data.details.range_type === "numbered" ? true : false;
            this.endBy = res.data.details.range_type === "end_date" ? true : false;
            if (this.endBy) {
                let end_date = res.data.details.end_date;
                if (new Date(end_date) > this.start_date) this.end_date = new Date(end_date);
            } else if (this.next_occurrence) {
                let disabledate = new Date(new Date(this.next_occurrence).getTime() + 24 * 60 * 60 * 1000);
                this.end_date = disabledate;
            }
        },
        rangeOfOccurrence(range) {
            if (range === "no_end") {
                this.noEndDate = true;
                this.endAfter = false;
                this.endBy = false;
            } else if (range === "end_after") {
                this.noEndDate = false;
                this.endAfter = true;
                this.endBy = false;
            } else if (range === "end_by") {
                this.noEndDate = false;
                this.endAfter = false;
                this.endBy = true;
                let date = this.getEndDate(90);
                if (this.next_occurrence) {
                    let disabledate = new Date(new Date(this.next_occurrence).getTime() + 24 * 60 * 60 * 1000);
                    if (disabledate > this.end_date) this.end_date = disabledate;
                } else if (this.frequencyType === "yearly") {
                    let endDate = new Date();
                    endDate.setFullYear(endDate.getFullYear() + 1);
                    this.end_date = endDate;
                } else if (this.frequencyType === "weekly" || this.frequencyType === "daily") {
                    this.end_date = this.getEndDate();
                }
            }
        },
        disableEndDate(date) {
            if (this.next_occurrence !== null) {
                let disabledate = new Date(new Date(this.next_occurrence).getTime() - 24 * 60 * 60 * 1000);
                return date <= disabledate;
            } else return date < new Date();
        },

        handleInputRange(range) {
            if (range > 99) {
                this.noOfdays = 99;
            }
            if (this.noOfdays < 0) {
                this.noOfdays = 1;
            }
        },
        handleCheckbox(id) {
            const day = document.getElementById(id);
            if (id === "everyday" && day.checked) {
                this.everyDay = true;
                this.everyWeekDay = false;
            } else if (id === "everyweekday" && day.checked) {
                this.everyWeekDay = true;
                this.everyDay = false;
            }
        },
        handledaysofweek(checkedDay) {
            this.weekDaysSelected = false;
            this.daysInWeek.find((el) => {
                if (el.id === checkedDay.id) {
                    let check = !this.daysOfWeek.includes(el.label);
                    if (check) {
                        el.checked = true;
                        this.daysOfWeek.push(el.label);
                    } else {
                        const index = this.daysOfWeek.indexOf(el.label);
                        if (index > -1) {
                            this.daysOfWeek.splice(index, 1);
                            this.daysInWeek.find((el) => {
                                if (el.id === checkedDay.id) {
                                    el.checked = false;
                                }
                            });
                        }
                    }
                }
            });
        },
        handleReviewYearly(id) {
            this.weekDaysSelected = false;
            const yearly = document.getElementById(id);
            if (id === "absyear" && yearly.checked) {
                this.absoluteYearly = true;
                this.relativeYearly = false;
            } else if (id === "relativeyear" && yearly.checked) {
                this.relativeYearly = true;
                this.absoluteYearly = false;
            }
        },
        handleDayMonth() {
            if (this.absoluteYear.dayMonth !== "") {
                if ((this.absoluteYear.monthYear === "January" || this.absoluteYear.monthYear === "March" || this.absoluteYear.monthYear === "May" || this.absoluteYear.monthYear === "July" || this.absoluteYear.monthYear === "August" || this.absoluteYear.monthYear === "October" || this.absoluteYear.monthYear === "December") && (this.absoluteYear.dayMonth > 31 || this.absoluteYear.dayMonth <= 0)) return true;
                else if ((this.absoluteYear.monthYear === "June" || this.absoluteYear.monthYear === "September" || this.absoluteYear.monthYear === "November") && (this.absoluteYear.dayMonth > 30 || this.absoluteYear.dayMonth <= 0)) return true;
                else if (this.absoluteYear.monthYear === "February" && (this.absoluteYear.dayMonth > 29 || this.absoluteYear.dayMonth <= 0)) return true;
                else return false;
            } else if (this.absoluteYear.dayMonth == "") return false;
        },
        getDayName(day) {
            let details = this.daysInWeek.find((el) => el.dayName === day);
            if (details) return details.label;
        },
        getWeekIndex(index) {
            if (index) {
                if (index.toLowerCase() === "first") return 1;
                if (index.toLowerCase() === "second") return 2;
                if (index.toLowerCase() === "third") return 3;
                if (index.toLowerCase() === "fourth") return 4;
                if (index.toLowerCase() === "last") return -1;
            }
        },
        handleUpcomingDate() {
            if (this.absoluteMonth.dayNumber !== null && this.absoluteMonthly && this.absoluteMonth.monthNumber !== "" && this.absoluteMonth.monthNumber > 0) {
                let dateFormat = moment(this.startdate)
                    .format("YYYY-MM-DD")
                    .split("-")
                    .join("");
                let rrule_obj = rrulestr(`DTSTART:${dateFormat} RRULE:FREQ=MONTHLY;INTERVAL=${this.absoluteMonth.monthNumber};BYMONTHDAY=${this.absoluteMonth.dayNumber}`);
                this.next_occurrence = rrule_obj.after(new Date());

                if (this.getEndDate(90) > moment(this.next_occurrence).format("YYYY-MM-DD")) this.end_date = new Date(this.getEndDt(90));
                else {
                    var date = this.next_occurrence;
                    date.setDate(date.getDate());
                    this.end_date = new Date(date);
                }
            } else if (this.relativeMonth.weeklyRepeat !== "" && this.relativeMonth.dayRepeat !== "" && this.relativeMonth.everyMonth !== "" && this.relativeMonth.everyMonth > 0 && this.relativeMonth.dayRepeat > 0 && this.relativeMonthly) {
                setTimeout(() => {
                    let dateFormat = moment(this.startdate)
                        .format("YYYY-MM-DD")
                        .split("-")
                        .join("");
                    this.next_occurrence = "";
                    let rrule_obj = rrulestr(`DTSTART:${dateFormat} RRULE:FREQ=MONTHLY;INTERVAL=${this.relativeMonth.everyMonth};BYDAY=${this.getWeekIndex(this.relativeMonth.weeklyRepeat)}${this.getDayName(this.relativeMonth.dayRepeat)}`);
                    this.next_occurrence = rrule_obj.after(new Date());
                    if (moment(this.end_date).format("YYYY-MM-DD") < moment(this.next_occurrence).format("YYYY-MM-DD") || (this.end_date > this.getEndDt(90) && moment(this.end_date).format("YYYY-MM-DD") > moment(this.next_occurrence).format("YYYY-MM-DD"))) {
                        if (this.getEndDate(90) > moment(this.next_occurrence).format("YYYY-MM-DD")) this.end_date = new Date(this.getEndDt(90));
                        var date = this.next_occurrence;
                        date.setDate(date.getDate());
                        this.end_date = new Date(date);
                    }
                }, 1500);
            }
        },
        handleMonthNumber() {
            if (this.absoluteMonthly == true && this.absoluteMonth.monthNumber !== "" && this.absoluteMonth.monthNumber <= 0) return true;
            else return false;
        },
        handleEveryMonth() {
            if (this.relativeMonthly == true && this.relativeMonth.everyMonth !== "" && this.relativeMonth.everyMonth <= 0) return true;
        },
        handleUpcomingYear() {
            if (this.recurYear !== "" && this.monthsInYear !== "" && this.absoluteYear.monthYear !== "" && this.recurYear > 0 && this.absoluteYear.dayMonth && this.absoluteYearly) {
                setTimeout(() => {
                    let endDate = new Date();
                    endDate.setFullYear(endDate.getFullYear() + 1);
                    let dateFormat = moment(this.startdate)
                        .format("YYYY-MM-DD")
                        .split("-")
                        .join("");
                    let rrule_obj = rrulestr(`DTSTART:${dateFormat} RRULE:FREQ=YEARLY;INTERVAL=${this.recurYear};BYMONTH=${this.monthsInYear.indexOf(this.absoluteYear.monthYear) + 1};BYMONTHDAY=${this.absoluteYear.dayMonth}`);
                    this.next_occurrence = rrule_obj.after(new Date());
                    if (moment(this.end_date).format("YYYY-MM-DD") < moment(this.next_occurrence).format("YYYY-MM-DD") || (this.end_date > endDate && moment(this.end_date).format("YYYY-MM-DD") > moment(this.next_occurrence).format("YYYY-MM-DD"))) {
                        if (endDate > moment(this.end_date).format("YYYY-MM-DD")) this.end_date = new Date(endDate);
                        else {
                            var date = this.next_occurrence;
                            date.setDate(date.getDate());
                            this.end_date = new Date(date);
                        }
                    }
                }, 1500);
            } else if (this.recurYear !== "" && this.relativeYear.week !== "" && this.relativeYear.yearDay !== "" && this.relativeYear.everyMonthYear && this.relativeYearly) {
                setTimeout(() => {
                    let endDate = new Date();
                    let dateFormat = moment(this.startdate)
                        .format("YYYY-MM-DD")
                        .split("-")
                        .join("");
                    let rrule_obj = rrulestr(`DTSTART:${dateFormat} RRULE:FREQ=YEARLY;INTERVAL=${this.recurYear};BYMONTH=${this.monthsInYear.indexOf(this.relativeYear.everyMonthYear) + 1};BYDAY=${this.getWeekIndex(this.relativeYear.week)}${this.getDayName(this.relativeYear.yearDay)}`);
                    this.next_occurrence = rrule_obj.after(new Date());
                    if (moment(this.end_date).format("YYYY-MM-DD") < moment(this.next_occurrence).format("YYYY-MM-DD") || (this.end_date > endDate && moment(this.end_date).format("YYYY-MM-DD") > moment(this.next_occurrence).format("YYYY-MM-DD"))) {
                        if (endDate > moment(this.end_date).format("YYYY-MM-DD")) this.end_date = new Date(endDate);
                        else {
                            var date = this.next_occurrence;
                            date.setDate(date.getDate());
                            this.end_date = new Date(date);
                        }
                    }
                }, 1500);
            }
            if (this.absoluteMonth.dayMonth !== "") {
                if ((this.absoluteMonth.monthYear === "January" || this.absoluteMonth.monthYear === "March" || this.absoluteMonth.monthYear === "May" || this.absoluteMonth.monthYear === "July" || this.absoluteMonth.monthYear === "August" || this.absoluteMonth.monthYear === "October" || this.absoluteMonth.monthYear === "December") && this.absoluteMonth.dayMonth <= 31) return true;
                else if ((this.absoluteMonth.monthYear === "June" || this.absoluteMonth.monthYear === "September" || this.absoluteMonth.monthYear === "November") && this.absoluteMonth.dayMonth <= 30) return true;
                else if (this.absoluteMonth.monthYear === "February" && this.absoluteMonth.dayMonth <= 29) return true;
                else return false;
            } else if (this.absoluteMonth.dayMonth == "") return true;
        },
        handleReviewMonthly(month) {
            const monthly = document.getElementById(month);
            if (month === "absmonthly" && monthly.checked) {
                this.absoluteMonthly = true;
                this.relativeMonthly = false;
            } else if (month === "relMonthly" && monthly.checked) {
                this.relativeMonthly = true;
                this.absoluteMonthly = false;
            }
        },

        handleFrequency(freq) {
            this.weekDaysSelected = false;

            this.frequencyType = freq;
            this.$nextTick(() => {
                if (freq == "daily") {
                    this.pattern = freq;
                    if (!this.everyWeekDay) {
                        this.everyDay = true;
                        document.getElementById("everyday").checked = true;
                        this.end_date = this.endBy ? this.getEndDate(90) : "";
                    }
                }
                if (freq == "weekly") {
                    this.pattern = freq;
                    this.noOfdays = 1;
                    this.end_date = this.getEndDate(90);
                }
                if (freq == "monthly") {
                    this.handleUpcomingDate();
                    if (!this.relativeMonthly) {
                        this.absoluteMonthly = true;
                        document.getElementById("absmonthly").checked = true;
                    }
                }
                if (freq == "yearly") {
                    if (this.relativeYearly == false) this.absoluteYearly = true;
                    let endDate = new Date();
                    endDate.setFullYear(endDate.getFullYear() + 1);
                    this.end_date = endDate;
                }
            });
        },
        handleAfterDay() {
            if (this.afterDays == "" || this.afterDays <= 0) {
                return true;
            }
        },

        async fetchAccounts() {
            var response = await axios.get("/api/v1/accounts/all");
            this.accounts = response.data;
            this.$store.commit("setUserAccounts", {accounts: this.accounts});
        },
        async fetchAccountUserDetails() {
            const accountId = this.$store.getters.getAccountId;
            const userId = this.$store.getters.getUserAuthDetails.userId;
            const url = `/api/v1/accounts/${accountId}/users/${userId}`;
            try {
                const response = await axios.get(url);
                let isAdmin = false;
                let access_all_cases = false;
                if (response.data.is_admin) {
                    isAdmin = true;
                }
                if (response.data.access_all_cases) {
                    access_all_cases = true;
                }
                this.$store.dispatch("setUserAdminStatus", {isAdmin: isAdmin});
                this.$store.dispatch("setUserAccessAlCases", {access_all_cases: access_all_cases});
            } catch (error) {
                console.error(error);
            }
        },
        async switchSocketIOConn() {
            const userId = this.$store.getters.getUserAuthDetails.userId;
            await this.createSocketIOConn();
            //SOCKET
            this.getSocketIO.on("connect", async () => {
                this.getSocketIO.emit("begin_conn", `${userId}`);
            });
            this.getSocketIO.on("switch", async (data) => {
                this.$router
                    .push({path: "/case-list"})
                    .then(() => this.$router.go())
                    .catch(() => this.$router.go());
            });
        },
        async fetchAccountDetails() {
            //Set original account id of logged in user.

            var accountId = VueCookies.get("ACCOUNT_ID");
            const response = await axios.get("/api/v1/accounts");

            if (accountId == null || accountId == undefined) {
                accountId = response.data.id;
            }
            this.accountName = response.data.name;
            this.$store.commit("setAccountId", {accountId: accountId});
            this.$store.commit("setAccountDetails", {accountDetails: response.data});
            this.$store.commit("setOnBoardingStatus", {status: response.data.onboarding_done});
        },

        close() {
            this.review_date = this.getCasePersonalData?.upcoming_review_date ? new Date(this.getCasePersonalData.upcoming_review_date) : null;
            this.$store.commit("SET_CASE_REVIEW_DATE_POPUP", false);
        },

        closeDueDatePopUp() {
            this.due_date = this.getCasePersonalData?.case_due_date ? new Date(this.getCasePersonalData.case_due_date) : null;
            this.$store.commit("SET_CASE_DUE_DATE_POPUP", false);
        },

        disabledRange(date) {
            return new Date(date).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0);
        },

        disabledDueDateRange(date) {
            let disabledate = this.getCasePersonalData?.case_due_date ? new Date(this.getCasePersonalData.case_due_date) : new Date();
            if (this.getCasePersonalData?.case_due_date) return date < disabledate;
            else return new Date(date).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0);
        },

        updateReviewDate() {
            this.loading = true;
            if (this.review_date) {
                this.$store.dispatch("setCaseReviewDate", this.review_date);
                this.loading = false;
            } else {
                this.$toast.error("Please select Upcoming Review Date");
                this.loading = false;
            }
        },

        updateDueDate() {
            this.loading = true;
            if (this.due_date) {
                this.$store.dispatch("setCaseDueDate", this.due_date);
                this.loading = false;
            } else {
                this.$toast.error("Please select Upcoming Due Date");
                this.loading = false;
            }
        },
        resetReviewFrequency() {
            this.frequencyType = "daily";
            (this.frequency = [
                {id: 1, checked: true, type: "daily"},
                {id: 2, checked: false, type: "weekly"},
                {id: 3, checked: false, type: "monthly"},
                {id: 4, checked: false, type: "yearly"},
            ]),
                (this.everyDay = true);
            this.everyWeekDay = "";
            this.enddateAfter = "";
            this.weekday = "";
            this.months = "";
            this.noEndDate = false;
            this.endAfter = false;
            this.endBy = true;
            this.occurrences = "";
            this.start_date = new Date();
            this.end_date = this.getEndDate(90);
            this.relativeYear.week = this.getWeekOfMonth();
            this.relativeYear.everyMonthYear = this.getMonthNameFull();
            this.absoluteYear.monthYear = this.getMonthNameFull();
            this.recurYear = 1;
            this.absoluteYear.dayMonth = new Date().getDate().toString();
            this.absoluteMonth.dayNumber = new Date().getDate().toString();
            this.absoluteMonth.monthNumber = 1;
            this.relativeMonth.weeklyRepeat = this.getWeekOfMonth();
            this.relativeYear.yearDay = this.getDayNameFull();
            this.relativeMonth.dayRepeat = this.getDayNameFull();
            this.relativeMonth.everyMonth = 1;
            this.afterDays = 1;
            this.noOfdays = 1;
            this.absoluteMonthly = true;
            this.relativeMonthly = false;
            this.absoluteYearly = true;
            this.relativeYearly = false;
            this.dayOfMonth = "";
            this.daysOfWeek = [];
            this.daysInWeek.find((el) => (el.checked = false));
            this.weekDaysSelected = false;
            this.getEndDate(90);
        },

        async initializeData() {
            await this.fetchAccountDetails();
            await this.fetchAccounts();
            await this.fetchPermissions();
            // await this.getCaseListReadOnly();
            await this.fetchAccountUserDetails();
            this.fetchingAccountFinished = true;
        },
    },
};
