<template>
    <div class="tw-m-4 tw-w-full" v-if="getDirectIpLogin">
        <div class="tw-flex tw-justify-end">
            <div class="input drop-item tw-text-base tw-shadow-md tw-rounded-md" v-on-click-outside="handleOutside" style="width: 250px; background-color: white; height: 30px">
                <div class="dropdown">
                    <button class="tw-flex tw-w-full tw-justify-between" @click="moveCategories">
                        <span class="tw-flex tw-items-center">
                            <p class="tw-flex tw-w-full tw-self-center" style="font-size: 14px; color: #9a9da9; font-weight: 500">
                                <span class="tw-mr-4"> Move Categories </span>
                            </p>
                        </span>
                        <font-awesome-icon :icon="open ? 'angle-up' : 'angle-down'" class="tw-self-center" size="2x" color="#9a9da9" />
                    </button>
                </div>
                <div
                    class="dropdown-content"
                    v-if="open"
                    :class="open ? 'tw-shadow-md' : ''"
                    style="boxShadow: open ? 'var(--component-elevation-on-focus)' : '';border-bottom: 1px solid rgba(0, 0, 0, .10025);border-bottom-right-radius: 0.8rem;
    border-bottom-left-radius: 0.8rem; "
                >
                    <div>
                        <draggable class="list-group" v-bind="dragOptions" tag="ul" v-model="list" @start="drag = true" @end="drag = false">
                            <transition-group type="transition" :name="!drag ? 'flip-list' : null">
                                <li class="tw-flex list-group-item tw-cursor-pointer tw-flex-wrap" v-for="(element, element_index) in list" v-bind:class="{'list-group-item-first': element_index === 0, 'list-group-item-last': list.length === element_index + 1}" :key="element.order">
                                    <i :class="element.fixed ? 'fa fa-anchor' : 'glyphicon glyphicon-pushpin'" @click="element.fixed = !element.fixed" aria-hidden="true"></i>
                                    <div class="draggable-content tw-flex tw-w-full tw-justify-between">
                                        <span class="tw-flex tw-items-center tw-overflow-hidden">
                                            {{ element.category_name }}
                                        </span>
                                        <font-awesome-icon icon="bars" class="tw-self-center"> </font-awesome-icon>
                                    </div>
                                </li>
                            </transition-group>
                        </draggable>
                    </div>
                </div>
            </div>
        </div>
        <div class="tw-w-full tw-h-full tw-bg-brand-area-color">
            <!-- Search Filters -->
            <search-bar v-model.trim="searchFilter" />

            <!-- querys List -->
            <neo-table :list="list" :headers="headers" :searchFilter="searchFilter" :isLoading="loader" @selected="goToQuery($event)" @checked="check" @update:page="changePage($event)" @update:list="dragHandler($event)" expandable :draggable="{general: true, sub: 'section_id'}" :sublistProp="sublistProp" :sublistHeaders="sublistHeaders" />
        </div>
    </div>
    <error-message v-else-if="!getDirectIpLogin" />
</template>

<script>
import Table from "@/components/table-expandable";
import {mapGetters, mapActions} from "vuex";
import {EventBus} from "@/main.js";
import SearchBar from "@/components/searchbar";
import draggable from "vuedraggable";
const ErrorMessage = () => import("@/components/server-error-message");
export default {
    name: "output-template",
    components: {
        SearchBar,
        "neo-table": Table,
        draggable,
        ErrorMessage,
    },
    props: [],
    data() {
        return {
            list: [],
            headers: [
                {
                    text: "Category",
                    value: "category_name",
                    class: "tw-w-1/4",
                    search: true,
                },
                {
                    text: "Description",
                    value: "category_details",
                    class: "tw-w-3/5 tw-flex-grow",
                },
            ],
            sublistProp: "sections",
            pageCount: 0,
            sublistHeaders: [
                {
                    value: "active",
                    type: "toggle",
                },
                {
                    value: "section_name",
                    search: true,
                },
                {
                    value: "details",
                },
            ],
            searchFilter: "",
            loader: false,
            drag: false,
            open: false,
            disable: false,
        };
    },
    async mounted() {
        this.loader = true;
        await this.initPage();
        this.loader = false;
    },
    computed: {
        ...mapGetters(["getProduct", "getAccountId", "getDirectIpLogin", "getCompanyProducts"]),
        product() {
            return this.getProduct.key ? this.getProduct : this.getCompanyProducts.find((el) => el.name === this.$route.query.product);
        },
        API() {
            return {
                get: this.getOutputTemplateProductCategories,
                toggle: this.toggleOutputTemplateProductSection,
                drag: this.swapOutputTemplateProductCategories,
                dragSection: this.swapOutputTemplateProductSections,
            };
        },
        dragOptions() {
            return {
                animation: 200,
                group: "description",
                disabled: false,
                ghostClass: "ghost",
            };
        },
    },
    methods: {
        ...mapActions("outputTemplates", ["getOutputTemplateProductCategories", "toggleOutputTemplateProductSection", "swapOutputTemplateProductCategories", "swapOutputTemplateProductSections"]),
        async initPage({page = 1} = {}) {
            this.loader = true;
            try {
                console.log(JSON.stringify(this.product.key), "JSON.stringify(this.product.key)");
                // const response = await this.API.get({
                //     current_page: page,
                //     product_id: this.product.key
                // });
                const response = await this.API.get(this.product.key);
                this.list = response.map((el, index) => ({
                    // TODO
                    ...el,
                    id: el.category_id,
                    order: index,
                }));
                this.list = this.list.filter((l) => l.category_name !== "Others");
                // this.pageCount = response.pagination.total_pages;
            } catch (error) {
                this.$toast.error(error.message || "Something went wrong");
            }
            this.loader = false;
        },
        async check({parent, item, value}) {
            const idParent = parent.id;
            const id = item.section_id;
            try {
                EventBus.$emit("topprogress", "start");
                const elToChangeParent = this.list.find((el, index) => el.id === idParent);
                const elToChange = elToChangeParent[this.sublistProp].find((el, index) => el.section_id === id);
                await this.API.toggle({
                    category_id: parent.category_id,
                    section_id: item.section_id,
                    status: value,
                    product_pack_id: this.product.key,
                });
                elToChange.active = value;
            } catch (error) {
                this.$toast.error(error.message || "Something went wrong");
            }
            EventBus.$emit("topprogress", "done");
        },
        async dragHandler(payload) {
            try {
                EventBus.$emit("topprogress", "start");
                if (payload.type === "sub") {
                    this.list[payload.parent.order].sections = payload.list;
                    await this.API.dragSection({
                        positions: payload.list.map((el) => ({
                            section_id: el.section_id || el._id,
                            position: el.order,
                        })),
                        product_pack_id: this.product.key,
                        category_id: payload.parent.category_id,
                    });
                } else {
                    this.list = payload.list;
                    await this.API.drag({
                        positions: payload.list.map((el) => ({
                            category_id: el.category_id || el._id,
                            position: el.order,
                        })),
                        product_pack_id: this.product.key,
                    });
                }
            } catch (error) {
                this.$toast.error(error.message || "Something went wrong");
            }
            EventBus.$emit("topprogress", "done");
        },
        async changePage(page) {
            // await this.initPage({page});
        },
        handleOutside() {
            this.open = false;
        },
        moveCategories() {
            if (this.open == false) this.open = true;
            else this.open = false;
        },
    },
};
</script>

<style src="@/components/dropdownMenuBtn/dropdownMenuBtn.scss" lang="scss"></style>
<style lang="scss">
.list-group {
    border: 1px solid rgba(0, 0, 0, 0.10025);
    border-radius: 0.5rem;
}
.list-group-item {
    padding: 0.5rem;
    border: none;
    margin-top: 5px;

    &:not(:first-child) {
        border-top: 1px solid rgba(0, 0, 0, 0.10025);
    }
    &:hover {
        background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
    }
}
</style>
