<template>
    <div class="tw-p-8 tw-bg-brand-area-color" v-if="getDirectIpLogin">
        <!-- Search Filters -->
        <search-bar v-model.trim="searchFilter" />

        <!-- list -->
        <neo-table :list="list" :headers="headers" :sublistProp="sublistProp" :sublistHeaders="sublistHeaders" :searchFilter="searchFilter" :searchFilterKey="searchFilterKey" :isLoading="loader" expandable asyncPages :pageCount="pageCount" @update:page="changePage($event)" />
    </div>
    <error-message v-else-if="!getDirectIpLogin" />
</template>

<script>
import SearchBar from "@/components/searchbar";
import Table from "@/components/table-expandable";
import {mapGetters, mapActions, mapMutations} from "vuex";
import titleMixin from "@/mixins/titleMixin";
import {debounce} from "lodash";
const ErrorMessage = () => import("@/components/server-error-message");

export default {
    title() {
        return `Search Engine`;
    },
    mixins: [titleMixin],
    name: "search-engine-queries",
    components: {
        "neo-table": Table,
        SearchBar,
        ErrorMessage,
    },
    data() {
        return {
            list: [],
            searchFilterKey: "category_name",
            headers: [
                {
                    text: "Category",
                    value: "category_name",
                    class: "tw-w-1/5",
                },
                {
                    class: "tw-flex-grow",
                },
                // {
                //     text: "Status",
                //     value: "status",
                //     class: 'tw-w-1/5',
                // },
                // {
                //     text: "Description",
                //     value: "description",
                //     class: 'tw-w-3/5',
                // }
            ],
            sublistProp: "queries",
            pageCount: 0,
            sublistHeaders: [
                {
                    value: "label",
                    class: "tw-flex-grow",
                },
                // {},
                // {
                //     value: "status",
                // },
                // {
                //     value: "description",
                // }
            ],
            searchFilter: "",
            loader: false,
        };
    },
    created() {
        this.RESET_ADMIN_SEARCH_ENGINE_QUERIES();
    },
    beforeDestroy() {
        this.RESET_ADMIN_SEARCH_ENGINE_QUERIES();
    },
    async mounted() {
        await this.initPage();
    },
    computed: {
        ...mapGetters(["getAccountId", "getDirectIpLogin"]),
        API() {
            return {
                get: this.getAdminSearchEngineCategories,
            };
        },
    },
    watch: {
        searchFilter: debounce(async function({page = 1} = {}) {
            this.loader = true;
            try {
                const response = await this.API.get({
                    current_page: page,
                    category_name: this.searchFilter ? this.searchFilter : "",
                    // company_id: this.getAccountId, // ? uncomment?
                });
                this.list = response.data.map((el, index) => ({
                    ...el,
                    id: el._id,
                    // [this.sublistProp]: [],
                    order: index,
                }));
                this.pageCount = response.pagination.total_pages;
            } catch (error) {
                this.$toast.error(error.message || "Something went wrong");
            }
            this.loader = false;
        }, 1000),
    },
    methods: {
        ...mapActions(["getAdminSearchEngineCategories"]),
        ...mapMutations(["RESET_ADMIN_SEARCH_ENGINE_QUERIES"]),
        wait(ms) {
            return new Promise((resolve) => setTimeout(resolve, ms));
        },
        async initPage({page = 1} = {}) {
            this.loader = true;
            try {
                const response = await this.API.get({
                    current_page: page,
                    // company_id: this.getAccountId, // ? uncomment?
                });
                this.list = response.data.map((el, index) => ({
                    ...el,
                    id: el._id,
                    // [this.sublistProp]: [],
                    order: index,
                }));
                this.pageCount = response.pagination.total_pages;
            } catch (error) {
                this.$toast.error(error.message || "Something went wrong");
            }
            this.loader = false;
        },
        // getSearchDetails: debounce(async function({ page = 1 } = {}) {
        //     this.loader = true;
        //     console.log("Response debounce");
        //     try {
        //         const response = await this.API.get({
        //             current_page: page,
        //             category_name: this.searchFilter ? this.searchFilter : "",
        //             // company_id: this.getAccountId, // ? uncomment?
        //         });
        //         this.list = response.data.map((el, index) => ({
        //             ...el,
        //             id: el._id,
        //             // [this.sublistProp]: [],
        //             order: index,
        //         }));
        //         this.pageCount = response.pagination.total_pages;
        //     } catch (error) {
        //         this.$toast.error(error.message || "Something went wrong");
        //     }
        //     this.loader = false;
        // }, 2000),
        async changePage(page) {
            await this.initPage({page});
        },
        // * HARDCODED DATA for testing
        async fetchEngineQueries() {
            const statuses = ["Active", "Inactive", "Other"];
            const checked = [true, false];
            const results = [];
            new Array(11).fill({}).forEach((el, i) => {
                results.push({
                    id: i,
                    category: `Category name ${i + 1}`,
                    checked: checked[i % 2],
                    queries:
                        i % 3
                            ? [
                                  {
                                      name: `Query name ${i + 1}.1`,
                                      status: statuses[i % 3],
                                      description: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis, quod.`,
                                  },
                                  {
                                      name: `Query name ${i + 1}.2`,
                                      status: statuses[(i + 1) % 3],
                                      description: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis, quod.`,
                                  },
                              ]
                            : [],
                });
            });
            const response = await new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve({list: results});
                }, 1000);
            });
            return response;
        },
    },
};
</script>

<style scoped lang="scss"></style>
