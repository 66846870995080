import Vue from "vue";
import {camelToSnake, generateRandomString} from "@/utils/functions";
import {flattenDeep, get, isEqual, uniqBy, without, uniq, pick, mapKeys, sortBy, cloneDeep} from "lodash";
import _ from "underscore";

import {RESEARCH_DATA} from "@/test/research-data";
import {RESEARCH_TABS, ACTIVE_PASSIVE_MONITORING_TYPE_FILTER, VALID_TAB_LOCATIONS, REMOVED_ENTITIES, TOOLS_STATUS_INTERVAL, FEATURES} from "@/utils/constants";
import axios from "@/axios";
import rawAxios from "axios";
import {dashedCase} from "../utils/functions";

import {tabFetcherMap, getStaticData, getFilterParamsData, getHistoryData, fetchNotesData, fetchCaseUsersData, fetchLastReviewed} from "@/services/research.js";
import {getOsintSources} from "@/services/osint-sources";
import osintAxios from "@/axios/osint.js";
import {DateTime} from "luxon";
import {io} from "socket.io-client";
import userAxios from "@/axios/user.js";
import whatsAppServices from "../views/research/tools/whatsapp/services";

import router from "@/router";
import store from "@/store";
import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
import {splitCountryCode} from "@/utils/functions";
import whatsappServices from "@/views/research/tools/whatsapp/services.js";
import {tryCatch} from "rxjs/internal-compatibility";
const options = {
    // You can set your default options here
};

Vue.use(Toast, options);

const allowedSearches = {
    personalDetails: ["field", "value", "email", "status", "entity", "notes", "customer_notes"],
    socialProfiles: ["username", "platform", "status", "categories", "notes", "customer_notes"],
    searchEngineResults: ["title", "displayed_link", "search_source.source", "snippet", "status", "notes", "customer_notes"],
    associatedPeople: ["name", "relation", "designation", "status", "entity", "notes", "customer_notes"],
    greenContent: ["value", "type", "status", "notes", "customer_notes"],
    websites: ["website", "title", "status", "notes", "customer_notes"],
    media: ["platform", "image_caption", "status", "url", "notes", "customer_notes", "raw_page_text"],
    darkWeb: ["source", "email", "breach_data", "status", "Title", "notes", "customer_notes"],
    keyFacts: ["name", "relation", "key_fact_name", "categories", "notes", "customer_notes"],
};

function assignEntityId(items, tab) {
    return items.map((item) => {
        return {
            ...item,
            _id: item._id ? item._id : generateRandomString(),
            tab,
        };
    });
}

function getActiveTab(tab) {
    const whitelist = ["socialProfiles", "websites", "searchEngineResults"].indexOf(tab) > -1;

    return `neo-${whitelist ? dashedCase(tab.replace(/.$/, "")) : dashedCase(tab)}`;
}

function getFormattedTime(ts) {
    if (ts) {
        let date = new Date(ts).toISOString();
        date = DateTime.fromISO(date);
        return date.toFormat("dd MMM yyyy");
    }
    return "-";
}

export default {
    state: () => ({
        entityData: {entities: [], connections: [], main_node: []},
        data: {},
        errors: [],
        toolsFilterData: {
            monitoring: "all",
            saved: "all",
        },
        nerFilters: null,
        researchData: {
            socialProfiles: [],
            personalDetails: [],
            searchEngineResults: [],
            associatedPeople: [],
            media: [],
            greenContent: [],
            websites: [],
            darkWeb: [],
            keyFacts: [],
        },
        socialPlatforms: [],
        filteredData: {
            socialProfiles: [],
            personalDetails: [],
            searchEngineResults: [],
            associatedPeople: [],
            media: [],
            greenContent: [],
            websites: [],
            darkWeb: [],
            keyFacts: [],
        },
        filteredIrreleventData: {
            socialProfiles: [],
            personalDetails: [],
            gdc: [],
            searchEngineResults: [],
            associatedPeople: [],
            media: [],
            greenContent: [],
            websites: [],
            keyFacts: [],
        },
        confirmedData: RESEARCH_TABS.map((rTab) => rTab.key).reduce((map, tab) => ({...map, [tab]: []}), {}),
        irrelevantData: RESEARCH_TABS.map((rTab) => rTab.key).reduce((map, tab) => ({...map, [tab]: []}), {}),
        // reportedContent: [],
        extensionID: "nefamhkgmaajipdpeoddkaapnedoaeag",
        externalExtensionID: "ibkdonbedimkodmialpkbfoaoabchlkk",
        entityMapperData: [],
        casePersonalData: {},
        companyProducts: [],
        iconsData: [],
        entitiesRelationsAttributesData: {attributes: [], entities: [], relations: []},
        profileData: [],
        matchingProfile: {data: []},
        caseData: {extra_content: []},
        matchingCaseData: {},
        filtersApplied: {},
        product: {},
        case_id: "",
        activePassiveData: [],
        hibpData: [],
        historyData: {},
        caseResearchTabs: [],
        caseResearchData: {},
        notesData: [],
        profileFlags: [],
        extraContent: [],
        readOnlyMode: false,
        validCase: false,
        userDetails: {
            displayName: "",
            email: "",
            phone: "",
            type: "",
        },
        education_employment_data: {},
        searchFilters: {},
        searchStatusFilter: [],
        personalDetailsTabs: [],
        osintSourcesData: [],
        riskCategories: [],
        rawRiskCategories: [],
        tabPaginations: {
            searchEngineResults: {
                currentPage: 1,
                pageSize: 20,
            },
        },
        invalidCaseId: null,
        caseUsers: [],
        allUsers: [],
        allCasePermissions: [],
        userDocIds: [],
        allUsernameData: [],
        lastReviewed: {
            user: "",
            timestamp: "",
            role: "",
        },
        authDetails: {
            userId: "",
            email: "",
            username: "",
            userRole: "",
        },
        allToolsData: [],
        allCaseStates: [],
        allCaseUsernames: [],
        allCaseEmails: [],
        currentAnalysisToolsTab: null,
        toolsData: null,
        allToolNames: [],
        searchedQueries: [],
        overallToolsStatus: true,
        queriesLoading: false,
        toolsMonitoringEvents: null,
        overallMonitoringEvents: null,
        queryMonitoringEvents: null,
        monitoringTools: {},
        productMonitoring: [],
        counterInterval: null,
        intervalSecs: 0,
        buildQueries: false,
        isEmptyConnection: false,
        allMonitoringData: [],
        monitoringData: null,
        appConfig: {mainEntity: null},
        socketIO: null,
        toolFieldsMapping: {
            "company-name": ["companyUsers", "nameList"],
            "company-domain": ["domainList", "companyUsers"],
            "name-search-tool": ["nameList"],
            "domain-search-tool": ["registrantUsers", "domainList", "emailList", "nameList"],
            "dark-web-tool": ["usernameList", "phoneList", "emailList", "domainList", "nameList"],
            "github-tool": ["emailList", "usernameList"],
            "tiktok-tool": ["usernameList"],
            "gitlab-tool": ["emailList"],
            "google-tool": ["emailList", "phoneList"],
            "linkedin-tool": ["emailList", "domainList", "nameList", "urlList"],
            // "linkedin-tool": ["emailList"],
            "facebook-tool": ["emailList", "phoneList", "urlList", "nameList"],
            "skype-tool": ["emailList", "usernameList", "nameList", "phoneList"],
            "twitter-tool": ["usernameList"],
            "instagram-tool": ["usernameList"],
            "username-tool": ["usernameList"],
            "flickr-tool": ["emailList"],
            "adverse-media-tool": ["nameList", "companyUsers"],
            "offshoreleaks-tool": ["nameList", "companyUsers"],
            "lexis-bridger-tool": ["nameList", "companyUsers"],
            "court-records-tool": ["nameList"],
            "risk-tool": ["nameList"],
            "email-search-tool": ["emailList"],
            "person-identifier-tool": ["emailList", "usernameList", "phoneList", "nameList"],
            "gdc-tool": ["emailList", "phoneList", "nameList"],
            "email-reputation-tool": ["emailList"],
            "search-engine-tool": ["emailList", "usernameList", "phoneList", "locationList", "educationList", "employmentList", "hobbyList", "companyUsers", "domainList", "nameList"],
            "social-search-tool": ["nameList"],
            "news-tool": ["nameList", "companyUsers"],
            "partial-domain-search-tool": ["domainList"],
        },
        toolPlatforms: {
            "company-name": "company-identifier",
            "company-domain": "domain",
            "domain-search-tool": "domain-search",
            "name-search-tool": "name-search",
            "dark-web-tool": "dark-web",
            "github-tool": "github",
            "gitlab-tool": "gitlab",
            "google-tool": "google",
            "linkedin-tool": "linkedin",
            "skype-tool": "skype",
            "twitter-tool": "twitter",
            "instagram-tool": "instagram",
            "username-tool": "username",
            "flickr-tool": "flickr",
            "facebook-tool": "facebook",
            "adverse-media-tool": "adverse-media",
            "offshoreleaks-tool": "offshore-leaks",
            "lexis-bridger-tool": "lexis-bridger",
            "court-records-tool": "court-records",
            "risk-tool": "adverse-media",
            "tiktok-tool": "tiktok",
            "email-search-tool": "email-search",
            "person-identifier-tool": "person-identifier",
            "email-reputation-tool": "email_reputation",
            "search-engine-tool": "search-engine",
            "news-tool": "news",
            "gdc-tool": "gdc",
            "social-search-tool": "social-search",
            "partial-domain-search-tool": "partial-domain-search",
        },
        entitiesSearchQuery: "",
        permissions: [
            // "search_engine__search_engine", "flickr__social_media", "factiva__news", "bvd__corp_record", "world_check__adverse_pep_sacntion", "linkedin__social_media", "search_engine__monitoring", "social_profiles__case_data", "social_media__monitoring", "reports_related_features__case_data", "history___notes__case_data", "case_settings__case_data"
        ],
        permissionsFetched: false,
        licenses: [],
        caseDataLevel: "",
        productFeatures: null,
        caseDescription: "",
        isReviewDatePopup: false,
        isDueDatePopup: false,
        caseStatusFlag: false,
        selectedKeywordsData: {
            keywords: {},
            nearCount: null,
        },
        searchWords: [],
        caseValid: true,
        selectedToolQuery: {},
        selectedMonitoringQuery: {},
        monitoringDateFilter: {},
        caseCreatedAt: "",
        caseFreezeDate: "",
        isCaseListReadOnly: false,
        isUserManagedCase: true,
        case_staus: "In Progress",
        selectedToolsUsers: [],
        caseEntityInfo: null,
        outputTemplates: [],
        directIpLogin: false,
    }),
    mutations: {
        SET_AUTH_DETAILS(state, payload) {
            state.authDetails.userId = payload.userId;
            state.authDetails.username = payload.username;
            state.authDetails.email = payload.email;
            state.authDetails.userRole = payload.userRole;
        },
        SET_CASE_DESCRIPTION(state, payload) {
            state.caseDescription = payload;
        },
        SET_CASE_CREATED_AT(state, payload) {
            state.caseCreatedAt = payload;
        },
        SET_CASE_FREEZE_DATE(state, payload) {
            state.caseFreezeDate = payload;
        },
        SET_CASE_PERSONAL_DATA_REVIEW_DATE(state, payload) {
            state.casePersonalData.upcoming_review_date = payload;
        },
        SET_CASE_PERSONAL_DATA_DUE_DATE(state, payload) {
            state.casePersonalData.case_due_date = payload;
        },
        SET_CASE_REVIEW_DATE_POPUP(state, payload) {
            state.isReviewDatePopup = payload;
        },
        SET_CASE_DUE_DATE_POPUP(state, payload) {
            state.isDueDatePopup = payload;
        },
        SET_CASE_STATUS(state, payload) {
            state.casePersonalData.case_state = payload;
        },
        SET_CASE_STATUS_FLAG(state, payload) {
            state.caseStatusFlag = payload;
        },
        SET_TOOLS_MONITORING_EVENTS(state, payload) {
            state.toolsMonitoringEvents = payload;
        },
        SET_OVERALL_MONITORING_EVENTS(state, payload) {
            state.overallMonitoringEvents = payload;
        },
        SET_QUERY_MONITORING_EVENTS(state, payload) {
            state.queryMonitoringEvents = payload;
        },
        SET_MONITORING_TOOLS(state, payload) {
            state.monitoringTools = payload;
        },
        SET_PRODUCT_MONITORING(state, payload) {
            state.productMonitoring = payload;
        },
        SET_PERSONAL_DETAILS_TABS(state, payload) {
            state.personalDetailsTabs = payload;
        },
        SET_READ_ONLY(state, payload) {
            state.readOnlyMode = payload;
        },
        SET_SEARCH_STATUS_FILTER(state, payload) {
            state.searchStatusFilter = payload;
        },
        SET_SEARCH_FILTERS(state, payload) {
            Object.keys(payload).forEach((key) => {
                payload[key] = payload[key].map((e) => {
                    return {data: e, active: false};
                });
            });
            state.searchFilters = payload;
        },
        SET_ACTIVE_PASSIVE_DATA(state, payload) {
            // state.researchData.media = payload;
            state.researchData.media = payload.filter((item) => (state.readOnlyMode ? item.media_type !== "search" && item.media_type !== "url" : item.media_type !== "Search"));
            // state.researchData.media = payload.filter((item) => item.media_type == "url");
        },
        SET_USER_DETAILS(state, payload) {
            state.userDetails = payload;
            // state.userDetails.displayName = payload.data.displayName
            // state.userDetails.email = payload.data.email
            // state.userDetails.phone = payload.data.mobile
            // state.user
        },
        SET_EXTRA_CONTENT(state, payload) {
            state.caseData.extra_content.push(payload);
        },
        SET_PROFILE_FLAGS(state, payload) {
            state.profileFlags = state.profileFlags.concat(payload);
        },
        SET_HIBP_DATA(state, payload) {
            state.hibpData = state.hibpData.concat(payload);
        },
        SET_HISTORY_DATA(state, payload) {
            state.historyData = payload;
        },
        SET_RESEARCH_TABS(state, payload) {
            state.caseResearchTabs = payload;
        },
        SET_RESEARCH_DATA(state, payload) {
            state.caseResearchData = {
                ...state.caseResearchData,
                [payload.tab]:
                    payload.tab === "socialProfiles"
                        ? payload.data.map((val) => ({
                              ...val,
                              ...{username: val.entity},
                          }))
                        : payload.data,
            };
        },
        SET_NOTES_DATA(state, payload) {
            state.notesData = payload;
        },
        SET_EDUCATION_EMPLOYMENT_DATA(state, payload) {
            state.education_employment_data = payload;
        },
        SET_SOCIAL_PLATFORMS(state, payload) {
            state.socialPlatforms = payload;
        },
        SET_PRODUCT(state, payload) {
            state.product = payload;
        },
        SET_CASE_ID(state, payload) {
            state.case_id = payload;
        },
        SET_ENTITY_MAPPER_DATA(state, payload) {
            state.entityMapperData = payload;
        },
        SET_ICONS_DATA(state, payload) {
            state.iconsData = payload;
        },
        SET_SEARCH_DATA(state, payload) {
            state.searchData = payload.google_search_data;
            state.socialPlatforms = payload;
        },
        SET_COMPANY_PRODUCTS(state, payload) {
            state.companyProducts = uniqBy(payload.final_products, "key") || [];
        },
        SET_CASE_PERSONAL_DATA(state, payload) {
            state.casePersonalData = payload;
            if (payload.case_type == "CS") {
                state.userDetails.displayName = payload.data["Full Name"];
                state.userDetails.email = payload.data["Personal Email Address"];
                state.userDetails.phone = payload.data["Mobile Number"];
            } else {
                state.userDetails.displayName = payload.data.full_name;
                state.userDetails.email = "emails" in payload.data ? payload.data.emails.join(", ") : "";
                state.userDetails.phone = "mobile" in payload.data ? payload.data.mobile.join(", ") : "";
            }
            if ("job_update" in payload) {
                state.casePersonalData.job_update.timestamp = DateTime.fromISO(payload.job_update.timestamp).toISODate();
            }
            state.userDetails.type = "case_subtype" in payload ? payload.case_subtype : "person";

            if (state.casePersonalData?.data) {
                const product = state.companyProducts.find((prod) => prod.name === state.casePersonalData?.data["Due Diligence Level"]?.value);
                state.product = product;
            }
        },
        SET_CASE_DATA(state, payload) {
            state.caseData = payload;
        },
        ADD_SOCIAL_PLATFORM_DATA(state, payload) {
            payload.forEach((platformData) => {
                let matchingProfile = state.profileData.find((elem) => elem.platform == platformData.platform && elem.username == platformData.username);
                if (!matchingProfile) state.profileData.push(platformData);
            });
        },
        UPDATE_RESEARCH_DATA(state, payload) {
            state.researchData[payload.tab] = payload.data;
        },
        SET_DARKWEB_DATA(state, payload) {
            state.researchData.darkWeb = payload;
        },
        SET_KEY_FACTS(state, payload) {
            state.researchData.keyFacts = payload;
        },
        SET_RESEARCH_SEARCH_DATA(state, payload) {
            state.researchData.searchEngineResults = payload;
        },
        UPDATE_EXTRA_CONTENT(state, payload) {
            let obj = state.caseData.extra_content.find((e) => e._id === payload.content._id);
            obj.categories = "categories" in payload ? payload.categories : obj.categories;
            obj.deleted = "deleted" in payload ? payload.deleted : obj.deleted;
            obj.screenshot = "screenshot" in payload ? payload.screenshot : obj.screenshot;
        },
        PUSH_API_ERRORS(state, payload) {
            state.errors.push(payload);
        },
        UPDATE_CARD(state, payload) {
            let item;
            if (router.app?._route?.name === "research-automated" && router.app?._route?.params.casename !== "keyFacts") item = state.caseResearchData?.[payload.tab]?.find((card) => payload.card._id === card._id);
            else item = state.researchData?.[payload.tab]?.find((card) => payload.card._id === card._id);
            if (item) Object.assign(item, payload.changes);
        },
        UPDATE_BY_ID(state, payload) {
            if (payload) {
                let match;
                if (router.app?._route?.name === "research-automated" && router.app?._route?.params.casename !== "keyFacts") match = state.caseResearchData[payload.tab].find((item) => item._id === payload.id);
                else match = state.researchData[payload.tab].find((item) => item._id === payload.id);
                const tag = match.categories.findIndex((item) => item.tag === payload.tag);
                match.categories.splice(payload.tag, 1);
            }
        },
        UPDATE_POST(state, payload) {
            const match = state.matchingProfile.data.find((post) => payload.post._id === post._id);

            Object.assign(match, {
                ...match,
                ...payload.changes,
            });
        },
        DELETE_POST(state, payload) {
            state.matchingProfile.data = state.matchingProfile.data.filter((item) => !isEqual(item, payload));
        },

        FILTER_DATA(state, payloadData) {
            // var payload = payloadData.payload;
            var flags = payloadData.flags;
            let filteredData = {};
            let data = state.researchData;
            if (router.app?._route?.name === "research-automated" && router.app?._route?.params.casename !== "keyFacts") {
                data = state.caseResearchData;
                for (const currentTab of Object.keys(data)) {
                    filteredData[currentTab] = data[currentTab];

                    if (state.filteredData && !state.filteredData[currentTab]) state.filteredData = {...state.filteredData, [currentTab]: []};

                    state.filteredData[currentTab] = uniq(filteredData[currentTab]);
                }
            } else {
                for (const currentTab of Object.keys(data)) {
                    filteredData[currentTab] =
                        data[currentTab] &&
                        data[currentTab].filter((data) => {
                            return Object.entries(state.filtersApplied).every(([control, value]) => {
                                if (control === "status") {
                                    value = value.map((val) => val.toLowerCase());
                                    if (!value?.length) return data;
                                    else {
                                        if (value.includes("none")) return data.status == null || !data.status || value.includes(data.status?.toLowerCase());
                                        else return value.includes(data.status?.toLowerCase());
                                    }
                                } else if (control === "identifiersCount") {
                                    value = value.map((val) => val.value);
                                    if (!value?.length) return data;
                                    else {
                                        if (value.includes(6)) return data.identifiers?.length >= 6 || value.includes(data.identifiers?.length);
                                        else return value.includes(data.identifiers?.length);
                                    }
                                } else if (control === "confidenceScore") {
                                    value = value.map((val) => val.toLowerCase());
                                    if (!value?.length) return data;
                                    else {
                                        return value.includes(data.confidence_score?.name?.toLowerCase());
                                    }
                                } else if (control === "identifiersMatched") {
                                    if (!value?.length) return data;
                                    else {
                                        let identifiers = [];
                                        for (let index in data.identifiers) {
                                            delete data.identifiers[index]?.platform;
                                            identifiers = identifiers.concat(Object.values(data.identifiers[index]));
                                        }
                                        identifiers = uniq(identifiers.filter((el) => el));

                                        return value.some((val) => identifiers.includes(val));
                                    }
                                } else if (control === "identifiersInputs") {
                                    if (!value?.length) return data;
                                    else {
                                        let identifiers = [];
                                        for (let index in data.identifier_inputs) {
                                            identifiers = identifiers.concat(Object.values(data.identifier_inputs[index]));
                                        }
                                        identifiers = uniq(identifiers.filter((el) => el));
                                        return value.some((val) => identifiers.includes(val));
                                    }
                                } else if (control === "identifiersTools") {
                                    if (!value?.length) return data;
                                    else {
                                        let identifiers = [];
                                        for (let index in data.identified_source) {
                                            identifiers = identifiers.concat(Object.values(data.identified_source[index]));
                                        }
                                        identifiers = uniq(identifiers.filter((el) => el));
                                        return value.some((val) => identifiers.includes(val));
                                    }
                                } else if (control === "rag_status") {
                                    value = value.map((val) => val.toLowerCase());
                                    if (!value?.length) return data;
                                    else {
                                        let categoriesList = state.riskCategories.find((cat) => cat.group === "Risk Categories");
                                        let radio = [];
                                        if ((currentTab === "media" || currentTab === "associatedPeople" || currentTab === "keyFacts") && data.risk_categories) {
                                            radio = data.risk_categories?.filter((el) => {
                                                if (categoriesList?.categories) {
                                                    return categoriesList?.categories.some((elem) => el === elem.category);
                                                }
                                            });
                                        } else {
                                            radio = flags
                                                ?.filter((el) => {
                                                    if (categoriesList?.categories) {
                                                        return categoriesList?.categories.some((elem) => el.categories.some((cat) => cat === elem.category));
                                                    }
                                                })
                                                ?.filter((el) => {
                                                    if (!el.username?.length) return el.platform === data.platform;
                                                    else return el.username.includes(data.username) && el.platform === data.platform;
                                                });
                                        }
                                        if (radio?.length) {
                                            if (data.entity_status) return value.includes(data.entity_status?.toLowerCase());
                                            else return value.includes("red");
                                        }
                                        return value.includes("green");
                                    }
                                } else if (control === "riskCategories") {
                                    // this filter need not be applied to some tabs, in that case ignore. & return.
                                    if ([VALID_TAB_LOCATIONS.personalDetails, VALID_TAB_LOCATIONS.searchEngineResults, VALID_TAB_LOCATIONS.greenContent, VALID_TAB_LOCATIONS.websites, VALID_TAB_LOCATIONS.darkweb].includes(currentTab)) {
                                        return data;
                                    }

                                    if (value?.length === 0) return data;
                                    else {
                                        let filteroptions = value.map((e) => e.category.toLowerCase());
                                        var containsRiskCategory = [];
                                        // added key facts search
                                        if ((currentTab === "media" || currentTab === "associatedPeople" || currentTab === "keyFacts") && data.risk_categories) {
                                            containsRiskCategory = data.risk_categories.filter((cat) => (cat ? filteroptions.includes(cat.toLocaleLowerCase()) : null));
                                        } else {
                                            containsRiskCategory = flags?.filter((el) => {
                                                if (!el.username?.length) return el.platform === data.platform && el.categories.some((cat) => (cat ? filteroptions.includes(cat.toLocaleLowerCase()) : null));
                                                else return el.username.includes(data.username) && el.platform === data.platform && el.categories.some((cat) => (cat ? filteroptions.includes(cat.toLocaleLowerCase()) : null));
                                            });
                                        }

                                        return containsRiskCategory?.length >= 1;
                                    }
                                } else if (control === "search") {
                                    if (!value) return data;
                                    else if (currentTab in allowedSearches)
                                        return allowedSearches[currentTab].some((param) => {
                                            if (param === "categories" && data[param]) return data[param].some((cat) => cat.tag.toLocaleLowerCase().includes(value.toLocaleLowerCase()));
                                            const item = get(data, param);
                                            if (item)
                                                return item
                                                    .toString()
                                                    .toLowerCase()
                                                    .includes(value.toLowerCase());
                                        });
                                } else if (control === "activePassiveMedia") {
                                    if (value === "All") {
                                        return data;
                                    } else if (value === "Media") {
                                        return data.media_type === "passive_media";
                                    } else if (value === "Search") {
                                        return data.media_type === "search";
                                    } else if (value === "Url") {
                                        return data.media_type === "url";
                                    }
                                } else if (control === "activePassiveMediaMonitoringType") {
                                    if (value === "All") return data;
                                    else {
                                        return data?.monitoring === true && data.monitoring_type === ACTIVE_PASSIVE_MONITORING_TYPE_FILTER[value];
                                    }
                                } else if (control === "entityFilter") {
                                    if (value?.length === 0 || (value?.length === 1 && value[0].entity === "All")) return data;
                                    else {
                                        let fromEntityIds = value.map((el) => {
                                            return el._id;
                                        });

                                        // Get all nodes in which the from  is  the selected entities
                                        let connectedNodesIds = state.entityData.connections.filter((el) => {
                                            return fromEntityIds.includes(el.from) || fromEntityIds.includes(el.to);
                                        });

                                        return connectedNodesIds.some(function(el) {
                                            return el.to == data._id || el.from == data._id;
                                        });
                                    }
                                } else if (control === "greenContentFilter") {
                                    if (value === "All") return data;
                                    else {
                                        let match_key = value
                                            .split(" ")
                                            .map((el) => el.toLowerCase())
                                            .join("_");
                                        return data.type == match_key;
                                    }
                                } else if (control === "associatedEntitiesFilter") {
                                    if (value === "All") {
                                        return data;
                                    } else {
                                        let match_key = value
                                            .split(" ")
                                            .map((el) => el.toLowerCase())
                                            .join("_");
                                        return data.entity_identifier == match_key;
                                    }
                                } else if (control === "monitoringCheckbox") {
                                    if (value === true) {
                                        return data.monitoring == true;
                                    } else {
                                        return data;
                                    }
                                } else if (control === "social") {
                                    if (!value || value === "All") return data;
                                    else return data.platform === value;
                                } else if (control === "selectedCaseUser") {
                                    if (value?.length) {
                                        if ("_id" in data) {
                                            return state.userDocIds.includes(data["_id"]);
                                        } else if ("id" in data) {
                                            return state.userDocIds.includes(data["id"]);
                                        }
                                    }
                                    return data;
                                } else if (control === "startDate" || control === "endDate") {
                                    if (value) {
                                        if ("_id" in data) {
                                            return state.userDocIds.includes(data["_id"]);
                                        } else if ("id" in data) {
                                            return state.userDocIds.includes(data["id"]);
                                        }
                                    }
                                    return data;
                                } else return data;
                            });
                        });

                    if (state.filteredData && !state.filteredData[currentTab]) state.filteredData = {...state.filteredData, [currentTab]: []};

                    state.filteredData[currentTab] = state.readOnlyMode ? uniq(filteredData[currentTab].filter((entity) => entity.status == "CONFIRMED" || currentTab == "darkWeb")) : uniq(filteredData[currentTab].filter((entity) => entity.status !== "IRRELEVANT"));
                    if (currentTab == "personalDetails") {
                        let sortedData = [];
                        let dataMap = {};
                        let removedEntity = ["associated_entity", "social_profile", "volunteering_roles", "wider_evidence_skills", "charitable_work", "active_media", "search", "passive_media"];
                        let navTabs = state.personalDetailsTabs.filter((el) => !removedEntity.includes(el.key) && el.enable);
                        navTabs.forEach((ele) => {
                            let item = state.filteredData[currentTab].filter((e) => (ele.type == "entity" ? ele.mapper.find((n) => n.map_id == e.entity_type._id) : ele.entity.find((n) => n.map_id == e.entity_type._id)));
                            if (ele.key === "employment") {
                                item = item.filter((el) => el.relations.find((n) => n.relation_type._id === "cdd90e43-d5ea-4ffd-b576-9b2f197cc79a"));
                            }
                            if (ele.key === "education") {
                                item = item.filter((el) => el.relations.find((n) => n.relation_type._id === "2c8628a7-7907-43a0-8933-337bb304cb26"));
                            }
                            dataMap[ele.key] = item;
                            sortedData = [...sortedData, ...item];
                        });
                        // state.filteredData[currentTab].forEach((item) => {
                        //     if (!removedEntity.includes(item.entity_identifier)) {
                        //         if (!(item.entity_identifier in dataMap)) {
                        //             dataMap[item.entity_identifier] = [];
                        //         }
                        //         dataMap[item.entity_identifier].push(item);
                        //     }
                        // });
                        // navTabs.forEach((tab) => {
                        //     if (dataMap[tab.key] && dataMap[tab.key]?.length) {
                        //         dataMap[tab.key].forEach((item) => {
                        //             sortedData.push(item);
                        //         });
                        //     }
                        // });
                        if (state.filteredData && !state.filteredData[currentTab]) state.filteredData = {...state.filteredData, [currentTab]: []};
                        state.filteredData[currentTab] = uniq(sortedData);
                    }
                }
            }
        },

        FILTER_PLACEHOLDER_DATA(state, payload) {
            payload.forEach((el) => {
                state.irrelevantData[el.tab].push({
                    component: el.tab,
                    item: el,
                    tab: el.tab,
                    tyoe: "IRRELEVANT",
                });
            });
        },

        SET_NODES_CONNECTIONS_INFO(state, payload) {
            state.entityData = payload;
            if (payload?.entities?.length === 0) {
                state.isEmptyConnection = true;
            }
            // state.entityId = payload.entities.filter(el => el.type === "Entity" && el.en)
        },

        PUSH_IRRELEVANT(state, payload) {
            if (!state.irrelevantData[payload.tab]) {
                return;
            }
            if (!state.irrelevantData[payload.tab]?.length) state.irrelevantData[payload.tab].push(payload);
            else {
                if (state.irrelevantData[payload.tab].every((item) => item.item._id !== payload.item._id)) state.irrelevantData[payload.tab].push(payload);
            }

            try {
                if (router.app?._route?.name === "research-automated" && router.app?._route?.params.casename !== "keyFacts")
                    if (state.researchData[payload.tab]?.length) {
                        const match = state.researchData[payload.tab].findIndex((item) => item._id === payload.item._id);

                        if (match !== -1) state.researchData[payload.tab].splice(match, 1);
                    } else if (state.caseResearchData[payload.tab]?.length) {
                        const match = state.caseResearchData[payload.tab].findIndex((item) => item._id === payload.item._id);

                        if (match !== -1) state.caseResearchData[payload.tab].splice(match, 1);
                    }
            } catch (err) {
                console.log(err, "err");
            }
        },

        POP_IRRELEVANT(state, payload) {
            const tab = payload.tab;

            const index = state.irrelevantData[tab].findIndex((item) => item.item._id === payload.item._id);

            const match = state.irrelevantData[tab].find((item) => item.item._id === payload.item._id);

            payload.item.status = null;
            payload.item.deleted = false;

            delete match["tab"];

            if (match !== -1) {
                if (router.app?._route?.name === "research-automated" && router.app?._route?.params.casename !== "keyFacts") state.caseResearchData?.[tab]?.unshift(payload.item);
                else state.researchData?.[tab]?.unshift(payload.item);
                // if(tab !== "personalDetails"){
                //   state.researchData[tab].unshift(match.item);
                // }
                state.irrelevantData[tab].splice(index, 1);
            }
        },
        PUSH_CONFIRMED(state, payload) {
            if (!state.confirmedData?.[payload.tab]?.length) {
                state.confirmedData?.[payload.tab]?.push(payload);
            } else {
                if (state.confirmedData?.[payload.tab]?.every((item) => item.item._id !== payload.item._id)) state.confirmedData?.[payload.tab]?.push(payload);
            }
        },
        POP_CONFIRMED(state, payload) {
            const match = state.confirmedData?.[payload.tab]?.findIndex((item) => item.item._id === payload.item._id);

            if (match !== -1) state.confirmedData?.[payload.tab]?.splice(match, 1);
        },
        MATCHING_PROFILE(state, payload) {
            state.matchingProfile = state.profileData.find((profile) => profile.username === payload.username && profile.platform === payload.platform);

            if (state.matchingProfile) {
                state.matchingProfile["notes"] = payload.notes;
                state.matchingProfile["customer_notes"] = payload.customer_notes;
            }

            if (router.app?._route?.name === "research-automated" && router.app?._route?.params.casename !== "keyFacts") state.matchingCaseData = state.caseResearchData.socialProfiles.find((elem) => elem.username === payload.username && elem.platform === payload.platform);
            else state.matchingCaseData = state.researchData.socialProfiles.find((elem) => elem.username === payload.username && elem.platform === payload.platform);
        },
        ADD_TO_REPORT(state, payload) {
            const match = state.matchingProfile.data.find((post) => post._id === payload._id);
            Object.assign(match, {...payload, include_in_report: true});

            // if (state.reportedContent.every(content => content._id !== payload._id))
            //   state.reportedContent.push(payload);
        },
        REMOVE_FROM_REPORT(state, payload) {
            const match = state.matchingProfile.data.find((post) => post._id === payload._id);
            payload.categories.analyst = payload.categories.system;
            Object.assign(match, {...payload, include_in_report: false});

            // state.reportedContent = state.reportedContent.filter(
            //   content => content._id !== payload._id
            // );
        },
        SET_FILTER(state, payload) {
            // state.filtersApplied[payload.field] = payload.param;
            state.filtersApplied = {
                ...state.filtersApplied,
                [payload.field]: payload.param,
            };
            // this.$set(state.getFiltersApplied, payload.field, payload.param)
            // (state.filtersApplied[payload.field] = state.filtersApplied[payload.field] || []).push(payload.param)
        },
        SET_GREEN_CONTENT_DATA(state, payload) {
            state.researchData.greenContent = payload;
        },
        SET_WEBSITES_DATA(state, payload) {
            state.researchData.websites = payload;
        },
        SET_SOCIAL_PROFILE_DATA(state, payload) {
            state.researchData.socialProfiles = payload;
        },
        SET_ASSOCIATED_DATA(state, payload) {
            state.researchData.associatedPeople = payload;
        },
        SET_PERSONAL_DETAILS_DATA(state, payload) {
            state.researchData.personalDetails = payload;
        },
        SET_EXTRA_CONTENT_DATA(state, payload) {
            state.caseData.extra_content = payload;
        },
        SET_OSINT_SOURCES_DATA(state, payload) {
            state.osintSourcesData = payload;
        },
        SET_ENTITIES_RELATIONS_ATTRIBUTES_DATA(state, payload) {
            state.entitiesRelationsAttributesData = payload;
        },
        SET_RISK_CATEGORIES_DATA(state, payload) {
            let data = {};
            let groupName = "";

            state.rawRiskCategories = payload.risk_data;
            payload?.risk_data?.forEach((item) => {
                if ("category_type" in item) {
                    groupName = item.category_type;
                } else {
                    groupName = "Others";
                }

                if (!(groupName in data)) {
                    data[groupName] = [];
                }
                data[groupName].push({category: item.category});
            });
            let groupedCategories = [];
            for (const [k, v] of Object.entries(data)) {
                groupedCategories.push({group: k, categories: v});
            }
            state.riskCategories = groupedCategories;
        },
        SET_DIRECT_IP_LOGIN(state, payload) {
            state.directIpLogin = payload;
        },
        PUSH_RESEARCH_SEARCH_DATA(state, payload) {
            for (const newDoc of payload) {
                let found = false;
                for (const existingDoc of state.researchData.searchEngineResults) {
                    if (existingDoc._id === newDoc._id) {
                        found = true;
                        break;
                    }
                }
                if (!found) {
                    state.researchData.searchEngineResults.push(newDoc);
                }
            }
        },
        SET_PAGINATION(state, payload) {
            state.tabPaginations[payload.tab].currentPage = payload.currentPage;
        },

        UPDATE_MATCHING_PROFILE(state, payload) {
            if (!state.matchingProfile)
                state.matchingProfile = {
                    data: payload,
                };
            else state.matchingProfile.data = payload;
        },
        SET_INVALID_CASE_ID(state, payload) {
            state.invalidCaseId = payload;
        },
        SET_CASE_USERS(state, payload) {
            state.caseUsers = payload;
        },
        SET_USERS(state, payload) {
            state.allUsers = payload;
        },
        SET_CASE_PERMISSIONS(state, payload) {
            state.allCasePermissions = payload;
        },
        SET_USER_DOC_IDS(state, payload) {
            state.userDocIds = payload;
        },
        SET_ALL_USERNAME_DATA: (state, payload) => (state.allUsernameData = payload),
        SET_LAST_REVIEWED(state, payload) {
            if (payload !== undefined || Object.keys(payload)?.length > 0) {
                state.lastReviewed.user = payload.reviewd_by;
                state.lastReviewed.timestamp = DateTime.fromISO(payload.timestamp).toISODate();
                state.lastReviewed.role = payload.reviewd_by_role;
            }
        },
        SET_ALL_TOOLS_DATA: (state, payload) => {
            const index = state.allToolsData.findIndex((n) => n["tool-name"] == payload["tool-name"]);
            if (index == -1) {
                state.allToolsData.push(payload);
            } else {
                state.allToolsData[index] = payload;
            }
        },

        SET_ALL_MONITORING_DATA: (state, payload) => {
            const index = state.allMonitoringData.findIndex((n) => n["tool-name"] == payload["tool-name"]);
            if (index == -1) {
                state.allMonitoringData.push(payload);
            } else {
                state.allMonitoringData[index] = payload;
            }
        },

        GET_ALL_CASE_STATES: (state, payload) => {
            state.allCaseStates = payload;
        },

        GET_CASE_USERNAMES: (state, payload) => {
            state.allCaseUsernames = payload;
        },

        GET_ANALYSIS_DROPDOWN: (state, payload) => {
            state.analysisDropdownData = payload;
        },

        GET_CASE_EMAILS: (state, payload) => {
            state.allCaseEmails = payload;
        },

        GET_CASE_PHONES: (state, payload) => {
            state.allCasePhones = payload;
        },

        GET_CASE_ORGANISATIONS: (state, payload) => {
            state.allCaseOrganisations = payload;
        },

        GET_CASE_LOCATIONS: (state, payload) => {
            state.allCaseLocations = payload;
        },

        GET_CASE_DOMAINS: (state, payload) => {
            state.allCaseDomains = payload;
        },

        GET_CASE_EDUCATIONS: (state, payload) => {
            state.allCaseEducations = payload;
        },

        GET_CASE_EMPLOYMENTS: (state, payload) => {
            state.allCaseEmployments = payload;
        },

        GET_CASE_HOBBIES: (state, payload) => {
            state.allCaseHobbies = payload;
        },

        GET_CASE_COMPANIES: (state, payload) => {
            state.allCaseCompanies = payload;
        },

        GET_CASE_REGISTRANTS: (state, payload) => {
            state.allCaseRegistrants = payload;
        },
        GET_CASE_NAMES: (state, payload) => {
            state.allCaseNames = payload;
        },

        SET_CURRENT_ANALYSIS_TOOL: (state, payload) => (state.currentAnalysisToolsTab = payload),

        SET_TOOLS_DATA: (state, payload) => (state.toolsData = payload),

        SET_ALL_TOOLS_NAMES: (state, payload) => (state.allToolNames = payload),
        SET_SEARCHED_QUERIES: (state, payload) => (state.searchedQueries = payload),
        SET_OVERALL_TOOLS_STATUS: (state, payload) => (state.overallToolsStatus = payload),
        SET_QUERIES_LOADER: (state, payload) => (state.queriesLoading = payload),
        SET_TOOLS_FILTER: (state, payload) => (state.toolsFilterData = payload),
        SET_NER_FILTERS: (state, payload) => {
            if (payload && Object.keys(payload)?.length) state.nerFilters = payload;
            else state.nerFilters = null;
        },
        SET_COUNTER_INTERVAL: (state, payload) => (state.counterInterval = payload),
        SET_INTERVAL_SECS: (state, payload) => (state.intervalSecs = payload),
        SET_BUILD_QUERIES: (state, payload) => (state.buildQueries = payload),
        SET_APP_CONFIG: (state, payload) => {
            if (payload) {
                state.appConfig.mainEntity = payload.config["saas:main_entity"]?.value;
            }
        },
        SET_MONITORING_DATA: (state, payload) => (state.monitoringData = payload),
        SET_SOCKET_IO: (state, payload) => (state.socketIO = payload),
        SET_ENTITIES_SEARCH_QUERY(state, payload) {
            state.entitiesSearchQuery = payload;
        },
        SET_PERMISSIONS: (state, payload) => {
            state.permissions = payload;
        },
        SET_PERMISSIONS_FETCHED: (state, payload) => {
            state.permissionsFetched = payload;
        },
        SET_LICENSES: (state, payload) => {
            state.licenses = payload;
        },
        SET_CASE_DATA_LEVEL: (state, payload) => (state.caseDataLevel = payload),
        SET_PRODUCT_FEATURES: (state, payload) => (state.productFeatures = payload),
        SET_EXTENSION_ID: (state, payload) => (state.extensionID = payload),
        SET_SELECTED_KEYWORDS_DATA: (state, payload) => (state.selectedKeywordsData = payload),
        SET_SEARCH_WORDS_DATA: (state, payload) => (state.searchWords = payload),
        SET_CASE_VALID: (state, payload) => (state.caseValid = payload),
        SET_VALIDATE_CASE: (state, payload) => (state.validCase = payload),
        SET_SELECTED_TOOL_QUERY: (state, payload) => {
            if (router.app?._route?.name === "monitoring" || router.app?._route?.name === "monitoring-ro") state.selectedMonitoringQuery = payload;
            else state.selectedToolQuery = payload;
        },
        SET_MONITORING_DATE_FILTERS: (state, payload) => (state.monitoringDateFilter = payload),
        SET_CASE_LIST_READ_ONLY: (state, payload) => (state.isCaseListReadOnly = payload),
        SET_USER_MANAGED_CASE: (state, payload) => (state.isUserManagedCase = payload),
        SET_NEW_CASE_STATUS: (state, payload) => (state.case_status = payload),
        SET_SELECTED_TOOLS_USERS: (state, payload) => (state.selectedToolsUsers = payload),
        SET_CASE_ENTITY_INFORMATION: (state, payload) => (state.caseEntityInfo = payload),
        SET_OUTPUT_TEMPLATES: (state, payload) => (state.outputTemplates = payload),
    },
    actions: {
        async getSearchNextPageResult({commit, getters, dispatch}) {
            // let nextPageId = getters.getSearchEngineResults[getters.getSearchEngineResults?.length - 1]._id
            // state.tabPagination.searchEngineResults.currentPage = state.tabPagination.searchEngineResults.currentPage + 1;
            let nextPage = getters.getTabPaginations.searchEngineResults.currentPage + 1;
            try {
                let nextPageData = await tabFetcherMap["searchEngineResults"](getters.getCaseId, nextPage, getters.getTabPaginations.searchEngineResults.pageSize);
                commit("PUSH_RESEARCH_SEARCH_DATA", nextPageData.searchEngineResults.relevant);
                commit("SET_PAGINATION", {tab: "searchEngineResults", currentPage: nextPage});
            } catch (error) {
                console.error("search next page error", error);
            }
        },

        setUserDetails({commit}, payload) {
            commit("SET_USER_DETAILS", payload);
        },

        setToolsFilterData({commit}, payload) {
            let payloadData = {
                monitoring: "all",
                saved: "all",
                // sources: [],
                // entity_type: [],
                // types: [],
                // jurisdiction: [],
            };

            if (payload.monitoring !== "all") {
                payloadData.monitoring = payload.monitoring === "monitoring";
            }
            if (payload.saved !== "all") {
                payloadData.saved = payload.saved === "saved";
            }
            // if (payload?.sources?.length > 0) {
            //     payloadData.sources = payload.sources;
            // }
            // if (payload?.entity_type?.length > 0) {
            //     payloadData.entity_type = payload.entity_type;
            // }
            // if (payload?.types?.length > 0) {
            //     payloadData.types = payload.types;
            // }
            // if (payload?.jurisdiction?.length > 0) {
            //     payloadData.jurisdiction = payload.jurisdiction;
            // }

            commit("SET_TOOLS_FILTER", payloadData);
        },

        async updateMatchingProfile({commit, getters}, payload) {
            let caseId = getters.getCaseId;
            let data = await tabFetcherMap["socialPlatformData"](caseId);
            for (const item of data.socialPlatformData.relevant) {
                if (item.username === payload[0]?.metadata?.user.screen_name) {
                    commit("UPDATE_MATCHING_PROFILE", payload);
                    break;
                }
            }
            // commit('ADD_SOCIAL_PLATFORM_DATA', data.socialPlatformData.relevant)
        },

        pushResearchData({commit}, payload) {
            let currentTabData = payload.currentTabData;
            let commitMapper = {
                personalDetails: "SET_PERSONAL_DETAILS_DATA",
                socialProfiles: "SET_SOCIAL_PROFILE_DATA",
                searchEngineResults: "SET_RESEARCH_SEARCH_DATA",
                associatedPeople: "SET_ASSOCIATED_DATA",
                greenContent: "SET_GREEN_CONTENT_DATA",
                websites: "SET_WEBSITES_DATA",
                media: "SET_ACTIVE_PASSIVE_DATA",
                darkWeb: "SET_DARKWEB_DATA",
                casePersonalData: "SET_CASE_PERSONAL_DATA",
                socialPlatformData: "ADD_SOCIAL_PLATFORM_DATA",
                extraContent: "SET_EXTRA_CONTENT_DATA",
                personalDetailsTabs: "SET_PERSONAL_DETAILS_TABS",
                invalidCaseId: "SET_INVALID_CASE_ID",
                connections: "SET_NODES_CONNECTIONS_INFO",
                keyFacts: "SET_KEY_FACTS",
                outputTemplates: "SET_OUTPUT_TEMPLATES",
            };

            for (const [tab, tabData] of Object.entries(currentTabData)) {
                if (tab === "invalidCaseId") {
                    commit(commitMapper[tab], tabData);
                } else {
                    if (Array.isArray(tabData)) {
                        commit(commitMapper[tab], tabData);
                    } else if ("relevant" in tabData && "irrelevant" in tabData) {
                        commit(commitMapper[tab], tabData.relevant);
                        tabData.irrelevant.forEach((el) => {
                            commit("PUSH_IRRELEVANT", el);
                        });
                    } else {
                        commit(commitMapper[tab], tabData);
                    }
                }
            }
        },

        async fetchStaticData({commit}) {
            getStaticData().then((staticData) => {
                commit("SET_ENTITY_MAPPER_DATA", staticData.entitymapperdata);
                commit("SET_ICONS_DATA", staticData.iconsdata);
                commit("SET_SOCIAL_PLATFORMS", staticData.socialPlatformIcons);
                commit("SET_RISK_CATEGORIES_DATA", staticData.riskCategories);
                commit("SET_ENTITIES_RELATIONS_ATTRIBUTES_DATA", staticData.entitiesRelationsAttributes);
                commit("SET_APP_CONFIG", staticData.appConfig);
            });
        },

        async fetchCompanyProducts({commit, getters}) {
            // let response = await rawAxios.post("https://portal.neotas.com/client/internal/get/company/products", {
            let response = await axios.post("/product", {
                comp_id: getters.getAccountDetails.id,
            });
            commit("SET_COMPANY_PRODUCTS", response.data);
        },

        async getNewMonitoringEvents({commit, getters}) {
            let url = `/data-monitoring/monitoring-changes-count-tools`;
            let startDt = getters.getMonitoringDateFilter.startDate;
            let endDt = getters.getMonitoringDateFilter.endDate;
            const days = 30;
            if (startDt && endDt) {
                startDt = new Date(getters.getMonitoringDateFilter.startDate);
                endDt = new Date(getters.getMonitoringDateFilter.endDate);
            } else if (getters.getCaseCreationDate) {
                endDt = new Date();
                startDt = new Date(getters.getCaseCreationDate);
            } else {
                endDt = new Date();
                startDt = new Date(endDt.getTime() - days * 24 * 60 * 60 * 1000);
            }
            startDt.setHours(0);
            startDt.setMinutes(0);
            startDt.setSeconds(0);
            startDt.setMilliseconds(0);
            let today = new Date();
            endDt.setHours(today.getHours());
            endDt.setMinutes(today.getMinutes());
            endDt.setSeconds(today.getSeconds());
            startDt = DateTime.fromMillis(Date.parse(startDt));
            startDt = startDt.toFormat("yyyy-MM-dd'T'hh:mm:ss.SSS'Z'");
            endDt = DateTime.fromMillis(Date.parse(endDt));
            endDt = endDt.toFormat("yyyy-MM-dd'T'hh:mm:ss.SSS'Z'");
            let data = {
                case_id: getters.getCaseId,
                start_date: startDt,
                end_date: endDt,
            };
            try {
                let response = await axios.post(url, data);
                if (response?.status === 200) {
                    commit("SET_TOOLS_MONITORING_EVENTS", response?.data?.result);
                    commit("SET_OVERALL_MONITORING_EVENTS", response?.data?.total_changes);
                }
            } catch (error) {
                console.error(error);
            }
        },

        async getQueryMonitoringEvents({commit, getters}, payload) {
            let url = `/data-monitoring/monitoring-changes-count-query`;
            let startDt = new Date(getters.getMonitoringDateFilter.startDate);
            startDt.setHours(0);
            startDt.setMinutes(0);
            startDt.setSeconds(0);
            startDt.setMilliseconds(0);
            let today = new Date();
            let endDt = new Date(getters.getMonitoringDateFilter.endDate);
            endDt.setHours(today.getHours());
            endDt.setMinutes(today.getMinutes());
            endDt.setSeconds(today.getSeconds());
            startDt = DateTime.fromMillis(Date.parse(startDt));
            startDt = startDt.toFormat("yyyy-MM-dd'T'hh:mm:ss.SSS'Z'");
            endDt = DateTime.fromMillis(Date.parse(endDt));
            endDt = endDt.toFormat("yyyy-MM-dd'T'hh:mm:ss.SSS'Z'");
            let data = {
                case_id: getters.getCaseId,
                tool_name: payload,
                start_date: startDt,
                end_date: endDt,
            };
            try {
                let response = await axios.post(url, data);
                if (response?.status === 200) {
                    commit("SET_QUERY_MONITORING_EVENTS", response?.data?.result);
                }
            } catch (error) {
                console.error(error);
            }
        },

        // Function to get case monitoring data
        async getDataMonitoring({commit, getters}) {
            let url = `/data-monitoring/case?case_id=${getters.getCaseId}`;
            // let data = {
            //     monitoring_type: "query",
            //     case_id: this.$route.params?.id,
            //     tool: this.currentToolSelected,
            // };
            try {
                // let response = await axios.post(url, data);
                let response = await axios.post(url);
                if (response?.status === 200 && response?.data?.data) {
                    let caseMonitoring = response?.data?.data;
                    commit("SET_MONITORING_TOOLS", caseMonitoring);
                }
            } catch (error) {
                console.error(error);
            }
        },

        // Function to get product monitoring data
        async getProductMonitoring({commit, getters}) {
            let url = `/data-monitoring/product?product_id=${getters.getProduct?.key}`;
            // let data = {
            //     product_id: this.product?.key,
            //     tool: this.tool,
            // };
            try {
                let response = await axios.post(url);
                // let response = await axios.post(url, data);
                if (response?.status === 200 && response?.data?.data) {
                    let productMonitoring = response?.data?.data;
                    commit("SET_PRODUCT_MONITORING", productMonitoring);
                }
            } catch (error) {
                console.error(error);
            }
        },

        async fetchAllResearchScreenData({commit, getters, dispatch}, payload) {
            var currentTab = payload.currentTab;
            let caseId = getters.getCaseId;

            let func;
            if (["greenContent", "socialProfiles", "websites"].includes(currentTab)) {
                func = tabFetcherMap["caseData"];
            } else if (["associatedPeople", "personalDetails"].includes(currentTab)) {
                func = tabFetcherMap["entityIdentifier"];
            } else {
                func = tabFetcherMap[currentTab];
            }

            dispatch("fetchStaticData");
            // func(caseId, currentTab).then((currentTabData) => {dispatch('pushResearchData',{currentTabData})})
            let currentTabData = await func(caseId, currentTab);
            dispatch("pushResearchData", {currentTabData});

            // getFilterParamsData(caseId).then((filterData) => {
            //     commit("SET_SEARCH_FILTERS", filterData);
            // });

            getHistoryData(caseId).then((historyData) => {
                commit("SET_HISTORY_DATA", historyData);
            });

            fetchNotesData(caseId).then((notesData) => {
                commit("SET_NOTES_DATA", notesData);
            });

            fetchCaseUsersData(caseId).then((caseUsers) => {
                commit("SET_CASE_USERS", caseUsers.users);
                commit("SET_USERS", caseUsers.users_mini);
            });

            fetchLastReviewed(caseId).then((lastReviewed) => {
                commit("SET_LAST_REVIEWED", lastReviewed);
            });

            let remainingApis = [];
            if (!["greenContent", "socialProfiles", "websites"].includes(currentTab)) {
                remainingApis.push(tabFetcherMap["caseData"]);
            }
            if (!["associatedPeople", "personalDetails"].includes(currentTab)) {
                remainingApis.push(tabFetcherMap["entityIdentifier"]);
            }

            if (currentTab !== "media") {
                remainingApis.push(tabFetcherMap["media"]);
            }
            // if (currentTab !==  'searchEngineResults'){
            //   remainingApis.push(tabFetcherMap['searchEngineResults'])
            // }
            // if (currentTab !== "darkWeb") {
            //     remainingApis.push(tabFetcherMap["darkWeb"]);
            // }
            if (currentTab !== "keyFacts") {
                remainingApis.push(tabFetcherMap["keyFacts"]);
            }

            remainingApis.push(tabFetcherMap["casePersonalData"]);
            remainingApis.push(tabFetcherMap["socialPlatformData"]);

            for (func of remainingApis) {
                func(caseId).then((currentTabData) => {
                    dispatch("pushResearchData", {currentTabData});
                    dispatch("filterData");
                });
            }

            getOsintSources().then((osintData) => {
                commit("SET_OSINT_SOURCES_DATA", osintData);
            });
        },

        async getAllResearchTabs({commit, getters}, payload) {
            let filters = {
                case_id: getters.getCaseId,
                ...payload,
            };

            delete filters.current_page;
            delete filters.limit;

            try {
                let response = await axios.post(`social-media-automation/research-tabs`, filters);
                commit(
                    "SET_RESEARCH_TABS",
                    response?.data?.data?.sort((a, b) => {
                        return a.order > b.order ? 1 : -1;
                    })
                );
            } catch (err) {
                console.error(err);
            }
        },

        async fetchCasePersonalData({commit, getters}) {
            let response = await axios.get(`case-personal-data/${getters.getCaseId}`);
            commit("SET_CASE_PERSONAL_DATA", response.data.case_data);
        },

        async getAllResearchData({commit, state, getters}, payload) {
            const data = {
                case_id: getters.getCaseId,
                research_tab_id: payload.tab._id,
                ...payload.filters,
            };
            try {
                let response = await axios.post(`social-media-automation/research-data`, data);
                if (payload.filters.current_page > 1) {
                    let cards = [];
                    const cardData = getters.getCaseResearchData[payload.tab?.key];
                    let mediaCards = response?.data?.data;
                    cards = [...cardData, ...mediaCards];
                    commit("SET_RESEARCH_DATA", {
                        tab: payload.tab.key,
                        data: cards,
                    });
                } else {
                    commit("SET_RESEARCH_DATA", {
                        tab: payload.tab.key,
                        data: response?.data?.data,
                    });
                }
                const index = state.caseResearchTabs.findIndex((el) => el._id === payload.tab._id);
                if (index > -1) state.caseResearchTabs[index].unacknowledge_count = 0;
            } catch (err) {
                console.error(err);
            }
        },

        async fetchHistory({commit, getters}) {
            try {
                let historyData = await axios.get(`get-history/` + getters.getCaseId);
                commit("SET_HISTORY_DATA", historyData.data);
            } catch (err) {
                //error...handle
            }
        },

        updateSearchResults({commit}, payload) {
            commit("SET_RESEARCH_SEARCH_DATA", payload);
        },
        setEntitiesSearchQuery({commit}, payload) {
            commit("SET_ENTITIES_SEARCH_QUERY", payload);
        },
        updateExtraContent({commit}, payload) {
            commit("UPDATE_EXTRA_CONTENT", payload);
        },
        setReadOnly({commit}, payload) {
            commit("SET_READ_ONLY", payload);
        },
        setProduct({commit}, payload) {
            commit("SET_PRODUCT", payload);
        },
        setCaseId({commit}, payload) {
            commit("SET_CASE_ID", payload);
        },
        updateCard({commit}, payload) {
            commit("UPDATE_CARD", payload);
        },
        updateById({commit}, payload) {
            commit("UPDATE_BY_ID", payload);
        },
        updatePost({commit}, payload) {
            commit("UPDATE_POST", payload);
        },
        deletePost({commit}, payload) {
            commit("DELETE_POST", payload);
        },
        filterData({commit, getters}, payload) {
            var flags = getters.getConsolidatedFlags;
            commit("FILTER_DATA", {payload: payload, flags: flags, dataType: "research"});
        },
        pushConfirmed({commit}, payload) {
            commit("PUSH_CONFIRMED", payload);
        },
        popConfirmed({commit}, payload) {
            commit("POP_CONFIRMED", payload);
        },
        async pushIrrelevant({commit}, payload) {
            commit("PUSH_IRRELEVANT", payload);
        },

        popIrrelevant({commit}, payload) {
            commit("POP_IRRELEVANT", payload);
            commit("FILTER_DATA", {payload: {currentTab: payload.currentTab}, dataType: "research"});
        },
        matchingProfile({commit}, payload) {
            commit("MATCHING_PROFILE", payload);
        },
        includeInReport({commit}, payload) {
            commit("ADD_TO_REPORT", payload);
        },
        removeFromReport({commit}, payload) {
            commit("REMOVE_FROM_REPORT", payload);
        },
        setFilter({commit}, payload) {
            commit("SET_FILTER", payload);
        },

        async fetchAllUsersList({commit}, payload) {
            let url = `api/v1/accounts/users-mini`;
            try {
                let users = await userAxios.get(url);
                commit("SET_USERS", users?.data || []);
            } catch (error) {
                console.error(error);
                return [];
            }
        },

        async getAnalysisDropdownData({commit}, payload) {
            const response = await axios.get(payload.url);
            try {
                if (response && response.data && response.data.data) {
                    let userNameList = response.data.data.map((user) => pick(user, ["value", "visited", "platform", "val_type"]));
                    let keyMap = {
                        value: "value",
                        visited: "visited",
                        platform: "platform",
                        val_type: "val_type",
                    };
                    userNameList = userNameList.filter((n) => n.value);
                    userNameList = userNameList.map(function(obj) {
                        return mapKeys(obj, function(value, key) {
                            return keyMap[key];
                        });
                    });
                    commit("GET_ANALYSIS_DROPDOWN", userNameList);
                }
            } catch (error) {
                let message = error.message;
            }
        },

        async getAllCaseStates({commit, getters}, payload) {
            let data = await axios.get(`/case-info/case-state`);
            commit(
                "GET_ALL_CASE_STATES",
                data?.data?.data?.map((state) => {
                    return {
                        text: state.state_name,
                        value: state.state_name,
                        indicator: state.indicator,
                    };
                })
            );
        },

        getAllCaseUsernames({commit, getters}, payload) {
            let data = getters.getResearchData.socialProfiles
                .filter((item) => item.status !== "IRRELEVANT")
                .map((item) => {
                    pick(item, ["username"]);
                    item["value"] = item["username"];
                    item["val_type"] = "username";
                    return item;
                });
            commit("GET_CASE_USERNAMES", data);
        },

        getAllCaseEmails({commit, getters}, payload) {
            let data = getters.getResearchData.personalDetails
                .filter((e) => e.entity_identifier == "email")
                .map((item) => {
                    pick(item, ["entity"]);
                    item["value"] = item["entity"];
                    item["val_type"] = "email";
                    return item;
                });
            commit("GET_CASE_EMAILS", data);
        },

        getAllCasePhones({commit, getters}, payload) {
            let data = getters.getResearchData.personalDetails
                .filter((e) => e.entity_identifier == "phone")
                .map((item) => {
                    pick(item, ["entity"]);
                    item["value"] = item["entity"];
                    item["val_type"] = "phone";
                    return item;
                });
            commit("GET_CASE_PHONES", data);
        },

        getAllCaseOrganisations({commit, getters}, payload) {
            let data = getters.getResearchData.associatedPeople
                .filter((e) => e.entity_identifier == "organisation_name")
                .map((item) => {
                    pick(item, ["entity"]);
                    item["value"] = item["entity"];
                    item["val_type"] = "organisation_name";
                    return item;
                });
            commit("GET_CASE_ORGANISATIONS", data);
        },

        getAllCaseLocations({commit, getters}, payload) {
            let data = getters.getResearchData.personalDetails
                .filter((e) => e.entity_identifier == "location" || e.entity_identifier === "address")
                .map((item) => {
                    pick(item, ["entity"]);
                    item["value"] = item["entity"];
                    item["val_type"] = "location";
                    return item;
                });
            commit("GET_CASE_LOCATIONS", data);
        },

        getAllCaseDomains({commit, getters}, payload) {
            let data = getters.getResearchData.personalDetails
                .filter((e) => e.entity_identifier == "domain")
                .map((item) => {
                    pick(item, ["entity"]);
                    item["value"] = item["entity"];
                    item["val_type"] = "domain";
                    return item;
                });
            commit("GET_CASE_DOMAINS", data);
        },

        getAllCaseEducations({commit, getters}, payload) {
            let data = getters.getResearchData.personalDetails
                .filter((e) => e.entity_identifier == "education" || e.entity_identifier === "education_institute")
                .map((item) => {
                    pick(item, ["entity"]);
                    item["value"] = item["entity"];
                    item["val_type"] = "education";
                    return item;
                });
            commit("GET_CASE_EDUCATIONS", data);
        },

        getAllCaseEmployments({commit, getters}, payload) {
            let data = getters.getResearchData.personalDetails
                .filter((e) => e.entity_identifier == "employment" || e.entity_identifier === "company_name")
                .map((item) => {
                    pick(item, ["entity"]);
                    item["value"] = item["entity"];
                    item["val_type"] = "employment";
                    return item;
                });
            commit("GET_CASE_EMPLOYMENTS", data);
        },

        getAllCaseHobbies({commit, getters}, payload) {
            let data = getters.getResearchData.personalDetails
                .filter((e) => e.entity_identifier == "hobby")
                .map((item) => {
                    pick(item, ["entity"]);
                    item["value"] = item["entity"];
                    item["val_type"] = "hobby";
                    return item;
                });
            commit("GET_CASE_HOBBIES", data);
        },

        getAllCaseCompanies({commit, getters}, payload) {
            let data = getters.getResearchData?.associatedPeople
                .filter((e) => e.entity_identifier == "company_name")
                .map((item) => {
                    pick(item, ["entity"]);
                    item["value"] = item["entity"];
                    item["val_type"] = "company_name";
                    return item;
                });
            commit("GET_CASE_COMPANIES", data);
        },

        getAllCaseRegistrants({commit, getters}, payload) {
            let data = getters.getResearchData?.associatedPeople
                .filter((e) => e.entity_identifier == "name")
                .map((item) => {
                    pick(item, ["entity"]);
                    item["value"] = item["entity"];
                    item["val_type"] = "registrant";
                    return item;
                });
            commit("GET_CASE_REGISTRANTS", data);
        },
        getAllCaseNames({commit, getters}, payload) {
            let data = getters.getResearchData.personalDetails
                .filter((e) => e.entity_identifier == "name")
                .map((item) => {
                    pick(item, ["entity"]);
                    item["value"] = item["entity"];
                    item["val_type"] = "name";
                    return item;
                });
            commit("GET_CASE_NAMES", data);
        },

        addExtraContent({commit}, payload) {
            commit("SET_EXTRA_CONTENT", payload);
        },
        setSearchStatusFilter({commit}, payload) {
            commit("SET_SEARCH_STATUS_FILTER", payload);
        },
        async fetchOsintSources({commit}, payload) {
            try {
                // let url = `/osint-tools/filter`;
                let {data: osintSources} = await axios.post(payload.url, payload.data);
                commit("SET_OSINT_SOURCES_DATA", osintSources);
            } catch (err) {
                console.error(err);
            }
        },

        setUserDocIds({commit}, payload) {
            commit("SET_USER_DOC_IDS", payload);
        },
        async fetchUsernameData({commit}, payload) {
            const {data: token} = await osintAxios.post("/username/token", payload);
            if (token?.status === "NEW") {
                await osintAxios.post("/username/process", {token: token._id});
            }

            const interval = setInterval(async () => {
                const {data} = await osintAxios.get(`/username/token/${token._id}`);
                commit("SET_ALL_USERNAME_DATA", data?.data);
                if (data.token.status === "DONE") {
                    clearInterval(interval);
                }
            }, 5000);
        },
        fetchAnalysisTools: async ({commit, state}) => {
            try {
                const {data: toolsData} = await axios.get("/analysis-tools/development");
                const tools = {
                    children: toolsData
                        .map((n) => ({
                            text: n.text,
                            activeProcess: n.tool_name,
                            monitoring: n.monitoring,
                            isTool: true,
                            active: n.active,
                            errorMessage: n.errorrMessage,
                            fields: state.toolFieldsMapping[n.tool_name],
                            platform: state.toolPlatforms[n.tool_name],
                            allChecked: false,
                            order: n.order,
                            heading: n?.heading ?? null,
                            analyseSelected: false,
                            type: n?.type ?? null,
                            manualMonitoring: n?.manualMonitoring ?? null,
                            sources: n?.sources ?? [],
                        }))
                        .sort(function(a, b) {
                            if (a.order > b.order) return 1;
                            else return -1;
                        }),
                    // .sort((a, b) => a.text.localeCompare(b.text)),
                };
                commit("SET_ALL_TOOLS_NAMES", tools);
            } catch (error) {
                console.error(error);
            }
        },
        handleAnalyseQuery: async ({getters, dispatch, commit}, payload) => {
            commit("SET_OVERALL_TOOLS_STATUS", true);
            let {source, value, windowOpen, selectedTools} = payload;
            let reqGitlab,
                reqWhatsApp,
                reqPartialDomainSearch,
                reqCourtRecords,
                reqLinkedInDiscovery,
                reqGdc,
                reqEmailRep,
                reqGoogle,
                reqDark,
                reqFlickr,
                reqGithub,
                reqLinkedIn,
                reqPI,
                reqSkype,
                reqTwitter,
                reqCompDomain,
                reqNewsTool,
                reqFacebook,
                reqFactivaTool,
                reqNameSearch,
                reqEmailSearch,
                reqCompanyName,
                reqDomainSt,
                reqInsta,
                reqTiktok,
                reqPepAdvSan,
                reqUserTool,
                reqCompanyNameClari,
                reqCompanyNameWatch,
                reqCompanyNameBvd,
                reqCompanyWatch,
                reqPepWorldCheck,
                reqPepDowJones,
                reqLexis,
                reqdnbcorprecord,
                reqPepOffshoreLeaks;
            let tools = selectedTools ? getters.getAllToolNames.children.filter((e) => selectedTools.includes(e.activeProcess)) : getters.getAllToolNames.children;
            tools = tools?.filter((ele) => getters.getPermissions.find((ele1) => FEATURES["tools"][ele1] == ele.activeProcess));
            for (const tool of tools) {
                let header = {
                    "x-tool-name": tool.activeProcess,
                };
                const data = {
                    value: value,
                    platform: tool.platform,
                    val_type: source,
                    visited: false,
                };
                await axios.post(`research-methodology/${getters.getCaseId}/tools/profiles`, data);
                if (windowOpen) {
                    window.open(`/research/${getters.getCaseId}/tools?source=${source}&value=${encodeURIComponent(value)}`, "_blank", "noreferrer");
                    windowOpen = false;
                }
                switch (tool.activeProcess) {
                    case "whatsapp-tool":
                        reqWhatsApp = whatsAppServices.searchNumber(value, "", false, true);
                        break;
                    case "court-records-tool":
                        reqCourtRecords = osintAxios.post(
                            "/scraping/judyrecords",
                            {
                                search_query: value,
                            },
                            {headers: header}
                        );
                        break;
                    case "gitlab-tool":
                        if (source === "email") {
                            reqGitlab = osintAxios.post(
                                `tools/gitlab/email`,
                                {
                                    email: value.trim(),
                                },
                                {
                                    headers: header,
                                }
                            );
                        }
                        break;
                    case "email-reputation-tool":
                        if (source === "email") {
                            reqEmailRep = osintAxios.post(
                                `tools/reputation/email`,
                                {
                                    email: value.trim(),
                                },
                                {
                                    headers: header,
                                }
                            );
                        }
                        break;
                    case "facebook-tool":
                        if (source === "email") {
                            reqFacebook = osintAxios.post(
                                "/intel/facebook-search",
                                {
                                    email: value.trim(),
                                },
                                {
                                    headers: header,
                                }
                            );
                        }
                        if (source === "phone") {
                            let allCountryCodes = await whatsappServices.getAllCountryCodes();
                            let countryCode = await splitCountryCode(value);
                            let countryId = allCountryCodes.filter((res) => {
                                return "+" + res.country_phone_code == countryCode.code;
                            });
                            let bodyData = null;
                            if (countryId.length > 0) {
                                bodyData = {
                                    id: countryId[0]._id,
                                    number: value.split(countryCode.code)[1],
                                };
                            } else {
                                bodyData = {number: value};
                            }

                            reqFacebook = osintAxios.post("/intel/facebook-search", bodyData, {
                                headers: header,
                            });
                        }
                        if (source === "name") {
                            reqFacebook = osintAxios.post(
                                "/intel/facebook-search",
                                {
                                    fname: value,
                                },
                                {
                                    headers: header,
                                }
                            );
                        }
                        if (source === "url") {
                            reqFacebook = osintAxios.post(
                                "/intel/facebook-search",
                                {
                                    fb_url: value,
                                },
                                {
                                    headers: header,
                                }
                            );
                        }
                        if (source === "facebook_id") {
                            reqFacebook = osintAxios.post(
                                "/intel/facebook-search",
                                {
                                    fb_id: value,
                                },
                                {
                                    headers: header,
                                }
                            );
                        }
                        break;
                    case "google-tool":
                        if (source === "email" || source === "phone") {
                            reqGoogle = osintAxios.post(
                                "/tools/google",
                                {
                                    [source]: source === "email" ? value.trim() : value,
                                },
                                {
                                    headers: header,
                                }
                            );
                        }
                        break;

                    case "flickr-tool":
                        if (source === "email") {
                            reqFlickr = osintAxios.post(
                                "/tools/flickr",
                                {
                                    email: value.trim(),
                                },
                                {headers: header}
                            );
                        }
                        break;
                    case "dark-web-tool":
                        if (source === "email" || source === "username" || source === "address" || source === "phone" || source === "name" || source === "domain") {
                            reqDark = osintAxios.post(
                                "/dark-web/search",
                                {
                                    [source]: source === "email" ? value.trim() : value,
                                },
                                {
                                    headers: header,
                                }
                            );
                        }
                        break;
                    case "github-tool":
                        if (source === "email") {
                            reqGithub = osintAxios.post(
                                "/tools/github/email",
                                {
                                    email: value.trim(),
                                },
                                {headers: header}
                            );
                        } else if (source === "username") {
                            reqGithub = osintAxios.post(
                                "/tools/github/username",
                                {
                                    query: value,
                                },
                                {headers: header}
                            );
                        }
                        break;
                    case "linkedin-tool":
                        if (source === "email") {
                            if (getters.getPermissions.includes("linkedin__social_media")) {
                                reqLinkedIn = osintAxios.post(
                                    "/linkedin/search/email",
                                    {
                                        email: value.trim(),
                                    },
                                    {headers: header}
                                );
                            }

                            if (getters.getPermissions.includes("linkedin_discover__social_media")) {
                                reqLinkedInDiscovery = osintAxios.post(
                                    "/intel/linkedin/discover",
                                    {
                                        discover_email: value.trim(),
                                        discovery: true,
                                    },
                                    {headers: header}
                                );
                            }
                        } else if (source === "domain") {
                            reqLinkedIn = osintAxios.post(
                                "/linkedin/search/domain",
                                {
                                    domain: value,
                                    start: 1,
                                    page_size: 10,
                                },
                                {headers: header}
                            );
                        } else if (source === "url") {
                            reqLinkedIn = osintAxios.post(
                                "/linkedin/search/url",
                                {
                                    linkedin_url: value,
                                },
                                {headers: header}
                            );
                        }
                        // else
                        // if (source === "name" ) {
                        //     reqLinkedIn = osintAxios.post(
                        //         "/linkedin/search/name",
                        //         {
                        //             name: value,
                        //             company: "",
                        //         },
                        //         { headers: header }
                        //     );
                        // }
                        break;
                    case "person-identifier-tool":
                        const product_data = getters.getCompanyProducts.find((prod) => prod.name === getters.getCasePersonalData?.data?.["Due Diligence Level"]?.value);
                        const piToolBody = {
                            name: [
                                {
                                    first_name: source === "name" && value?.trim()?.split(" ")?.[0] ? value?.trim()?.split(" ")?.[0] : "",
                                    middle_name: source === "name" && value?.trim()?.split(" ")?.[1] ? value?.trim()?.split(" ")?.[1] : "",
                                    last_name: source === "name" && value?.trim()?.split(" ")?.[2] ? value?.trim()?.split(" ")?.[2] : "",
                                    name_selected: source === "name" && value?.trim() ? true : false,
                                },
                            ],
                            age: null,
                            email: [
                                {
                                    email: source === "email" ? value.trim() : "",
                                },
                            ],
                            phone: [
                                {
                                    phone: source === "phone" ? value : "",
                                },
                            ],
                            social_details: [
                                {
                                    social_profile: "",
                                    username: source === "username" ? value.trim() : "",
                                    social_detail_selected: source === "username" ? true : false,
                                },
                            ],
                            jobs: [
                                {
                                    title: "",
                                    organization: "",
                                    industry: "",
                                    date_range: {
                                        start_date: "",
                                        end_date: "",
                                    },
                                    job_selected: false,
                                },
                            ],
                            educations: [
                                {
                                    degree: "",
                                    school: "",
                                    date_range: {
                                        start_date: "",
                                        end_date: "",
                                    },
                                    education_selected: false,
                                },
                            ],
                            location: [
                                {
                                    country: "",
                                    state: "",
                                    city: "",
                                    location_selected: false,
                                },
                            ],
                            contact_selected: source === "email" || source === "phone" ? true : false,
                            gender_selected: false,
                            top_match: product_data.automated ? true : false,
                        };

                        const {data: sourcesCall} = await osintAxios.post("/person-identifier/search-sources", piToolBody, {headers: header});

                        reqPI = osintAxios.post(
                            "/person-identifier/adv",
                            {
                                ...piToolBody,
                                request_id: sourcesCall.request_id,
                            },
                            {headers: header}
                        );
                        break;
                    case "gdc-tool":
                        if (source === "email" || source === "firstname" || source === "phone") {
                            reqGdc = osintAxios.post(
                                "/toools/gdc",
                                {
                                    [source]: source === "email" ? value.trim() : value,
                                },
                                {headers: header}
                            );
                        }
                        break;
                    case "skype-tool":
                        if (source === "email" || source === "username" || source == "phone") {
                            reqSkype = osintAxios.post(
                                "/tools/skype",
                                {
                                    [source]: source === "email" ? value.trim() : value,
                                },
                                {headers: header}
                            );
                        } else if (source === "name") {
                            reqSkype = osintAxios.post(
                                "/tools/skype",
                                {
                                    query: value,
                                },
                                {headers: header}
                            );
                        }
                        break;
                    case "twitter-tool":
                        if (source === "username") {
                            reqTwitter = osintAxios.post(
                                "/twitter/user",
                                {
                                    [source]: value,
                                },
                                {headers: header}
                            );
                        }
                        break;

                    case "company-domain":
                        if (source === "domain") {
                            reqCompDomain = osintAxios.post(
                                "/domain/registration/lookup",
                                {
                                    [source]: value,
                                },
                                {headers: header}
                            );
                        }
                        if (source === "company_name") {
                            reqCompDomain = osintAxios.post(
                                "/domain/registration/domain-name",
                                {
                                    query: value,
                                },
                                {headers: header}
                            );
                        }
                        break;
                    case "news-tool":
                        if (source === "name" || source === "company_name") {
                            // let search_keyword = value?.split(' near')[0];
                            // search_keyword = search_keyword.replace("(", "");
                            // search_keyword = search_keyword.replace(")", "");
                            let obj = {
                                // query: search_keyword,
                                query: value,
                                from_date: null,
                                to_date: null,
                                language: "en",
                                page: 1,
                                limit: 10,
                                news_sources: [],
                                sources: [],
                                //  ["news", "dowjonesfactiva"]
                            };
                            if (getters.getPermissions.includes("factiva__news")) {
                                obj.sources.push("dowjonesfactiva");
                            }
                            if (getters.getPermissions.includes("news_api__news_")) {
                                obj.sources.push("news");
                            }
                            const {data: sourcesCall} = await osintAxios.post("/news/search/sources", obj, {headers: header});
                            if (obj.sources.includes("news")) {
                                reqNewsTool = osintAxios.post(
                                    "/news/search",
                                    {
                                        query: value,
                                        from_date: null,
                                        to_date: null,
                                        language: "en",
                                        page: 1,
                                        limit: 10,
                                        request_id: sourcesCall.request_id,
                                    },
                                    {headers: header}
                                );
                            }
                            if (obj.sources.includes("dowjonesfactiva")) {
                                reqFactivaTool = osintAxios.post(
                                    "/news/dowjones-headlines-search",
                                    {
                                        query: value,
                                        page: 1,
                                        limit: 10,
                                        from_date: null,
                                        to_date: null,
                                        language: ["en"],
                                        news_sources: [],
                                        regions: [],
                                        request_id: sourcesCall.request_id,
                                    },
                                    {headers: header}
                                );
                            }
                        }
                        break;
                    case "name-search-tool":
                        if (source === "name") {
                            reqNameSearch = osintAxios.post(
                                "/tools/query",
                                {query: value},
                                {
                                    headers: header,
                                }
                            );
                        }
                        break;
                    case "email-search-tool":
                        if (source === "email") {
                            reqEmailSearch = osintAxios.post(
                                "/tools/query",
                                {query: value.trim()},
                                {
                                    headers: header,
                                }
                            );
                        }
                        break;
                    case "partial-domain-search-tool":
                        reqPartialDomainSearch = osintAxios.post(
                            "/intel/partial-domain-search?search_query=${value}",
                            {
                                search_query: value,
                            },
                            {headers: header}
                        );
                        break;
                    case "company-name":
                        if (source === "company_name") {
                            let obj = {
                                name: value,
                                sources: [],
                                mode: "keyword",
                                // ["opencorporates", "clarifiedby", "companywatch", "bvd"].sort((a, b) => a.localeCompare(b))
                            };
                            if (getters.getPermissions.includes("bvd__corp_record")) {
                                obj.sources.push("bvd");
                            }
                            if (getters.getPermissions.includes("clarifedby__corp_record")) {
                                obj.sources.push("clarifiedby");
                            }
                            if (getters.getPermissions.includes("company_watch__corp_record")) {
                                obj.sources.push("companywatch");
                            }
                            if (getters.getPermissions.includes("open_corporates__corp_record")) {
                                obj.sources.push("opencorporates");
                            }
                            if (getters.getPermissions.includes("db__corp_record")) {
                                obj.sources.push("db");
                            }
                            // if (getters.getPermissions.includes("dnb_reseller__corp_record")) {
                            //     obj.sources.push("db");
                            // }
                            const {data: sourcesCall} = await osintAxios.post("/company/search/sources", obj, {headers: header});
                            if (obj.sources.includes("opencorporates")) {
                                reqCompanyName = osintAxios.post(
                                    "/company/search",
                                    {
                                        name: value,
                                        request_id: sourcesCall.request_id,
                                    },
                                    {headers: header}
                                );
                            }
                            if (obj.sources.includes("clarifiedby")) {
                                reqCompanyNameClari = osintAxios.post(
                                    "/company/clarifiedby/search",
                                    {
                                        name: value,
                                        type: "organisations",
                                        request_id: sourcesCall.request_id,
                                    },
                                    {headers: header}
                                );
                            }
                            if (obj.sources.includes("companywatch")) {
                                reqCompanyNameWatch = osintAxios.post(
                                    "/company/company-watch/search",
                                    {
                                        name: value,
                                        mode: sourcesCall.mode,
                                        request_id: sourcesCall.request_id,
                                    },
                                    {headers: header}
                                );
                            }
                            if (obj.sources.includes("bvd")) {
                                reqCompanyNameBvd = osintAxios.post(
                                    "/company/bvd/search",
                                    {
                                        name: value,
                                        request_id: sourcesCall.request_id,
                                    },
                                    {headers: header}
                                );
                            }
                            if (obj.sources.includes("db")) {
                                reqdnbcorprecord = osintAxios.post(
                                    "/db/type_head",
                                    {
                                        name: value,
                                        request_id: sourcesCall.request_id,
                                    },
                                    {headers: header}
                                );
                            }
                        }
                        if (source === "name") {
                            let obj = {
                                officer: value,
                                sources: [],
                                mode: "keyword",
                                // ["opencorporates", "clarifiedby", "companywatch"].sort((a, b) => a.localeCompare(b)
                                // ["opencorporates", "clarifiedby", "companywatch", "bvd"].sort((a, b) => a.localeCompare(b))
                            };
                            if (getters.getPermissions.includes("bvd__corp_record")) {
                                obj.sources.push("bvd");
                            }
                            if (getters.getPermissions.includes("clarifedby__corp_record")) {
                                obj.sources.push("clarifiedby");
                            }
                            if (getters.getPermissions.includes("company_watch__corp_record")) {
                                obj.sources.push("companywatch");
                            }
                            if (getters.getPermissions.includes("open_corporates__corp_record")) {
                                obj.sources.push("opencorporates");
                            }
                            const {data: sourcesCall} = await osintAxios.post("/company/search/sources", obj, {headers: header});
                            if (obj.sources.includes("opencorporates")) {
                                reqCompanyName = osintAxios.post(
                                    "/company/search/person",
                                    {
                                        officer: value,
                                        request_id: sourcesCall.request_id,
                                    },
                                    {headers: header}
                                );
                            }
                            if (obj.sources.includes("companywatch")) {
                                reqCompanyWatch = osintAxios.post(
                                    "/company/company-watch/person",
                                    {officer: value, mode: sourcesCall.mode, request_id: sourcesCall.request_id},
                                    {
                                        headers: header,
                                    }
                                );
                            }
                            if (obj.sources.includes("clarifiedby")) {
                                reqCompanyNameClari = osintAxios.post(
                                    "/company/clarifiedby/search",
                                    {name: value, type: "individuals", request_id: sourcesCall.request_id},
                                    {
                                        headers: header,
                                    }
                                );
                            }
                            if (obj.sources.includes("bvd")) {
                                reqCompanyNameBvd = osintAxios.post(
                                    "/company/bvd/person",
                                    {
                                        name: value,
                                        request_id: sourcesCall.request_id,
                                    },
                                    {headers: header}
                                );
                            }
                        }
                        break;
                    case "domain-search-tool":
                        if (source === "email") {
                            reqDomainSt = osintAxios.post(
                                "/domain/lookup",
                                {
                                    email: source === "email" ? value : value,
                                },
                                {
                                    headers: header,
                                }
                            );
                        }
                        if (source === "name") {
                            reqDomainSt = osintAxios.post(
                                "/domain/lookup",
                                {
                                    query: value,
                                },
                                {
                                    headers: header,
                                }
                            );
                        }
                        if (source === "domain") {
                            reqDomainSt = osintAxios.post("/domain/registration/lookup", {domain: value}, {headers: header});
                        }
                        break;
                    case "instagram-tool":
                        if (source === "username") {
                            reqInsta = osintAxios.post("/instagram/user", {username: value}, {headers: header});
                        }
                        break;
                    case "tiktok-tool":
                        if (source === "username") {
                            reqTiktok = osintAxios.post("/tiktok/user", {username: value}, {headers: header});
                        }
                        break;
                    case "adverse-media-tool":
                        if (source === "name" || source === "company_name") {
                            let sourcesCallBody = {
                                name: value,
                                sources: [],
                                // ["complyadvantage", "dowjones", "worldcheck"],
                                entity_type: "",
                                search_mode: "exact_match",
                                year_of_birth: "",
                                fuzziness: 0.7,
                                country: [],
                                checks: ["peps", "sanctions", "adverse"].sort((a, b) => a.localeCompare(b)),
                                page: 1,
                                limit: 100,
                            };
                            if (getters.getPermissions.includes("comply_advantage__adverse_pep/sacntion") || getters.getPermissions.includes("comply_advantage__adverse_pep_sacntion")) {
                                sourcesCallBody.sources.push("complyadvantage");
                            }
                            if (getters.getPermissions.includes("dow_jones__adverse_pep/sacntion") || getters.getPermissions.includes("dow_jones__adverse_pep_sacntion")) {
                                // sourcesCallBody.sources.push("soe", "ame", "wl");
                                sourcesCallBody.sources.push("soe", "wl");
                            }
                            if (getters.getPermissions.includes("world_check__adverse_pep/sacntion") || getters.getPermissions.includes("world_check__adverse_pep_sacntion")) {
                                sourcesCallBody.sources.push("worldcheck");
                            }
                            sourcesCallBody.sources = sourcesCallBody.sources.sort((a, b) => a.localeCompare(b));
                            const {data: sourcesCall} = await osintAxios.post("/adverse-media/search/sources", sourcesCallBody, {headers: header});
                            if (sourcesCallBody.sources.includes("complyadvantage")) {
                                reqPepAdvSan = osintAxios.post(
                                    "/adverse-media/search",
                                    {
                                        name: value,
                                        fuzziness: 0.7,
                                        entity_type: "",
                                        checks: ["adverse", "peps", "sanctions"],
                                        sources: ["complyadvantage"],
                                        request_id: sourcesCall.request_id,
                                    },
                                    {headers: header}
                                );
                            }
                            if (sourcesCallBody.sources.includes("worldcheck")) {
                                reqPepWorldCheck = osintAxios.post(
                                    "/adverse-media/worldcheck",
                                    {
                                        name: value,
                                        request_id: sourcesCall.request_id,
                                        limit: 100,
                                        page: 1,
                                        entity_type: "",
                                        search_mode: sourcesCall.search_mode,
                                    },
                                    {
                                        headers: header,
                                    }
                                );
                            }
                            if (sourcesCallBody.sources.includes("soe") || sourcesCallBody.sources.includes("ame") || sourcesCallBody.sources.includes("wl")) {
                                reqPepDowJones = osintAxios.post(
                                    "/adverse-media/search/dj-search",
                                    {
                                        name: value,
                                        request_id: sourcesCall.request_id,
                                        limit: 100,
                                        page: 1,
                                        sources: sourcesCallBody.sources.filter((src) => src != "complyadvantage" && src != "worldcheck" && src != "offshoreleaks"),
                                        entity_type: "",
                                        search_mode: sourcesCall.search_mode,
                                    },
                                    {headers: header}
                                );
                            }
                        }
                        break;
                    case "offshoreleaks-tool":
                        if (source === "name" || source === "company_name") {
                            let sourcesCallBody = {
                                name: value,
                                sources: [],
                                entity_type: "",
                                search_mode: "exact_match",
                                country: [],
                                page: 1,
                                limit: 100,
                            };

                            sourcesCallBody.sources.push("offshoreleaks");
                            sourcesCallBody.sources = sourcesCallBody.sources.sort((a, b) => a.localeCompare(b));
                            const {data: sourcesCall} = await osintAxios.post("/adverse-media/search/sources", sourcesCallBody, {headers: header});
                            if (sourcesCallBody.sources.includes("offshoreleaks")) {
                                reqPepOffshoreLeaks = osintAxios.post(
                                    "/adverse-media/icij-search",
                                    {
                                        name: value,
                                        request_id: sourcesCall.request_id,
                                        limit: 100,
                                        offset: 1,
                                        entity_type: [],
                                    },
                                    {
                                        headers: header,
                                    }
                                );
                            }
                        }
                        break;
                    case "risk-tool":
                        if (source === "name") {
                            let sourcesCallBody = {
                                name: value,
                                sources: ["complyadvantage", "dowjones", "worldcheck"],
                                entity_type: "",
                                year_of_birth: "",
                                fuzziness: 0.7,
                                country: [],
                                checks: ["peps", "sanctions", "adverse"],
                                page: 1,
                                limit: 100,
                            };
                            const {data: sourcesCall} = await osintAxios.post("/adverse-media/search/sources", sourcesCallBody, {headers: header});
                            reqPepAdvSan = osintAxios.post(
                                "/adverse-media/search",
                                {
                                    name: value,
                                    fuzziness: 0.7,
                                    entity_type: "",
                                    checks: ["peps", "sanctions", "adverse"],
                                    sources: ["complyadvantage"],
                                    request_id: sourcesCall.request_id,
                                },
                                {headers: header}
                            );
                            reqPepWorldCheck = osintAxios.post(
                                "/adverse-media/worldcheck",
                                {
                                    name: value,
                                    request_id: sourcesCall.request_id,
                                    limit: 100,
                                    page: 1,
                                    entity_type: "",
                                },
                                {
                                    headers: header,
                                }
                            );
                            reqPepDowJones = osintAxios.post(
                                "/adverse-media/search/dj-search",
                                {
                                    name: value,
                                    request_id: sourcesCall.request_id,
                                    limit: 100,
                                    page: 1,
                                    entity_type: "",
                                    sources: sourcesCall.djSources,
                                },
                                {headers: header}
                            );
                        }
                        break;
                    case "lexis-bridger-tool":
                        let sourcesCallBody = {
                            sources: [],
                            entity_type: source === "name" ? "Individual" : "Business",
                        };
                        if (source === "name") {
                            const names = value?.trim()?.split(" ");

                            if (names.length === 1)
                                // sourcesCallBody.person_entity = {
                                //     first_name: value?.trim() || "",
                                //     last_name: " ",
                                // }
                                return;
                            if (names.length === 2)
                                sourcesCallBody.person_entity = {
                                    first_name: names?.[0] || "",
                                    last_name: names?.[1] || "",
                                };
                            else {
                                const list = cloneDeep(names);
                                list.splice(0, 1);
                                list.splice(list.length - 1, 1);
                                sourcesCallBody.person_entity = {
                                    first_name: names?.[0] || "",
                                    middle_name: list.join(" ") || "",
                                    last_name: names?.length > 1 ? names?.[names.length - 1] || "" : "",
                                };
                            }
                        } else
                            sourcesCallBody.company_entity = {
                                name: value,
                            };

                        if (getters.getPermissions.includes("lexis_bridger__adverse_pep/sacntion") || getters.getPermissions.includes("lexis_bridger__adverse_pep_sacntion")) {
                            sourcesCallBody.sources.push("lexis");
                            sourcesCallBody.sources = sourcesCallBody.sources.sort((a, b) => a.localeCompare(b));
                            const {data: sourcesCall} = await osintAxios.post("/lexis_bridger/search_sources", sourcesCallBody, {headers: header});

                            reqLexis = osintAxios.post(
                                "lexis_bridger/search_entity?cache_bypass=false",
                                {
                                    ...sourcesCallBody,
                                    ...{
                                        additional_details: {
                                            query_id: sourcesCall.request_id,
                                        },
                                    },
                                },
                                {
                                    headers: header,
                                }
                            );
                        }
                        break;
                    case "username-tool":
                        if (source === "username") reqUserTool = osintAxios.post("/username/token", {username: value}, {headers: header});
                        break;
                    default:
                        break;
                }

                // comapny-name
            }
            // EventBus.$emit('topprogress', 'start');
            await Promise.all([reqGitlab, reqEmailRep, reqGoogle, reqDark, reqFlickr, reqLinkedInDiscovery, reqGdc, reqGithub, reqLinkedIn, reqPI, reqSkype, reqTwitter, reqFacebook, reqCompDomain, reqNewsTool, reqFactivaTool, reqNameSearch, reqEmailSearch, reqCompanyName, reqDomainSt, reqInsta, reqTiktok, reqPepAdvSan, reqUserTool, reqCompanyNameClari, reqCompanyNameWatch, reqCompanyNameBvd, reqCompanyWatch, reqPepWorldCheck, reqPepDowJones, reqLexis, reqPepOffshoreLeaks, reqdnbcorprecord]);

            // .then(() => EventBus.$emit('topprogress', 'done'));
        },
        getToolsStatus: async ({getters, commit, dispatch}) => {
            try {
                commit("SET_QUERIES_LOADER", true);
                let body = {case_id: getters.getCaseId};
                if (getters.selectedToolsUsers?.length > 0) {
                    const userIds = getters.selectedToolsUsers.map((user) => ({user_id: user.id}));
                    body.user_ids = userIds;
                }
                const {monitoring, saved} = getters.getToolsFilterData;
                if (monitoring !== "all") {
                    body.monitoring = monitoring;
                }
                if (saved !== "all") {
                    body.saved = saved;
                }
                if (getters.nerFilters) body.output_filters = getters.nerFilters;

                const {data: toolsStatus} = await axios.post(`/tools-status`, body);
                const fetchToolsOptions = getters.getAllToolNames.children;
                fetchToolsOptions?.forEach((ele, index) => {
                    const tools = toolsStatus?.data?.filter((elem) => elem.tool == ele.activeProcess);
                    const done_status = tools?.some((n) => n.status == "DONE" && !n.visited);
                    const pending_status = tools?.some((n) => n.status == "PENDING");
                    const running_status = tools?.some((n) => n.status == "RUNNING");
                    if (!running_status && !done_status && !pending_status) {
                        ele.status = false;
                    } else if (running_status) {
                        ele.status = "RUNNING";
                    } else if (pending_status) {
                        ele.status = "PENDING";
                    } else if (done_status) {
                        ele.status = "not_visited";
                    }
                });
                let overall_status = false;
                const done_status_tools = fetchToolsOptions?.some((n) => n.status === "not_visited");
                const pending_status_tools = fetchToolsOptions?.some((n) => n.status === "PENDING");
                const running_status_tools = fetchToolsOptions?.some((n) => n.status === "RUNNING");
                if (running_status_tools) {
                    overall_status = "RUNNING";
                } else if (pending_status_tools) {
                    overall_status = "PENDING";
                } else if (done_status_tools) {
                    overall_status = "not_visited";
                }
                commit("SET_OVERALL_TOOLS_STATUS", overall_status);
                commit("SET_ALL_TOOLS_NAMES", {children: fetchToolsOptions});
                commit(
                    "SET_SEARCHED_QUERIES",
                    toolsStatus?.data?.map((el) => ({
                        ...el,
                        ...{
                            is_monitoring: el.monitoring_status,
                        },
                    })) ?? []
                );
                commit("SET_QUERIES_LOADER", false);
            } catch (error) {
                console.error(error);
            }
        },
        intervalToolsStatus: ({state, dispatch, commit}, {secs}) => {
            if (state.intervalSecs !== secs) {
                if (state.counterInterval !== null) {
                    clearInterval(state.counterInterval);
                }
                let mInterval = setInterval(async () => {
                    // await dispatch("getToolsStatus");
                }, secs * 1000);
                commit("SET_COUNTER_INTERVAL", mInterval);
                commit("SET_INTERVAL_SECS", secs);
            }
        },
        createSocketIOConn: ({commit}) => {
            try {
                const {VUE_APP_SOCKET_URL: url} = process.env;
                const socket = io(url, {path: "/socket.io/", transports: ["websocket"], upgrade: false, secure: true});
                // const osint_socket = io(osint_url, { path: osint_path, transports: ["websocket"], upgrade: false });
                commit("SET_SOCKET_IO", socket);
                // commit("SET_OSINT_SOCKET_IO", osint_socket);
            } catch (error) {
                console.error(error);
            }
        },
        getCaseListReadOnly: async ({commit, getters}) => {
            try {
                const {data: readOnly} = await userAxios.get(`/api/v1/users/v2/features`);
                if (readOnly) {
                    const isCaseReadOnly = readOnly?.licenses?.case_list__case_data === 1;
                    commit("SET_CASE_LIST_READ_ONLY", isCaseReadOnly);
                    commit("SET_READ_ONLY", isCaseReadOnly);
                }
            } catch (error) {
                console.error(error);
            }
        },

        fetchPermissions: async ({commit, getters}) => {
            // commit("SET_PERMISSIONS_FETCHED", false);
            try {
                const {data: features} = await userAxios.get(`/api/v1/users/features`);
                //// FOR READ ONLY USING THIS END POINT
                // CHECK IF CASE LIST IS READ ONLY OR NOT
                commit("SET_PRODUCT_FEATURES", features?.product_fetaures ?? null);
                const licenses = features.licenses;
                // const licenses = features?.licenses.map( ftr => ftr.licenses )
                commit("SET_LICENSES", licenses);
                let productFeatures = [];
                if (getters.getCaseId && getters.caseValid) {
                    try {
                        const {data: caseData} = await axios.get(`/case-personal-data/${getters.getCaseId}`);
                        commit("SET_CASE_PERSONAL_DATA", caseData.case_data);
                        commit("SET_CASE_DESCRIPTION", caseData.case_data.description ?? "");
                        commit("SET_CASE_CREATED_AT", caseData.case_data.created_at ?? "");
                        let type = "";
                        let case_level = caseData?.case_data?.data["Due Diligence Level"]?.value ?? "";
                        if (getters.getUserAuthDetails.email.includes("@neotas.com")) {
                            if (case_level) {
                                type = case_level;
                            } else {
                                type = caseData?.case_data?.case_type ? caseData?.case_data?.case_type : "CS";
                            }
                        } else if (case_level) {
                            type = case_level;
                        }
                        if (type) {
                            commit("SET_CASE_DATA_LEVEL", case_level ? case_level : type);
                            productFeatures = features?.product_fetaures[type];
                        }
                    } catch (error) {
                        console.error(error);
                    }
                } else {
                    try {
                        productFeatures = uniq(
                            Object.values(features.product_fetaures)
                                .join()
                                .split(",")
                        );
                    } catch (error) {
                        console.error(error);
                    }
                }
                if (productFeatures) {
                    const permissions = productFeatures.filter((ele) => licenses.find((ele1) => ele1 === ele));
                    permissions.push("company_checker_tool");
                    commit("SET_PERMISSIONS", permissions);
                }
            } catch (error) {
                console.error(error);
            }
            commit("SET_PERMISSIONS_FETCHED", true);
        },
        async loadKeyFacts({commit, getters}) {
            let keyFactsResponse = await axios.get(`key-facts/${getters.getCaseId}`);
            commit("SET_KEY_FACTS", keyFactsResponse.data.data);
        },
        setSelectedKeywordsData({commit}, payload) {
            commit("SET_SELECTED_KEYWORDS_DATA", payload);
        },
        async setCaseReviewDate({commit, getters, dispatch}, payload) {
            try {
                console.log(router.app._route.params.toolName);
                let data = await axios.post("/case-info/upcoming-review-date", payload);
                if (data.status === 200 && data?.data?.status) {
                    Vue.$toast.success("Case state updated successfully !");
                    commit("SET_CASE_STATUS", "Monitoring");
                    commit("SET_CASE_PERSONAL_DATA_REVIEW_DATE", data?.data?.upcoming_review_date);
                    commit("SET_CASE_REVIEW_DATE_POPUP", false);
                    commit("SET_CASE_STATUS_FLAG", false);
                    dispatch("getDataMonitoring");
                    dispatch("getNewMonitoringEvents");
                    if (router.app?._route?.params?.toolName) dispatch("getQueryMonitoringEvents", router.app._route.params.toolName);
                } else Vue.$toast.error("Some error occurred ! Please try again later");
            } catch (error) {
                let message = "Some error occurred ! Please try again later";
                message = error?.response?.data?.detail?.message;
                Vue.$toast.error(message);
                commit("SET_CASE_REVIEW_DATE_POPUP", false);
                commit("SET_CASE_STATUS_FLAG", false);
            }

            // } else {
            //     // commit("SET_CASE_PERSONAL_DATA_REVIEW_DATE", payload);
            //     commit('SET_CASE_REVIEW_DATE_POPUP', false);
            // }
        },
        // async setCaseReviewDate({ commit, getters, dispatch }, payload) {
        //     console.log("test")
        //     var date = DateTime.fromJSDate(payload).toFormat("yyyy-MM-dd");
        //     try {
        //         let data = await axios.post(`case-info/case-state`, {
        //             case_id: getters.getCaseId,
        //             state_name: "Monitoring",
        //             upcoming_review_date: date,
        //         });

        //         if (data.status === 200 && data?.data?.status) {
        //             Vue.$toast.success("Case state updated successfully !");
        //             commit("SET_CASE_STATUS", "Monitoring");
        //             commit("SET_CASE_PERSONAL_DATA_REVIEW_DATE", data.data?.case_data?.upcoming_review_date);
        //             commit("SET_CASE_REVIEW_DATE_POPUP", false);
        //             commit("SET_CASE_STATUS_FLAG", false);
        //             dispatch("getDataMonitoring");
        //             dispatch("getNewMonitoringEvents");
        //             if (router.app?._route?.params?.toolName) dispatch("getQueryMonitoringEvents", router.app._route.params.toolName);
        //         } else Vue.$toast.error("Some error occurred ! Please try again later");
        //     } catch (error) {
        //         let message = "Some error occurred ! Please try again later";
        //         message = error?.response?.data?.detail?.message;
        //         Vue.$toast.error(message);
        //         commit("SET_CASE_REVIEW_DATE_POPUP", false);
        //         commit("SET_CASE_STATUS_FLAG", false);
        //     }

        //     // } else {
        //     //     // commit("SET_CASE_PERSONAL_DATA_REVIEW_DATE", payload);
        //     //     commit('SET_CASE_REVIEW_DATE_POPUP', false);
        //     // }
        // },
        async setCaseDueDate({commit, getters, dispatch}, payload) {
            var date = DateTime.fromJSDate(payload).toFormat("yyyy-MM-dd");
            try {
                let data = await axios.post(`case-info/`, {
                    case_id: getters.getCaseId,
                    due_date: date,
                });

                if (data.status === 200 && data?.data?.status) {
                    Vue.$toast.success("Case due date updated successfully !");
                    commit("SET_CASE_PERSONAL_DATA_DUE_DATE", date);
                    commit("SET_CASE_DUE_DATE_POPUP", false);
                    commit("SET_CASE_STATUS_FLAG", false);
                    dispatch("getDataMonitoring");
                    dispatch("getNewMonitoringEvents");
                    if (router.app?._route?.params?.toolName) dispatch("getQueryMonitoringEvents", router.app._route.params.toolName);
                } else Vue.$toast.error("Some error occurred ! Please try again later");
            } catch (error) {
                let message = "Some error occurred ! Please try again later";
                message = error?.response?.data?.detail?.message;
                Vue.$toast.error(message);
                commit("SET_CASE_DUE_DATE_POPUP", false);
                commit("SET_CASE_STATUS_FLAG", false);
            }
        },
        setSearchWordsData({commit}, payload) {
            commit("SET_SEARCH_WORDS_DATA", payload);
        },
        fetchCaseValid: async ({commit, getters}) => {
            try {
                const {data} = await axios.get(`/case-data/${getters.getCaseId}/validate`, {
                    headers: {"x-company-id": store.getters.getAccountId},
                });
                if (getters.getCaseId === "NEW") data.status = true;
                commit("SET_CASE_VALID", data.status);
                commit("SET_VALIDATE_CASE", data.status);
                commit("SET_USER_MANAGED_CASE", data?.self_service);
                commit("SET_NEW_CASE_STATUS", data?.case_state);
                const isManagedType = !data?.self_service;
                if (data && (data.ro || data.permission_scope == "read")) {
                    // getters.getIsCaseListReadOnly = true
                    // getters.getReadOnlyMode = true
                    commit("SET_CASE_LIST_READ_ONLY", true);
                    commit("SET_READ_ONLY", true);
                    if (isManagedType) router.push({path: `/ro/${getters.getCaseId}/entities`});
                    else router.push({path: `/ro/${getters.getCaseId}`});
                }
            } catch (e) {
                if (e.response.data.detail) commit("SET_CASE_VALID", false);
                if (e.response.data.detail) commit("SET_VALIDATE_CASE", false);
            }
        },
        fetchCaseFreezeDate: async ({commit, getters}) => {
            if (getters.caseValid) {
                const {data} = await axios.get(`/case-data/case-current-state/${getters.getCaseId}`);
                if (data?.case_state_date && data?.status) commit("SET_CASE_FREEZE_DATE", getFormattedTime(data?.case_state_date));
            }
        },
        updateSelectedToolsUsers: async ({commit, dispatch}, value) => {
            commit("SET_SELECTED_TOOLS_USERS", value);
            await dispatch("getToolsStatus");
        },
        fetchCaseEntityInfo: async ({commit, getters}, product_id = payload) => {
            const {data} = await axios.get(`case-entity-data/form-data/${product_id}`);
            if (data && data.status) commit("SET_CASE_ENTITY_INFORMATION", data?.data);
            else commit("SET_CASE_ENTITY_INFORMATION", null);
        },
        setDirectIpLogin: async ({commit}, payload) => {
            console.log(payload);
            commit("SET_DIRECT_IP_LOGIN", payload);
        },
    },
    getters: {
        getFilteredSavedEntities: (state) => {
            let data = state.entityData.main_node?.concat(state.entityData.entities?.filter((e) => e.status !== "IRRELEVANT" && e.status));
            if (state.entitiesSearchQuery == "") {
                return data;
            } else {
                return data.filter((entity) => {
                    return entity.title.toLowerCase().includes(state.entitiesSearchQuery.toLowerCase()) || entity.entity.toLowerCase().includes(state.entitiesSearchQuery.toLowerCase());
                });
            }
        },
        getToolsFilterData: (state) => state.toolsFilterData,
        nerFilters: (state) => state.nerFilters,
        getKeyFacts: (state) => state.researchData.keyFacts.filter((e) => e.status != "IRRELEVANT"),
        getCategorisedRiskCategories: (state) => {
            const types = uniq(state.rawRiskCategories?.map((e) => e.category_type) ?? []);
            const data = [];
            types.forEach((e) => {
                data.push({
                    category_type: e,
                    categories: sortBy(
                        state.rawRiskCategories.filter((f) => f.category_type == e),
                        ["ordering"]
                    ),
                });
            });

            return data;
        },
        getTabPaginations: (state) => state.tabPaginations,
        getRiskCategories: (state) => state.riskCategories,
        getRawRiskCategories: (state) => state.rawRiskCategories,
        getOsintData: (state) => {
            return state.osintSourcesData;
        },
        getCombinedTags: (state) => uniqBy(without(flattenDeep(["socialProfiles", "media"].map((tab) => state.researchData[tab] && state.researchData[tab].map((item) => item.categories))), undefined), "tag"),
        // getCombinedProfileTags: state =>
        //   state.matchingProfile &&
        //   uniqBy(
        //     flattenDeep(
        //       state.matchingProfile.data.filter(includePost => includePost.include_in_report).map(post =>
        //         post.categories.analyst.map(tag => {
        //           if (typeof tag === 'object' && tag !== null)
        //             return {
        //               ...tag,
        //               id: post._id,
        //               tab: "socialProfiles"
        //             }
        //           else
        //             return {
        //               tag: tag,
        //               id: post._id,
        //               tab: "socialProfiles"
        //             }
        //         })
        //       )
        //     ),
        //     "tag"
        //   ),
        getUserDetails: (state) => state.userDetails,
        getAllAssociatedEntities: (state) => {
            if (state.researchData["associatedPeople"]) {
                // find all entities with entitiy_identifier set as `name`
                let data = state.researchData["associatedPeople"].filter((el) => {
                    // filter out all entities that are not part of canvas(i.e, present in the store)
                    // return el.entity_identifier === "name"
                    return el.type === "entity";
                });
                // add center node to the list
                let center_nodes = [];
                if (state.entityData.main_node?.length > 0) {
                    center_nodes = [...state.entityData.main_node];
                }
                center_nodes = center_nodes.map((e) => ({...e, main_entity: true}));
                data = center_nodes.concat(data);
                return data;
            }
            return [];
        },
        getConsolidatedFlags: (state) => {
            let final_data = [];
            let data = state.researchData.socialProfiles;
            if (router.app?._route?.name === "research-automated" && router.app?._route?.params.casename !== "keyFacts") data = state.caseResearchData.socialProfiles;
            if (data) {
                data.forEach((profile) => {
                    let all_categories = [];

                    if (!profile?.risk_categories) profile.risk_categories = [];
                    // GET PROFILE RISKS
                    all_categories = [...all_categories, ...profile.risk_categories];

                    // FILTER FROM PLATFORM_CONTENT
                    let pc = state.profileData.find((platform_content) => platform_content.platform == profile.platform && platform_content.username == profile.username);
                    if (pc !== undefined) {
                        pc.data
                            .filter((x) => x.include_in_report)
                            .forEach((flagdata) => {
                                all_categories = [...all_categories, ...flagdata.categories.analyst];
                            });
                    }

                    // FILTER FROM EXTRA_CONTENT
                    state.caseData.extra_content
                        .filter((extra) => extra.platform == profile.platform && extra.username == profile.username && !extra.deleted)
                        .forEach((content) => {
                            all_categories = [...all_categories, ...content.categories];
                        });
                    all_categories = uniq(all_categories);
                    final_data.push({
                        platform: profile.platform,
                        username: profile.username,
                        categories: all_categories,
                    });
                });
            }
            return final_data;
        },

        getMatchingProfileConsolidatedFlags: (state, getters) => {
            if (state.matchingCaseData) {
                return getters.getConsolidatedFlags.find((e) => e.platform == state.matchingCaseData.platform && e.username == state.matchingCaseData.username);
            }
            return {categories: []};
        },
        getAllConsolidatedFlags: (state, getters) => {
            let all_categories = [];
            getters.getConsolidatedFlags.forEach((e) => {
                all_categories = [...all_categories, ...e.categories];
            });

            // Get Risks from Associated People
            getters.getResearchData.associatedPeople.forEach((e) => {
                all_categories = [...all_categories, ...e.risk_categories];
            });

            // Get Risks from Active Passive Media
            getters.getResearchData.media.forEach((e) => {
                all_categories = [...all_categories, ...e.risk_categories];
            });

            // state.profileData.map((profile) => {
            //   profile.data.filter(x => x.include_in_report).map((flagdata) => {
            //     all_categories = all_categories.concat(flagdata.categories.analyst)
            //   })
            // })

            all_categories = uniq(all_categories);
            return all_categories;
        },
        getExtensionID: (state) => state.extensionID,
        getEntitiesRelationsAttributesData: (state) => state.entitiesRelationsAttributesData,
        Fata: (state) => state.profileData.find((elem) => elem.platform == state.matchingCaseData.platform && elem.username == state.matchingCaseData.username),
        getSocialPlatforms: (state) => state.socialPlatforms,
        getSocialProfiles: (state) => state.caseData.social_profiles,
        getEducationEmploymentData: (state) => state.education_employment_data,
        getCaseId: (state) => state.case_id,
        getProduct: (state) => state.product,
        getResearchData: (state) => state.researchData,
        getHistoryData: (state) => state.historyData,
        getNotesData: (state) => state.notesData,
        getCaseResearchTabs: (state) => state.caseResearchTabs,
        getCaseResearchData: (state) => state.caseResearchData,
        getEntityMapperData: (state) => state.entityMapperData,
        /* Get Personal Data
            Twitter
            Skype, Google, Dark web, Username, github gitlab */
        getCasePersonalData: (state) => state.casePersonalData,
        getCompanyProducts: (state) => state.companyProducts,
        getCaseType: (state) => state.casePersonalData.case_type,
        getActivePassiveData: (state) => state.activePassiveData,
        getIconsData: (state) => state.iconsData,
        getFilteredData: (state) => state.filteredData,
        getConfirmed: (state) => state.confirmedData,
        getIrrelevant: (state) => state.irrelevantData,
        getConfirmedCount: (state) => Object.entries(state.confirmedData).reduce((acc, [_, value]) => (value && value?.length ? acc + value?.length : acc + 0), 0),
        getIrrelevantCount: (state) => Object.entries(state.irrelevantData).reduce((acc, [_, value]) => (value && value?.length ? acc + value?.length : acc + 0), 0),
        getCaseData: (state) => state.caseData,
        getNodesInfo: (state) => state.entityData,
        getOriginalCaseData: (state) => state.caseData,
        getReadOnlyMode: (state) => state.readOnlyMode,
        getProfileData: (state) => state.profileData,
        getExtraContent: (state) => state.caseData.extra_content,
        getMatchingProfile: (state) => state.matchingProfile,
        getMatchingCaseData: (state) => state.matchingCaseData,
        getSearchFilters: (state) => state.searchFilters,
        getSearchStatusFilter: (state) => state.searchStatusFilter,
        getSearchEngineResults: (state) => state.researchData.searchEngineResults,
        getActiveSearchFilters: (state) => {
            //https://blackbox.neotas.uk/api/v1/datapoints?case_id=ORCA-b819c2a7ec99&domain=www.linkedin.com&source=yandex&search_term=%22kshitij%22%20%22aggkunal@gmail.com%22&search_term=%22Kunal%20Aggarwal%22
            let params = new URLSearchParams();
            let filter_data = {
                case_id: state.case_id,
            };
            if (state.searchStatusFilter !== "All") {
                filter_data["status"] = state.searchStatusFilter;
            }
            let mapper = {
                domains: "domain",
                sources: "source",
                search_queries: "search_term",
            };
            Object.keys(state.searchFilters).forEach((key) => {
                filter_data[mapper[key]] = [];
                state.searchFilters[key]
                    .filter((e) => e.active)
                    .forEach((f) => {
                        filter_data[mapper[key]].push(f.data);
                        params.append(mapper[key], f.data);
                    });
            });
            return {params, filter_data};
        },
        getMatchingSocialData: (state) => state.profileData.find((elem) => elem.platform == state.matchingCaseData.platform && elem.username == state.matchingCaseData.username),
        getMatchingExtraContent: (state) => state.caseData.extra_content.filter((item) => item.platform == state.matchingCaseData.platform && item.username == state.matchingCaseData.username && !item.deleted),
        getFilterCountMap: (state) => {
            ["socialProfiles", "media"].reduce((map, tab) => {
                //
            });
        },
        getFiltersApplied: (state) => state.filtersApplied,
        getPersonalDetails: (state) => state.researchData["personalDetails"],
        getPersonalDetailsTabs: (state) => state.personalDetailsTabs,
        getInvalidCaseId: (state) => state.invalidCaseId,
        getCaseUsers: (state) => state.caseUsers,
        getAllUsers: (state) => state.allUsers,
        getCasePermissions: (state) => state.allCasePermissions,
        getAllUsernameData: (state) => state.allUsernameData,
        getLastReviewed: (state) => state.lastReviewed,
        getAuthDetails: (state) => {
            return state.authDetails;
        },
        getAllToolsData: (state) => state.allToolsData,
        getAnalysisToolData: (state) => state.analysisDropdownData,
        getCaseUsernames: (state) => state.allCaseUsernames,
        getCaseStates: (state) => state.allCaseStates,
        getCaseEmails: (state) => state.allCaseEmails,
        getCasePhones: (state) => state.allCasePhones,
        getCaseOrganisations: (state) => state.allCaseOrganisations,
        getCaseLocations: (state) => state.allCaseLocations,
        getCaseDomains: (state) => state.allCaseDomains,
        getCaseEducations: (state) => state.allCaseEducations,
        getCaseEmployments: (state) => state.allCaseEmployments,
        getCaseHobbies: (state) => state.allCaseHobbies,
        getCaseCompanies: (state) => state.allCaseCompanies,
        getCaseRegistrants: (state) => state.allCaseRegistrants,
        getCaseNames: (state) => state.allCaseNames,
        getCurrentAnalysisToolsTab: (state) => state.currentAnalysisToolsTab,
        getTotalCards: (state, getters) => {
            let navTabs = [];
            getters.getPersonalDetailsTabs.forEach((el) => {
                if (!REMOVED_ENTITIES.includes(el.key)) {
                    navTabs.push(el.key);
                }
            });

            let total = {};
            for (const [tab, value] of Object.entries(state.filteredData)) {
                let confirmed = value.reduce((acc, cur) => {
                    if (cur.status?.toLocaleLowerCase() === "confirmed" || cur.status?.toLocaleLowerCase() === "potential") {
                        // if (tab === "personalDetails") {
                        // if (navTabs.includes(cur.entity_identifier)) {
                        //     return ++acc;
                        // } else {
                        //     return acc;
                        // }
                        // } else {
                        return ++acc;
                        // }
                    } else {
                        return acc;
                    }
                }, 0);
                total[tab] = {confirmed: confirmed, total: value?.length};
            }
            return total;
        },
        getToolsData: (state) => state.toolsData,
        getAllToolNames: (state) => state.allToolNames,
        searchedQueries: (state) => state.searchedQueries,
        overallToolsStatus: (state) => state.overallToolsStatus,
        queriesLoading: (state) => state.queriesLoading,
        buildQueries: (state) => state.buildQueries,
        getisEmptyConnection: (state) => state.isEmptyConnection,
        getAppConfig: (state) => state.appConfig,
        getAllMonitoringData: (state) => state.allMonitoringData,
        getMonitoringData: (state) => state.monitoringData,
        getSocketIO: (state) => state.socketIO,
        getPermissions: (state) => state.permissions,
        checkPermissionsFetched: (state) => state.permissionsFetched,
        getLicenses: (state) => state.licenses,
        getCaseDataLevel: (state) => state.caseDataLevel,
        getProductFeatures: (state) => state.productFeatures,
        getCaseDescription: (state) => state.caseDescription,
        getCaseReviewDatePopup: (state) => state.isReviewDatePopup,
        getCaseDueDatePopup: (state) => state.isDueDatePopup,
        overallMonitoringEvents: (state) => state.overallMonitoringEvents,
        queryMonitoringEvents: (state) => state.queryMonitoringEvents,
        toolsMonitoringEvents: (state) => state.toolsMonitoringEvents,
        monitoringTools: (state) => state.monitoringTools,
        productMonitoring: (state) => state.productMonitoring,
        getCaseStatusFlag: (state) => state.caseStatusFlag,
        getSelectedKeywordsData: (state) => state.selectedKeywordsData,
        getSelectedKeywordsNearNumber: (state) => state.selectedKeywordsData.nearCount,
        getSelectedKeywords: (state) => {
            return Object.keys(state.selectedKeywordsData.keywords).reduce((result, key) => {
                const selectedKeywordsSet = state.selectedKeywordsData.keywords[key];
                return result.concat(selectedKeywordsSet);
            }, []);
        },
        getSearchWords: (state) => state.searchWords,
        caseValid: (state) => state.caseValid,
        getValidCase: (state) => state.validCase,
        getSelectedToolQuery: (state) => {
            if (router.app?._route?.name === "monitoring" || router.app?._route?.name === "monitoring-ro") return state.selectedMonitoringQuery;
            else return state.selectedToolQuery;
        },
        getMonitoringDateFilter: (state) => state.monitoringDateFilter,
        getCaseCreationDate: (state) => state.caseCreatedAt,
        getCaseFreezeDate: (state) => state.caseFreezeDate,
        getIsCaseListReadOnly: (state) => state.isCaseListReadOnly,
        checkIsUserManagedCase: (state) => state.isUserManagedCase,
        getCaseStatus: (state) => state.case_status,
        selectedToolsUsers: (state) => state.selectedToolsUsers,
        getCaseEntityInfo: (state) => state.caseEntityInfo, // MULTI CASE ENTITY FORM DATA AND ENTITY INFORMATION
        getOutputTemplates: (state) => state.outputTemplates,
        getDirectIpLogin: (state) => state.directIpLogin,
    },
};
